import { Box, Container, Grid, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import GetUploadedImages from '../services/GetUploadedImages';
import { navigateToUrl } from 'single-spa';

function ChapterGridCard({ chapters }) {
  const { bookId } = useParams();
  const [coverImage, setCoverImage] = useState({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); 

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        chapters.map(async (chapter) => {
          const imageUrl = await GetUploadedImages(chapter.imageKey);
          return { [chapter.id]: imageUrl };
        })
      );
      setCoverImage(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };
    fetchImages();
  }, [chapters]);

  const draftChapters = chapters.filter((chapter) => chapter.status === 'PUBLISHED').sort((a, b) => a.chapterNumber - b.chapterNumber);

  return (
    <Container maxWidth="md">
      {draftChapters.map((chapter) => (
        <Box
          key={chapter.id}
          sx={{
            mt: 2,
            bgcolor: 'rgb(0,0,0,0.03)',
            width: '100%',
            borderRadius: 5,
            height: isSmallScreen ? 'auto' : { md: '75px' },
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: isSmallScreen ? 'flex-start' : 'center',
            alignItems: isSmallScreen ? 'flex-start' : 'center',
            p: isSmallScreen ? 2 : 0, 
          }}
          onClick={() => navigateToUrl(`/book/${bookId}/chapter/${chapter.id}`)}
        >
          <Grid container spacing={isSmallScreen ? 1 : 0}>

            <Grid item xs={12} sm={3} md={2}>
              <Box
                sx={{
                  ml: isSmallScreen ? 0 : 2,
                  height: isSmallScreen ? 120 : 55,
                  borderRadius: 4,
                  width: isSmallScreen ? '100%' : '80%',
                  backgroundImage: `url(${coverImage[chapter.id]})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Grid>

            <Grid item xs={12} sm={9} md={10}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isSmallScreen ? 'column' : 'row', 
                  alignItems: isSmallScreen ? 'flex-start' : 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                  padding: isSmallScreen ? '0' : '0 1rem',
                }}
              >
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems={isSmallScreen ? 'flex-start' : 'center'}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                      fontWeight: 600,
                    }}
                  >
                    {chapter.chapterNumber}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                      fontWeight: 500,
                    }}
                  >
                    {chapter.title}
                  </Typography>
                </Stack>

                <Typography
                  variant="body2"
                  sx={{
                    mt: isSmallScreen ? 1 : 0,
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    color: 'gray',
                  }}
                >
                  {chapter.updatedAt}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Container>
  );
}

export default ChapterGridCard;

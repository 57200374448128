import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetArticleImage from '../services/GetArticleImage';
import Filter from './Filter';

function ArticleGrid({ articles, categoryName }) {
    const [articleImage, setArticleImage] = useState({});
    const [filteredArticles, setFilteredArticles] = useState(articles);
    const [searchQuery, setSearchQuery] = useState("");

    const category = categoryName.toLowerCase();

    useEffect(() => {
        const fetchImages = async () => {
            if (!Array.isArray(articles)) {
                console.error('Invalid articles data:', articles);
                return;
            }

            try {
                const images = await Promise.all(
                    articles.map(async (article) => {
                        const imageUrl = await GetArticleImage(article.articleImageKey);
                        return { [article.id]: imageUrl };
                    })
                );
                setArticleImage(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
            } catch (error) {
                // console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [articles]);

    useEffect(() => {
        const filtered = articles.filter((article) => {
            const articleName = article.title || "";
            const matchesSearch = searchQuery === "" || articleName.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
        setFilteredArticles(filtered);
    }, [searchQuery, articles]);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };



    return (
        <Container maxWidth="lg">
            <Grid
                container
                spacing={2}
                mt={2}
                sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Grid item xs={12}>
                    <Filter onSearch={handleSearch}/>
                </Grid>
                {filteredArticles.length > 0 ? (
                    filteredArticles.map((article) => (
                        <Grid
                            item
                            key={article.id}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            mb={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    height: '150px',
                                    width: "100%",
                                    borderRadius: 2,
                                    position: "relative",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(1.02)",
                                    },
                                }}
                                onClick={() => navigateToUrl(`/articles/${category}/${article.id}`)}

                            >
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={articleImage[article.id] || ""}
                                    alt={article.name || "Article Image"}
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        zIndex: 0,
                                    }}
                                />

                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                                        zIndex: 1,
                                        pointerEvents: "none",
                                    }}
                                />

                                <CardContent
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "100%",
                                        zIndex: 2,
                                        color: "white",
                                        p: 2,
                                    }}
                                >
                                    <Typography sx={{ color: "white", fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '0.8rem' }, userSelect: 'none' }}>
                                        {article.title || "No Title"}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '500px',
                                width: '100%',
                                border: '1px solid',
                                borderColor: '#e0e0e0',
                                borderImageSlice: 1,
                                borderRadius:10,
                                p:5
                            }}
                        >
                            <Typography sx={{fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1rem' }, textAlign: 'center' }}>
                                We couldn’t find any articles based on your search.<br />Feel free to explore other categories or check back soon for new content.                             </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default ArticleGrid;

import React, { useEffect, useState, useRef } from 'react';
import { Alert, Box, Button, Container, Grid, Stack, IconButton, Breadcrumbs,FormHelperText, Link, Snackbar, TextField, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import CreateArticle from '../services/CreateArticle';
import { navigateToUrl } from "single-spa";
import UploadImages from '../services/UploadImages';
import defaultCoverImage from '../images/ChapterCover.png'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/styles.css'
import GetUploadedImages from '../services/GetUploadedImages';
import { useNavigate } from 'react-router-dom';
import GetBookById from '../services/GetBookById';
import { useParams } from 'react-router-dom';
import CreateChapter from '../services/CreateChapter';
import UpdateBook from '../services/UpdateBook';

import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import GridOnIcon from '@mui/icons-material/GridOn';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import LexicalEditorComponent from './LexicalEditor/LexicalEditorComponent';
import LoadingScreen from './LoadingScreen';
import SendNotifications from '../services/SendNotifications';
import imageCompression from 'browser-image-compression';

function WriteBookChapter() {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const { bookId } = useParams();
    const charLimit = 50000;
    const titleCharLimit = 60;

    const [book, setBook] = useState('');
    const [status, setStatus] = useState('');
    const [imageKey, setImageKey] = useState('');

    const [content, setContent] = useState('');

    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [coverImage, setCoverImage] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [savedUserNicknames, setSavedUserNicknames] = useState([]);

    const [fileError, setFileError] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [file, setFile] = useState(null);

    const handleReload = (bookId) => {
        return new Promise((resolve) => {
            setIsLoading(true);
            setTimeout(() => {
                navigateToUrl(`/write/mystories/book/${bookId}`);
                resolve();
            }, 2000);
        });
    };

    const fetchBookDetails = async (bookId) => {
        const bookData = await GetBookById(bookId);
        setBook(bookData);
        if (bookData.savedUserLists) {
            const nicknames = bookData.savedUserLists.map((list) => list.nickname);
            setSavedUserNicknames(nicknames);
        }
    }

    useEffect(() => {
        fetchBookDetails(bookId)
        const storedData = localStorage.getItem('chapterData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setTitle(parsedData.title || '');
            setContent(parsedData.content || '');
            setCoverImage(parsedData.coverImage || defaultCoverImage);
            setAuthor(parsedData.author || '');
            setImageKey(parsedData.imageKey || '');
        }
    }, []);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            setFileError("No file selected.");
            setFile(null);
            setImagePreview(null);
            return;
        }

        const validTypes = ["image/png", "image/jpeg", "image/jfif"];
        if (!validTypes.includes(selectedFile.type)) {
            setFile(null)
            setImagePreview(null)
            setFileError("Only PNG, JPG, and JFIF images are allowed.");
            return;
        }

        const maxSize = 1 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
            setFile(null)
            setImagePreview(null)
            setFileError("File size must be less than 1MB.");
            return;
        }

        setFileError("");
        setFile(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));

};

const handleButtonClick = () => {
    if (!isLoading) {
        fileInputRef.current.click();
    }
};

const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    if (newTitle.length <= titleCharLimit) {
        setTitle(newTitle);
    }
};

const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

const handleContentChange = (event) => {
    const newContent = event.target.value;
    if (newContent.length <= charLimit) {
        setContent(newContent);
    }
};

useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
        setAuthor(nickname);
    }
}, []);


const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append('articleImage', file);

    if (!title.trim()) {
        setSnackbarMessage('Please enter a title.');
        setOpenSnackbar(true);
        return;
    }

    if (!content.trim()) {
        setSnackbarMessage('Please enter content.');
        setOpenSnackbar(true);
        return;
    }

    if (!file) {
        setSnackbarMessage('Please add an image.');
        setOpenSnackbar(true);
        return;
    }

    setIsLoading(true);

    try {
        const dateOnly = new Date().toLocaleDateString('en-CA');
        const imageUploadResponse = await UploadImages(formData);
        await CreateChapter(title, content, author, dateOnly, imageUploadResponse, "PUBLISHED", bookId);
        localStorage.removeItem('chapterData');

        if (savedUserNicknames && savedUserNicknames.length > 0) {
            await Promise.all(
                savedUserNicknames.map((nickname) =>
                    SendNotifications(
                        nickname,
                        `A new chapter has been published in the book : ${book.title}`,
                        `/book/${bookId}`
                    )
                )
            );
        }

        handleReload(bookId);
    } catch (error) {
        console.error('Failed to create article', error);
    }
};

const handleSnackbarClose = () => {
    setOpenSnackbar(false);
};

const handlePreview = (bookId) => {

    localStorage.setItem(
        'chapterData',
        JSON.stringify({ title, content, coverImage, author, imageKey })
    );

    navigate(`/write/book/${bookId}/chapter/preview`, {
        state: {
            title,
            content,
            coverImage,
            author,
            imageKey
        },
    });
};

const handleSave = async (event) => {
    event.preventDefault();

    if (!title.trim()) {
        setSnackbarMessage('Please enter a title.');
        setOpenSnackbar(true);
        return;
    }

    if (!content.trim()) {
        setSnackbarMessage('Please enter content.');
        setOpenSnackbar(true);
        return;
    }

    if (!imageKey) {
        setSnackbarMessage('Please add an image.');
        setOpenSnackbar(true);
        return;
    }

    setIsLoading(true);

    try {
        const dateOnly = new Date().toLocaleDateString('en-CA');
        await CreateChapter(title, content, author, dateOnly, imageKey, "DRAFT", bookId);
        navigateToUrl(`/write/mystories/book/${bookId}`);
    } catch (error) {
        console.error('Failed to create chapter', error);
    } finally {
        setIsLoading(false);
    }
};


const handleTabKey = (e) => {
    if (e.key === 'Tab') {
        e.preventDefault();
        const cursorPosition = e.target.selectionStart;
        const newContent = content.substring(0, cursorPosition) + '    ' + content.substring(cursorPosition);
        setContent(newContent);
        setTimeout(() => {
            e.target.selectionStart = e.target.selectionEnd = cursorPosition + 4;
        }, 0);
    }
};

if (isLoading) {
    return <LoadingScreen />;
}

return (
    <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

        <Container maxWidth="lg">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                        color="inherit"
                        href="/"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                        color="inherit"
                        href={`/write`}
                    >
                        <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Contributor
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                        color="inherit"
                        href={`/write/mystories`}
                    >
                        <GridOnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        My Stories
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                        color="inherit"
                        href={`/write/mystories/book/${bookId}`}
                    >
                        <MenuBookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {book.title}
                    </Link>
                    <Typography
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                        color="inherit"
                    >
                        Write Article
                    </Typography>
                </Breadcrumbs>
            </Stack>
        </Container>

        <Typography sx={{
            mb: 2,
            mt: 2,
            fontFamily: "serif",
            fontSize: { xs: 'h4.fontSize', sm: 'h4.fontSize', md: 'h3.fontSize' },
            textAlign: 'left',
            width: '100%',
            lineHeight: 1.2,
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        }}>
            {book.title} / {title}
        </Typography>

        <Box width="100%" maxWidth="1000px" p={1} sx={{
            mb: 10, position: 'relative'
        }}>
            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Chapter Title</Typography>
                        <TextField
                            className="gradient-textfield"
                            required
                            fullWidth
                            variant='outlined'
                            multiline
                            rows={2}
                            value={title}
                            onChange={handleTitleChange}
                            autoComplete="title"
                            name="title"
                            id="title"
                            autoFocus
                            sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
                            disabled={isLoading}
                        />
                        <Typography variant='caption' display='block' gutterBottom>
                            {title.length}/{titleCharLimit} characters
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box>
                            <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Story</Typography>
                            {/* <TextField
                                    className="gradient-textfield"
                                    required
                                    fullWidth
                                    variant='outlined'
                                    multiline
                                    rows={35}
                                    value={content}
                                    onChange={handleContentChange}
                                    onKeyDown={handleTabKey}
                                    autoComplete="title"
                                    name="title"
                                    id="title"
                                    autoFocus
                                    sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
                                    disabled={isLoading}
                                /> */}
                            <LexicalEditorComponent setContent={setContent} />
                            <Typography variant='caption' display='block' sx={{ mt: 2 }}>
                                {stripHtmlTags(content).length}/{charLimit} characters
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: "15px",
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                            width: '100%',
                            backgroundImage: `url(${imagePreview || defaultCoverImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            mb: 3,
                            '@media (max-width: 600px)': { height: '200px' }
                        }} />
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 2, flexShrink: 0, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>
                                    Upload Chapter Image
                                </Typography>
                                <IconButton
                                    onClick={handleButtonClick}
                                    sx={{
                                        background: 'black',
                                        "&:hover": {
                                            background: 'black',
                                        },
                                        color: 'white',
                                        borderRadius: '25px',
                                        width: '40px',
                                        height: '40px',
                                        justifyContent: 'center',
                                        mx: 1,
                                        mt: 1
                                    }}
                                    disabled={isLoading}
                                >
                                    <ImageIcon />
                                    <input
                                        type="file"
                                        id="profileImage"
                                        onChange={handleFileChange}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />
                                </IconButton>
                            </Box>
                            {fileError && <FormHelperText sx={{ color: 'red' }}>{fileError}</FormHelperText>}
                            <Typography variant='caption' display='block' gutterBottom sx={{ mt: 1, mb: 2 }}>
                                Instructions: Click the upload button to select an image file. Ensure the file is in JPG, PNG format and does not exceed 1MB.<br />
                                Image minimum resolution should be 2048 x 1152 pixels.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',

                    }}>

                    {/* Buttons */}
                    {/* <Box sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        onClick={() => handlePreview(bookId)}
                                        variant="contained"
                                        sx={{
                                            fontWeight: 550,
                                            width: '100%',
                                            letterSpacing: 2,
                                            background: 'black',
                                            "&:hover": { background: 'black' },
                                            mb: 2,
                                            fontSize: { xs: '0.9rem', sm: '1rem' }
                                        }}
                                        disabled={isLoading}
                                    >
                                        Preview
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        onClick={(event) => handleSave(event)}
                                        variant="contained"
                                        sx={{
                                            fontWeight: 550,
                                            width: '100%',
                                            letterSpacing: 2,
                                            background: 'black',
                                            "&:hover": { background: 'black' },
                                            mb: 2,
                                            fontSize: { xs: '0.9rem', sm: '1rem' }
                                        }}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            fontWeight: 550,
                                            width: '70%',
                                            letterSpacing: 2,
                                            background: 'black',
                                            "&:hover": { background: 'black' },
                                            mb: 2,
                                            fontSize: { xs: '0.9rem', sm: '1rem' }
                                        }}
                                        disabled={isLoading}
                                    >
                                        Publish
                                    </Button>
                                </Grid>
                                {isLoading && (
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress color="inherit" />
                                        </Box>
                                    )}
                            </Grid>
                        </Box> */}

                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}  >
                            <Grid item xs={12} sm={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        fontWeight: 550,
                                        width: '30%',
                                        letterSpacing: 2,
                                        background: 'black',
                                        "&:hover": { background: 'black' },
                                        mb: 2,
                                        fontSize: { xs: '0.6rem', sm: '1rem' },
                                    }}
                                    disabled={isLoading}
                                >
                                    Publish
                                </Button>
                            </Grid>
                            {isLoading && (
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress color="inherit" />
                                </Box>
                            )}
                        </Grid>
                    </Box>



                </Box>


            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '1000px' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    </Container>

);
}

export default WriteBookChapter;

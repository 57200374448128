import React, { useEffect, useState, useRef } from 'react';
import { Alert, Box, Button, Container, Grid, IconButton, Snackbar, TextField, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import { navigateToUrl } from "single-spa";
import UploadImages from '../services/UploadImages';
import defaultCoverImage from '../images/ChapterCover.png'
import 'react-quill/dist/quill.snow.css';
import '../styles/styles.css'
import GetUploadedImages from '../services/GetUploadedImages';
import { useNavigate } from 'react-router-dom';
import GetBookById from '../services/GetBookById';
import { useParams } from 'react-router-dom';
import CreateChapter from '../services/CreateChapter';
import GetChapterById from '../services/GetChapterById';
import UpdateChapter from '../services/UpdateChapter';

function DraftBookChapter() {
    const { chapterId } = useParams();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const { bookId } = useParams();
    const charLimit = 50000;
    const titleCharLimit = 60;

    const [chapter, setChapter] = useState('');
    const [book, setBook] = useState('');

    const [status, setStatus] = useState('');
    const [imageKey,setImageKey] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [coverImage, setCoverImage] = useState('');
    const [chapterNumber, SetChapterNumber] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const fetchChapterDetails = async (chapterId, bookId) => {
        const bookData = await GetBookById(bookId)
        const chapterData = await GetChapterById(bookId ,chapterId);
        const uploadedImageUrl = await GetUploadedImages(chapterData.imageKey);
        setChapter(chapterData);
        setTitle(chapterData.title || '');
        setContent(chapterData.content || '');
        setStatus(chapterData.status || '');
        setImageKey(chapterData.imageKey || '');
        SetChapterNumber(chapterData.chapterNumber || '');
        setBook(bookData);
        setCoverImage(uploadedImageUrl);
    }

    useEffect(() => {
        fetchChapterDetails(chapterId, bookId)
    }, []);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const formData = new FormData();
            formData.append('articleImage', selectedFile);

        try {
            const imageUploadResponse = await UploadImages(formData);
            setImageKey(imageUploadResponse);
            const uploadedImageUrl = await GetUploadedImages(imageUploadResponse);
            setCoverImage(uploadedImageUrl);
        } catch (error) {

        }
    }
    };

    const handleButtonClick = () => {
        if (!isLoading) {
            fileInputRef.current.click();
        }
    };

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        if (newTitle.length <= titleCharLimit) {
            setTitle(newTitle);
        }
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleContentChange = (event) => {
        const newContent = event.target.value;
        if (newContent.length <= charLimit) {
            setContent(newContent);
        }
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
            setAuthor(nickname);
        }
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setIsLoading(true);
        
        try {
            const dateOnly = new Date().toLocaleDateString('en-CA');
            await UpdateChapter(title, content, dateOnly, imageKey, bookId ,chapterId ,"PUBLISHED", chapterNumber);
            localStorage.removeItem('chapterData');
            navigateToUrl(`/write/mystories/book/${bookId}`);
        } catch (error) {
            console.error('Failed to create article', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handlePreview = (bookId) => {
        if (!title.trim() || !content.trim()) {
            setOpenSnackbar(true);
            return;
        }
        
        localStorage.setItem(
            'chapterData',
            JSON.stringify({ title, content, coverImage, author, imageKey })
        );
        
        navigate(`/write/book/${bookId}/chapter/preview`, {
            state: {
                title,
                content,
                coverImage,
                author,
                imageKey
            },
        });
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setStatus("DRAFT");
    
        if (!title.trim()) {
            setSnackbarMessage('Please enter a title.');
            setOpenSnackbar(true);
            return;
        }
    
        if (!content.trim()) {
            setSnackbarMessage('Please enter content.');
            setOpenSnackbar(true);
            return;
        }
    
        setIsLoading(true);
    
        try {
            const dateOnly = new Date().toLocaleDateString('en-CA');  
            await UpdateChapter(title, content, dateOnly, imageKey, bookId ,chapterId ,status, chapterNumber);
            navigateToUrl(`/write/mystories/book/${bookId}`);
        } catch (error) {
            console.error('Failed to create chapter', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleTabKey = (e) => {
        if (e.key === 'Tab') {
          e.preventDefault(); 
          const cursorPosition = e.target.selectionStart;
          const newContent = content.substring(0, cursorPosition) + '    ' + content.substring(cursorPosition);
          setContent(newContent);
          setTimeout(() => {
            e.target.selectionStart = e.target.selectionEnd = cursorPosition + 4;
          }, 0);
        }
      };


    return (

        <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', px: { xs: 5, sm: 6, md: 8 }, }} >
            <Typography sx={{
                mb: 5,
                mt: 2,
                fontFamily: "serif",
                letterSpacing: "0.1rem",
                fontSize: { xs: 'h6.fontSize', sm: 'h4.fontSize', md: 'h3.fontSize' },
                textAlign: 'left',
                width: '100%',
                lineHeight: 1.2,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
            }}>
                {book.title} / {chapter.title}
            </Typography>
            <Box width="100%" maxWidth="1000px" p={4} sx={{
                border: '1px solid', borderColor: 'black', borderImageSlice: 1, borderRadius: "15px", mb: 10, position: 'relative',
                '@media (max-width: 600px)': { padding: '16px', maxWidth: '100%' },
            }}>                
            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
                    <Grid container spacing={5}>

                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Chapter Title</Typography>
                            <TextField
                                className="gradient-textfield"
                                required
                                fullWidth
                                variant='outlined'
                                multiline
                                rows={2}
                                value={title}
                                onChange={handleTitleChange}
                                autoComplete="title"
                                name="title"
                                id="title"
                                autoFocus
                                sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
                                disabled={isLoading}
                            />
                            <Typography variant='caption' display='block' gutterBottom>
                                {title.length}/{titleCharLimit} characters
                            </Typography>
                        </Grid>



                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Story</Typography>
                                <TextField
                                className="gradient-textfield"
                                required
                                fullWidth
                                variant='outlined'
                                multiline
                                rows={35}
                                value={content}
                                onChange={handleContentChange}
                                onKeyDown={handleTabKey}
                                autoComplete="title"
                                name="title"
                                id="title"
                                autoFocus
                                sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
                                disabled={isLoading}
                            />
                                <Typography variant='caption' display='block' sx={{ mt: 2 }}>
                                    {stripHtmlTags(content).length}/{charLimit} characters
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    borderColor: 'black',
                                    border: 1,
                                    backgroundColor: 'black',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '300px',
                                    width: '100%',
                                    backgroundImage: `url(${coverImage || defaultCoverImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    mb: 3,
                                    '@media (max-width: 600px)': { height: '200px' }
                                }}
                            />
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 2, flexShrink: 0 }} fontWeight={600}>
                                        Upload Chapter Image
                                    </Typography>
                                    <IconButton
                                        onClick={handleButtonClick}
                                        sx={{
                                            background: 'black',
                                            "&:hover": {
                                                background: 'black',
                                            },
                                            color: 'white',
                                            borderRadius: '25px',
                                            width: '40px',
                                            height: '40px',
                                            justifyContent: 'center',
                                            mx: 1,
                                            mt: 1
                                        }}
                                        disabled={isLoading}
                                    >
                                        <ImageIcon />
                                        <input
                                            type="file"
                                            id="profileImage"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                    </IconButton>
                                </Box>

                                <Typography variant='caption' display='block' gutterBottom sx={{ mt: 1, mb: 8 }}>
                                    Instructions: Click the upload button to select an image file. Ensure the file is in JPG, PNG format and does not exceed 5MB.<br />
                                    Image minimum resolution should be 2048 x 1152 pixels.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                  <Box 
                             sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'space-between', 
                                 p: 2,
                             }}>
         
                                 {/* Buttons */}
                                 <Box sx={{ mt: 2 }}>
                                     <Grid container spacing={2}>
                                         <Grid item xs={12} sm={4}>
                                             <Button
                                                 onClick={() => handlePreview(bookId)}
                                                 variant="contained"
                                                 sx={{
                                                     fontWeight: 550,
                                                     width: '100%',
                                                     letterSpacing: 2,
                                                     background: 'black',
                                                     "&:hover": { background: 'black' },
                                                     mb: 2,
                                                     fontSize: { xs: '0.9rem', sm: '1rem' }
                                                 }}
                                                 disabled={isLoading}
                                             >
                                                 Preview
                                             </Button>
                                         </Grid>
                                         <Grid item xs={12} sm={4}>
                                             <Button
                                                 onClick={(event) => handleSave(event)}
                                                 variant="contained"
                                                 sx={{
                                                     fontWeight: 550,
                                                     width: '100%',
                                                     letterSpacing: 2,
                                                     background: 'black',
                                                     "&:hover": { background: 'black' },
                                                     mb: 2,
                                                     fontSize: { xs: '0.9rem', sm: '1rem' }
                                                 }}
                                                 disabled={isLoading}
                                             >
                                                 Save
                                             </Button>
                                         </Grid>
                                         <Grid item xs={12} sm={4}>
                                             <Button
                                                 type="submit"
                                                 variant="contained"
                                                 sx={{
                                                     fontWeight: 550,
                                                     width: '100%',
                                                     letterSpacing: 2,
                                                     background: 'black',
                                                     "&:hover": { background: 'black' },
                                                     mb: 2,
                                                     fontSize: { xs: '0.9rem', sm: '1rem' }
                                                 }}
                                                 disabled={isLoading}
                                             >
                                                 Publish
                                             </Button>
                                         </Grid>
                                         {isLoading && (
                                                 <Box sx={{ width: '100%' }}>
                                                     <LinearProgress color="inherit" />
                                                 </Box>
                                             )}
                                     </Grid>
                                 </Box>
                             </Box>

                </Box>

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '1000px' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default DraftBookChapter;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the TextField */
.gradient-textfield .MuiOutlinedInput-root {
  position: relative;
  border-radius: 15px !important;
}

.gradient-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #e0e0e0 !important;
}

.gradient-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0 !important;
  border-width: 1px !important;
}

.gradient-textfield .MuiOutlinedInput-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0 !important;
}

.gradient-textfield .MuiInputLabel-outlined {
  color: black !important;
}

.gradient-textfield .MuiInputLabel-outlined.Mui-focused {
  color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;EAC9B,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,4BAA4B;AAC9B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["/* Styles for the TextField */\r\n.gradient-textfield .MuiOutlinedInput-root {\r\n  position: relative;\r\n  border-radius: 15px !important;\r\n}\r\n\r\n.gradient-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {\r\n  border-width: 1px !important;\r\n  border-style: solid !important;\r\n  border-color: #e0e0e0 !important;\r\n}\r\n\r\n.gradient-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\r\n  border-color: #e0e0e0 !important;\r\n  border-width: 1px !important;\r\n}\r\n\r\n.gradient-textfield .MuiOutlinedInput-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {\r\n  border-color: #e0e0e0 !important;\r\n}\r\n\r\n.gradient-textfield .MuiInputLabel-outlined {\r\n  color: black !important;\r\n}\r\n\r\n.gradient-textfield .MuiInputLabel-outlined.Mui-focused {\r\n  color: black !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { Box, Container, Divider, Grid, Stack, Typography, Avatar, Fab, Breadcrumbs, Link, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';

import ArticleLeftAd from './ArticleLeftAd';
import ArticleRightAd from './ArticleRightAd';
import ChapterDrawer from './ChapterDrawer';
import LikeAndCommentSection from './LikeAndCommentSection';
import Banner from './Banner';

import FetchProfileImage from '../services/FetchProfileImage';
import GetUserData from '../services/GetUserData';
import GetUploadedImages from '../services/GetUploadedImages';
import GetChapterById from '../services/GetChapterById';
import GetBookById from '../services/GetBookById';
import BookGridList from './BookGridList';
import AdPage from './AdPage';
import LoadingScreen from './LoadingScreen';

function Content() {

    const { chapterId } = useParams();
    const { bookId } = useParams();
    const location = useLocation();

    const [bookTitle, setBookTitle] = useState('')

    const [imageKey, setImageKey] = useState('');
    const [chapter, setChapter] = useState({});
    const [chapterContent, setChapterContent] = useState('')
    const [profileImage, setProfileImage] = useState('');

    const [slicedContent, setSlicedContent] = useState([]);
    const [hoveredChapter, setHoveredChapter] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedChapter, setSelectedChapter] = useState(null);

    const [showAd, setShowAd] = useState(false);
    const [visitCount, setVisitCount] = useState(0);

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleReload = () => {
        return new Promise((resolve) => {
            setIsLoading(true);
            setTimeout(()=> {
                resolve();
            },500);
        })
    }

    const handleAvatarClick = (nickName) => {
        navigateToUrl(`/profile/${nickName}`);
    };

    useEffect(() => {
        fetchChapterData(bookId, chapterId);
        preventRightClickAndTextSelection();
    }, [bookId, chapterId])

    const fetchChapterData = async (bookId, chapterId) => {
        setIsLoading(true);
        try {
            const bookData = await GetBookById(bookId);
            setBookTitle(bookData.title)

            const chapterData = await GetChapterById(bookId, chapterId);
            setChapterContent(chapterData.content)
            setChapter(chapterData);

            const imageKey = await GetUploadedImages(chapterData.imageKey);
            setImageKey(imageKey);

            const authorDetails = await GetUserData(chapterData.author);
            const authorProfileImage = await FetchProfileImage(authorDetails.profileImageKey);
            setProfileImage(authorProfileImage);
            if (showAd) {
                await new Promise((resolve) => setTimeout(resolve, 10000));  
            } else {
                await new Promise((resolve) => setTimeout(resolve, 500));
            }
            await handleReload();
        } catch (error) {
            console.error("Error fetching chapter data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const sliceContentData = () => {
        const rawContent = chapterContent.split(/<\/?p>/);
        const validContent = rawContent.filter(chapter => chapter.trim() !== '' && chapter !== '<p></p>');
        setSlicedContent(validContent);
    }

    useEffect(() => {
        sliceContentData();
    }, [chapter.content]);

    const handleChapterClick = (index) => {
        setSelectedChapter(slicedContent[index]);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleMouseEnter = (index) => {
        setHoveredChapter(index);
    };

    const handleMouseLeave = () => {
        setHoveredChapter(null);
    };

    const getCommentCountForChapter = (chapterIndex) => {
        return chapter.comments.filter(
            (comment) => comment.chapterIndex === String(chapterIndex)
        ).length;
    };

    const preventRightClickAndTextSelection = () => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        document.body.style.userSelect = 'none';
    };

    useEffect(() => {
        const pathMatch = location.pathname.match(/\/book\/[^/]+\/chapter/);

        if (pathMatch) {
            const visitKey = 'global_visit_count';
            const storedCount = parseInt(localStorage.getItem(visitKey), 10) || 0;

            if (storedCount === 2) {
                localStorage.setItem(visitKey, 0);
                setShowAd(true);
            } else if (storedCount < 2) {
                localStorage.setItem(visitKey, storedCount + 1);
                setVisitCount(storedCount + 1);
                setShowAd(false);
            }
        }
    }, [location]);

    useEffect(() => {
        if (showAd) {
            const timer = setTimeout(() => setShowAd(false), 5000); // Hide the ad after 5 seconds
            return () => clearTimeout(timer); // Cleanup the timer on unmount or state change
        }
    }, [showAd]);


    if (isLoading) {
        if (showAd) {
            return <AdPage />;
        }

        return <LoadingScreen/>
    }
    
    return (
        <div>
            <Banner imageKey={imageKey} />
            <Container
                maxWidth='xl'
                sx={{
                    mb: 10,
                }}>
                <Container maxWidth="lg">
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Breadcrumbs aria-label="breadcrumb" mb={1}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Home
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                                color="inherit"
                                href={`/book/${bookId}`}
                            >
                                <ArticleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                {bookTitle}
                            </Link>
                            <Typography
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                                color="inherit"
                            >
                                {chapter.title}
                            </Typography>
                        </Breadcrumbs>

                    </Stack>

                </Container>
                <Grid container spacing={2} justifyContent="center">
                    <Grid
                        item
                        xs={12}
                        md={2}
                        mt={50}
                        sx={{
                            display: { xs: 'none', md: 'none', lg: 'block' },
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            {/* <ArticleLeftAd /> */}
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            margin: { xs: 'auto', md: '0' },
                        }}
                    >
                        <Box
                            sx={{
                                minHeight: "1000px",
                                mb: 10,
                            }}
                        >
                            <Stack spacing={3}>
                                <Stack spacing={1}>
                                    <Typography
                                        variant='h3'
                                        fontFamily={'serif'}
                                        sx={{
                                            fontSize: { xs: '2rem', sm: '2.2rem', md: '2.2rem', lg: '2.5rem' },
                                            fontWeight: 700
                                        }}
                                    >
                                        {chapter.title}
                                    </Typography>
                                    <Stack spacing={2}>
                                        <Stack spacing={1} direction='row' alignItems="center">
                                            <Avatar
                                                src={profileImage}
                                                sx={{
                                                    width: { xs: 30, sm: 32 },
                                                    height: { xs: 30, sm: 32 },
                                                    cursor: 'pointer',
                                                }}
                                                alt={chapter.author}
                                                onClick={() => handleAvatarClick(chapter.author)}
                                            />
                                            <Stack direction='column' justifyContent='center'>
                                                <Typography
                                                    variant='caption'
                                                    fontFamily={'serif'}
                                                    color="text.secondary"
                                                >
                                                    By {chapter.author}
                                                </Typography>
                                                <Typography
                                                    variant='caption'
                                                    fontFamily={'serif'}
                                                    color="text.secondary"
                                                >
                                                    {chapter.updatedAt}
                                                </Typography>
                                            </Stack>

                                        </Stack>

                                    </Stack>
                                </Stack>

                                <Divider />



                                <div>
                                    {slicedContent.map((chapter, index) => {
                                        const commentCount = getCommentCountForChapter(index);
                                        return (
                                            <Box
                                                key={index}
                                                id={`chapter-${index}`}
                                                sx={{
                                                    position: 'relative',
                                                }}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={() => {
                                                    if (commentCount === 0) {
                                                        handleMouseLeave();
                                                    }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                        paragraph
                                                        sx={{
                                                            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1.1rem' },
                                                            lineHeight: 1.8,
                                                            textAlign: 'justify',
                                                            '& p': {
                                                                marginBottom: '4px',
                                                            },
                                                            '& br': {
                                                                margin: '0px 0',
                                                                display: 'block',
                                                                content: '""',
                                                            },
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: `<p>${chapter}</p>`,
                                                        }}
                                                    />

                                                    <Fab
                                                        size="small"
                                                        sx={{
                                                            position: 'absolute',
                                                            backgroundColor: 'transparent',
                                                            boxShadow: 'none',
                                                            padding: 0,
                                                            top: { xs: '-20px', md: '-20px', sm: '-20px' },
                                                            right: { xs: '0px', md: '-10px', sm: '0px' },
                                                            zIndex: 1,
                                                            visibility:
                                                                hoveredChapter === index || commentCount > 0
                                                                    ? 'visible'
                                                                    : 'hidden',
                                                            opacity:
                                                                hoveredChapter === index || commentCount > 0
                                                                    ? 1
                                                                    : 0,
                                                            transition: 'opacity 0.3s, visibility 0.3s',
                                                            '&:active, &:focus': {
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                        onClick={() => handleChapterClick(index)}
                                                    >
                                                        <CommentIcon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />
                                                        {commentCount > 0 && (
                                                            <Typography
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    color: 'black',
                                                                    fontSize: { xs: '0.8rem', sm: '1rem' }
                                                                }}
                                                            >
                                                                *
                                                            </Typography>
                                                        )}
                                                    </Fab>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </div>
                            </Stack>
                        </Box>
                        <Divider sx={{ mb: 5 }} />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={2}
                        mt={50}
                        sx={{
                            display: { xs: 'none', md: 'none', lg: 'block' },
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            {/* <ArticleRightAd /> */}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        {chapter.id && (
                            <LikeAndCommentSection
                                bookId={bookId}
                                chapterId={chapter.id}
                                chapterLikeCount={chapter.likeCount}
                                chapterLikes={chapter.likes}
                                chapterComments={chapter.comments}
                                bookAuthor={chapter.author}
                            />
                        )}
                    </Grid>
                </Grid>

                <ChapterDrawer
                    chapterId={chapter.id}
                    chapterComments={chapter.comments}
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    chapterText={selectedChapter || ''}
                    chapterIndex={slicedContent.indexOf(selectedChapter)}
                    bookId={bookId}
                />

            </Container>
        </div>
    );
}

export default Content;

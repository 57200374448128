// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the TextField */
.gradient-textfield .MuiOutlinedInput-root {
  position: relative;
  border-radius: 15px;
}

.gradient-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.gradient-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
  border-width: 1px;
}

.gradient-textfield .MuiOutlinedInput-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

.gradient-textfield .MuiInputLabel-outlined {
  color: black;
}

.gradient-textfield .MuiInputLabel-outlined.Mui-focused {
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/input.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* Styles for the TextField */\r\n.gradient-textfield .MuiOutlinedInput-root {\r\n  position: relative;\r\n  border-radius: 15px;\r\n}\r\n\r\n.gradient-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {\r\n  border-width: 1px;\r\n  border-style: solid;\r\n  border-color: #e0e0e0;\r\n}\r\n\r\n.gradient-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\r\n  border-color: #e0e0e0;\r\n  border-width: 1px;\r\n}\r\n\r\n.gradient-textfield .MuiOutlinedInput-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {\r\n  border-color: #e0e0e0;\r\n}\r\n\r\n.gradient-textfield .MuiInputLabel-outlined {\r\n  color: black;\r\n}\r\n\r\n.gradient-textfield .MuiInputLabel-outlined.Mui-focused {\r\n  color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

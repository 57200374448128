import { Container, CssBaseline, Grid } from '@mui/material'
import React from 'react'
import Banner from './Banner'
import PageButton from './PageButton'

import MyArticles from '../images/MyArticles.png'
import WriteArticle from '../images/WriteArticle.png'
import MyStories from '../images/MyStories.png'
import WriteStories from '../images/WriteStories.png'

function Contributor() {
  return (
    <Container maxWidth="lg" sx={{ mb: 10 }}>
        <CssBaseline />
        <Banner />
        <Container maxWidth='lg'>
          <Grid container spacing={3} mt={1} mb={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={3} lg={3}>
              <PageButton image={WriteArticle} path={"write/article"} />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <PageButton image={WriteStories} path={"write/book"} />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <PageButton image={MyStories} path={"write/mystories"} />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <PageButton image={MyArticles} path={"myArticles"} />
            </Grid>
          </Grid>
        </Container>
    </Container>
  )
}

export default Contributor

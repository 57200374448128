import ApiInstance from "../configs/ApiInstance";

async function UpdateBook(title, description,status, numberOfChapters, bookId) {
    const response = await ApiInstance.bookService.put(`${bookId}`,{
        title, 
        description, 
        status,
        numberOfChapters, 
    });
    return response.data;
}

export default UpdateBook;
import React, { useEffect, useState } from 'react';
import GetBookById from '../services/GetBookById';
import { Container, Divider, Typography, Stack, Breadcrumbs, Link } from '@mui/material';
import ChapterGrid from './ChapterGrid';
import { useParams } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import GridOnIcon from '@mui/icons-material/GridOn';

function Published() {
    const { bookId } = useParams();
    const [bookName, setBookName] = useState('')
    const [chapters, setChapters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const bookData = await GetBookById(bookId);
                setBookName(bookData.title)
                setChapters(bookData.chapters)
            } catch (error) {
                console.error("Error fetching books:", error);
            }
        };
        fetchBooks();
    }, [bookId]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Container maxWidth="lg">
            <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write`}
                        >
                            <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Contributor
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write/mystories`}
                        >
                            <GridOnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            My Stories
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write/mystories/book/${bookId}`}
                        >
                            {bookName}
                        </Link>
                        <Link
                            underline="none"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                        >
                            Published
                        </Link>
                    </Breadcrumbs>
                </Stack>
            </Container>
            <Divider textAlign="right">
                <Typography variant="h2" sx={{
                    textAlign: 'left',
                    fontSize: { xs: 'h5.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' },
                    fontFamily: 'serif'
                }}>
                    Published
                </Typography>
            </Divider>
            <ChapterGrid chapters={chapters} />
            {chapters.length >= 9 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}
        </Container>
    );
}

export default Published;

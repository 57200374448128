import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';

import GetAdminImage from '../services/GetAdminImage';
import GetAdminData from '../services/GetAdminData';

import '../styles/MainBanner.css';

import banner from "../images/banner.png";

function MainBanner() {
    const [bannerImages, setBannerImages] = useState([banner, banner]); 
    const [loading, setLoading] = useState(true); 

    const settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 15000,
        arrows: false
    };

    useEffect(() => {
        const storedBanner1 = sessionStorage.getItem('mainBanner1');
        const storedBanner2 = sessionStorage.getItem('mainBanner2');
        const storedBanner3 = sessionStorage.getItem('mainBanner3');

        if (storedBanner1 && storedBanner2 && storedBanner3) {
            setBannerImages([storedBanner1, storedBanner2, storedBanner3]);
            setLoading(false);
        } else {
            const fetchAdminData = async () => {
                try {
                    const adminData = await GetAdminData();
                    const mainBanner1 = await GetAdminImage(adminData.mainBanner1);
                    const mainBanner2 = await GetAdminImage(adminData.mainBanner2);
                    const mainBanner3 = await GetAdminImage(adminData.mainBanner3);

                    sessionStorage.setItem('mainBanner1', mainBanner1);
                    sessionStorage.setItem('mainBanner2', mainBanner2);
                    sessionStorage.setItem('mainBanner3', mainBanner3);

                    setBannerImages([mainBanner1, mainBanner2, mainBanner3]);
                    setLoading(false);
                } catch (error) {
                    console.error("Error occurred when getting data", error);
                    setLoading(false); 
                }
            };
            fetchAdminData();
        }
    }, []);

    if (loading) {
        return (
            <Slider {...settings}>
                {[...Array(3)].map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            backgroundImage: `url(${banner})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: 5,
                            mt: 2,
                            height: { xs: '200px', sm: '350px', md: '560px', lg: '500px' },
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    />
                ))}
            </Slider>
        );
    }

    return (
        <Slider {...settings}>
            {bannerImages.map((image, index) => (
                <Box
                    key={index}
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: 5,
                        mt: 2,
                        height: { xs: '200px', sm: '350px', md: '560px', lg: '500px' },
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                />
            ))}
        </Slider>
    );
}

export default MainBanner;

import React from 'react';
import { Container, Typography, Grid, Paper, Box, List, ListItem, ListItemText, Button } from '@mui/material';

function AboutUs() {
    return (
        <Container maxWidth="md" sx={{ mb: 10, mt: 7 }}>
            <Typography variant="h5" gutterBottom>
                About Us
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to <strong>Ceylon Diaries</strong>, your go-to platform for storytelling and creative expression!
                We are a community-driven website dedicated to providing a space for writers, readers, and content creators
                to connect and share stories. Our goal is to empower Sri Lankan writers to showcase their creativity and connect
                with audiences both locally and globally.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Our Mission
            </Typography>
            <Typography variant="body1" paragraph>
                At Ceylon Diaries, our mission is to provide a platform where writers can easily publish their stories,
                engage with readers, and build a following. We believe in the power of storytelling to inspire, entertain,
                and connect people. Whether you're a budding writer or a passionate reader, our website offers a space for
                everyone to enjoy and contribute to the world of literature.
            </Typography>

            <Typography variant="h5" gutterBottom>
                What We Offer
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">Chapter-based publishing</Typography>
                    <Typography variant="body2">
                        Publish your stories chapter by chapter and receive feedback from readers in real time.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">Interactive Features</Typography>
                    <Typography variant="body2">
                        Readers can like, comment, and share their thoughts on each chapter, creating a dynamic community.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">Monetization opportunities</Typography>
                    <Typography variant="body2">
                        Writers can earn money based on the number of likes their chapters receive.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">Focus on Sri Lanka</Typography>
                    <Typography variant="body2">
                        Our platform is designed with the Sri Lankan audience in mind, allowing local writers to flourish.
                    </Typography>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: 4, textAlign: 'center' }}>
                <Typography variant="h5" paragraph>
                    Join Us
                </Typography>
                <Typography variant="body1" paragraph>
                    Whether you're here to read, write, or share your creative projects with the world, we invite you to join
                    our growing community. Together, we can inspire creativity and foster the spirit of storytelling in Sri Lanka.
                </Typography>
                <Button variant="contained" color="primary" href="/signup" sx={{
                    mt: 3,
                    mb: 2,
                    fontWeight: 550,
                    letterSpacing: 2,
                    background: 'black',
                    "&:hover": {
                        background: 'black',
                    }
                }}>
                    Get Started
                </Button>
            </Box>
        </Container>
    );
}

export default AboutUs;

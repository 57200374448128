import React, { useEffect, useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { navigateToUrl } from 'single-spa';
import { useLocation } from 'react-router-dom';

import GetUserDetails from '../services/GetUserData';

import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import BookIcon from '@mui/icons-material/Book';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';

const MobileBottomNavbar = () => {
    const location = useLocation(); 
    const [value, setValue] = useState(0);
    const [nickname, setNickname] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const fetchUserProfile = async () => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = getUserAttribute(parsedUser, 'nickname');
            try {
                const userDetails = await GetUserDetails(nickname);
                setNickname(userDetails.nickname);
            } catch (error) {
                console.error('Error occurred when getting user data', error);
            }
        }
    };

    useEffect(() => {
        const checkLoginStatus = () => {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken) {
                if (!isLoggedIn) {
                    setIsLoggedIn(true);
                    fetchUserProfile();
                }
            } else {
                if (isLoggedIn) {
                    setIsLoggedIn(false);
                }
            }
        };

        checkLoginStatus();
        const intervalId = setInterval(checkLoginStatus, 1000);
        return () => clearInterval(intervalId);
    }, [isLoggedIn]);

    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    useEffect(() => {
        const currentPathSegment = location.pathname.split('/')[1];
    
        switch (currentPathSegment) {
            case 'book':
            case 'explorestories':
                setValue(1); // Explore Stories
                break;
            case 'articles':
            case 'explorearticles':
                setValue(2); // Explore Articles
                break;
            case '':
                setValue(0); // Home
                break;
            case 'library':
                setValue(3); // Library
                break;
            case 'profile':
            case 'write':
            case 'signin':
                setValue(4); // Profile or Login
                break;
            default:
                break;
        }
    }, [location, nickname]);
    

    const handleNavigation = (newValue) => {
        setValue(newValue);

        switch (newValue) {
            case 0:
                navigateToUrl('/');
                break;
            case 1:
                navigateToUrl('/explorestories');
                break;
            case 2:
                navigateToUrl('/explorearticles');
                break;
            case 3:
                navigateToUrl('/library');
                break
            case 4:
                if (isLoggedIn) {
                    navigateToUrl(`/profile/${nickname}`);
                } else {
                    navigateToUrl('/signin');
                }
                break;
            default:
                break;
        }
    };

    return (
        <BottomNavigation
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                zIndex: 1000,
                backgroundColor: '#fff',
                boxShadow: '0px -1px 5px rgba(0, 0, 0, 0.1)',
            }}
            value={value}
            onChange={(event, newValue) => handleNavigation(newValue)}
        >
            <BottomNavigationAction
                label="Home"
                icon={value === 0 ? <HomeIcon /> : <HomeOutlinedIcon />}
                sx={{
                    '& .MuiBottomNavigationAction-label': { color: 'black' },
                    '&.Mui-selected': { color: 'black' },
                    '&:hover': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' },
                }}
            />
            <BottomNavigationAction
                label="Books"
                icon={value === 1 ? <BookIcon /> : <BookOutlinedIcon />}
                sx={{
                    '& .MuiBottomNavigationAction-label': { color: 'black' },
                    '&.Mui-selected': { color: 'black' },
                    '&:hover': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' },
                }}
            />
            <BottomNavigationAction
                label="Articles"
                icon={value === 2 ? <ArticleIcon /> : <ArticleOutlinedIcon />}
                sx={{
                    '& .MuiBottomNavigationAction-label': { color: 'black' },
                    '&.Mui-selected': { color: 'black' },
                    '&:hover': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' },
                }}
            />
            {isLoggedIn && (
                <BottomNavigationAction
                    label="Library"
                    icon={value === 3 ? <BookmarkOutlinedIcon style={{ color: 'black' }} /> : <BookmarkBorderOutlinedIcon style={{ color: 'black' }} />}
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '0.9rem',  
                            color: 'black',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '1.5rem', 
                        },
                        '&.Mui-selected': {
                            color: 'black',
                        },
                        '&:hover': {
                            color: 'black',
                        },
                    }}
                />
            )}

            <BottomNavigationAction
                label={isLoggedIn ? 'Profile' : 'Login'}
                icon={value === 4 ? <AccountCircleIcon /> : <AccountCircleOutlinedIcon />}
                sx={{
                    '& .MuiBottomNavigationAction-label': { color: 'black' },
                    '&.Mui-selected': { color: 'black' },
                    '&:hover': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' },
                }}
            />



        </BottomNavigation>
    );
};

export default MobileBottomNavbar;

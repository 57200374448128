import ApiInstance from "../configs/ApiInstance";

async function AddComment(articleId, nickName, comment, updatedAt, profileImage, chapterIndex) {
  try {
    const likeCount = 0;
    const response = await ApiInstance.articleService.post(`${articleId}/comments`, {
        articleId,
        nickName,
        comment,
        updatedAt,
        likeCount,
        profileImage,
        chapterIndex
        });
     return response;
  } catch (error) {
    return null;
    // console.log(error);
    // throw error;
  }
}

export default AddComment;

import React from 'react';
import Footer from './components/Footer';
import { useMediaQuery } from '@mui/material';

export default function Root() {
  const isLargeScreen = useMediaQuery('(min-width:1280px)'); 

  return (
    <section>
      {isLargeScreen && <Footer />}
    </section>
  );
}

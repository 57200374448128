import { Box, Container, Divider, Grid, Stack, Typography, Link, Breadcrumbs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import GetChapterById from '../services/GetChapterById'
import GetUploadedImages from '../services/GetUploadedImages'

import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import GridOnIcon from '@mui/icons-material/GridOn';
import GetBookById from '../services/GetBookById';

function ViewChapter() {
    const navigate = useNavigate();
    const { chapterId } = useParams();
    const { bookId } = useParams();

    const [bookName, setBookName] = useState('')
    const [imageKey, setImageKey] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchChapterData(chapterId);
    })

    const fetchChapterData = async (chapterId) => {
        const bookData = await GetBookById(bookId);
        setBookName(bookData.title);
        const chapterData = await GetChapterById(bookId, chapterId);
        const imageKey = await GetUploadedImages(chapterData.imageKey);
        setTitle(chapterData.title);
        setContent(chapterData.content);
        setAuthor(chapterData.author);
        setImageKey(imageKey);
    }

    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    height: '100%',
                    mb: 3,
                    borderRadius: 5,
                    mt: 2
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        height: { xs: '300px', md: '400px' },
                        objectFit: 'cover',
                    }}
                    src={imageKey}
                    alt="Banner Image"
                />
            </Box>

            <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write`}
                        >
                            <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Contributor
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write/mystories`}
                        >
                            <GridOnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            My Stories
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write/mystories/book/${bookId}/published`}
                        >
                            {bookName}
                        </Link>
                        <Typography
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write/mystories/book/${bookId}`}
                        >
                            {title}
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Container>

            <Grid container spacing={2}>
                <Grid
                    item
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%',
                    }}
                >
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        mx: { xs: 2, sm: 4 },
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "1000px",
                        }}
                    >
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography
                                    variant='h3'
                                    fontFamily={'serif'}
                                    sx={{ fontWeight: 700 }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    fontFamily={'serif'}
                                    color="text.secondary"
                                    variant='caption'
                                >
                                    By {author}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Typography
                                variant="h6"
                                component="div"
                                fontFamily={'serif'}
                                whiteSpace='pre-wrap'
                                sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1.2rem' } }}
                                dangerouslySetInnerHTML={{
                                    __html: `<p>${content}</p>`,
                                }}
                            />
                        </Stack>
                    </Box>
                    <Divider sx={{ mb: 5 }} />
                </Grid>

                <Grid
                    item
                    md={2}
                    mt={6}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%',
                    }}
                >
                </Grid>
            </Grid>
        </Container>
    );
}

export default ViewChapter;

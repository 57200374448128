import React from 'react';
import FloatingButton from "./components/FloatingButton.js";
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from "./components/Navbar.js";
import MobileBottomNavbar from "./components/MobileBottomNavbar.js";
import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function Root(props) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm' && 'md' && 'lg'));

  return (
    <section>
      <Router>
        <Navbar />
        {/* <BottomNavbar/> */}
        {isSmallScreen && <MobileBottomNavbar />}
      </Router>
    </section>
  );
}

import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography, Button, Card, CardMedia, CardContent, Pagination, Collapse } from '@mui/material';
import { navigateToUrl } from 'single-spa';

import Filter from './Filter';
import GetAllArticles from '../services/GetAllArticles';
import GetArticleImage from '../services/GetArticleImage';
import BookGridList from './BookGridList';
import GetAllBooks from '../services/GetAllBooks';
import LoadingScreen from './LoadingScreen';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function ExploreStories() {
  const [searchQuery, setSearchQuery] = useState("");

  const [allBooks, setAllBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [bookImages, setBookImages] = useState({});
  const [showCategories, setShowCategories] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const itemsPerPage = 10;

  useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
    const handleReload = () => {
      return new Promise((resolve) => {
        setIsLoading(true);
        setTimeout(() => {
          resolve();
        }, 500);
      });
    };

  useEffect(() => {
    const fetchBooks = async () => {
      setIsLoading(true);
      try {
        const { content, totalPages } = await GetAllBooks(currentPage - 1, itemsPerPage);
        const shuffledBooks = content.sort(() => Math.random() - 0.5);
        setAllBooks(shuffledBooks);
        setTotalPages(totalPages);
        await handleReload();
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBooks();
  }, [currentPage]);

  useEffect(() => {
    const filterBooks = () => {
      const booksToFilter = searchQuery.trim() === "" ? allBooks : allBooks.filter(book =>
        book.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredBooks(booksToFilter);
    };
    filterBooks();
  }, [allBooks, searchQuery]);

  useEffect(() => {
    const fetchImages = async () => {
      const booksToFetch = searchQuery.trim() === "" ? allBooks : filteredBooks;
      if (booksToFetch.length === 0) return;

      try {
        const images = await Promise.all(
          booksToFetch.map(async (book) => {
            const imageUrl = await GetArticleImage(book.imageKey);
            return { id: book.id, imageUrl };
          })
        );

        const imagesMap = images.reduce((acc, { id, imageUrl }) => {
          acc[id] = imageUrl;
          return acc;
        }, {});

        setBookImages(imagesMap);
      } catch (error) {
        console.error("Error fetching article images:", error);
      }
    };

    fetchImages();
  }, [allBooks, filteredBooks, searchQuery]);



  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const bookCategories = [
    "Fantasy",
    "Science Fiction",
    "Mystery",
    "Thriller",
    "Romance",
    "Historical Fiction",
    "Horror",
    "Adventure",
    "Action",
    "Teen Fiction",
    "Drama",
    "True Crime",
    "History",
    "Paranormal",
    "Werewolf",
    "Vampire",
    "Fan Fiction",
    "Dark Romance",
    "New Adult",
    "LGBTQ+",
    "Mythology",
    "Humor",
    "Poetry",
    "Short Stories",
    "General Fiction",
    "Non-Fiction",
    "Random",
    "Spiritual"
  ];

  const booksToDisplay = searchQuery.trim() === "" ? allBooks : filteredBooks;

  const categoryDetails = {
    "fantasy": {
      mainTitle: "Embark on Magical Journeys",
      description: "Discover spellbinding tales that transport you to otherworldly realms.",
    },
    "science fiction": {
      mainTitle: "Explore the Universe of Possibilities",
      description: "Dive into futuristic worlds and cutting-edge adventures.",
    },
    "mystery": {
      mainTitle: "Solve Intriguing Mysteries",
      description: "Unravel gripping tales of suspense and hidden secrets.",
    },
    "thriller": {
      mainTitle: "Feel the Adrenaline Rush",
      description: "Experience heart-pounding stories that keep you on edge.",
    },
    "romance": {
      mainTitle: "Fall in Love with Love",
      description: "Explore heartwarming tales of passion and romance.",
    },
    "historical fiction": {
      mainTitle: "Travel Through Time",
      description: "Relive the past with captivating historical narratives.",
    },
    "horror": {
      mainTitle: "Face Your Deepest Fears",
      description: "Dare to read spine-chilling tales of terror and dread.",
    },
    "adventure": {
      mainTitle: "Embark on Epic Adventures",
      description: "Set out on journeys filled with excitement and daring quests.",
    },
    "action": {
      mainTitle: "Get Your Heart Racing",
      description: "Dive into explosive and action-packed stories.",
    },
    "teen fiction": {
      mainTitle: "Relive Teenage Dreams",
      description: "Engage with stories that capture the essence of youth.",
    },
    "drama": {
      mainTitle: "Experience the Depths of Emotion",
      description: "Delve into stories rich with drama and intense moments.",
    },
    "true crime": {
      mainTitle: "Explore Real-Life Mysteries",
      description: "Uncover true stories of crime and intrigue.",
    },
    "history": {
      mainTitle: "Learn from the Past",
      description: "Engage with narratives that bring history to life.",
    },
    "paranormal": {
      mainTitle: "Step into the Supernatural",
      description: "Discover thrilling tales of ghosts and otherworldly beings.",
    },
    "werewolf": {
      mainTitle: "Howl with the Pack",
      description: "Immerse yourself in stories of werewolves and full-moon mysteries.",
    },
    "vampire": {
      mainTitle: "Sink Your Teeth Into Adventure",
      description: "Explore dark and alluring tales of vampires.",
    },
    "fan fiction": {
      mainTitle: "Reimagine Your Favorites",
      description: "Dive into creative spins on beloved characters and stories.",
    },
    "dark romance": {
      mainTitle: "Indulge in Forbidden Desires",
      description: "Experience the allure of dangerous and intense romances.",
    },
    "new adult": {
      mainTitle: "Navigate Young Adulthood",
      description: "Explore relatable stories of growth and self-discovery.",
    },
    "lgbtq+": {
      mainTitle: "Celebrate Love and Diversity",
      description: "Engage with stories that embrace all identities and relationships.",
    },
    "mythology": {
      mainTitle: "Uncover Ancient Legends",
      description: "Discover tales of gods, heroes, and epic myths.",
    },
    'humor': {
      mainTitle: "Laugh Out Loud",
      description: "Enjoy stories that bring joy and laughter.",
    },
    "poetry": {
      mainTitle: "Feel the Rhythm of Words",
      description: "Experience the beauty of language through poetry.",
    },
    "short stories": {
      mainTitle: "Bite-Sized Tales",
      description: "Enjoy quick reads packed with depth and meaning.",
    },
    "general fiction": {
      mainTitle: "Discover Timeless Stories",
      description: "Explore narratives that resonate with all readers.",
    },
    "non-fiction": {
      mainTitle: "Learn Something New",
      description: "Engage with real-life stories and informative reads.",
    },
    "random": {
      mainTitle: "Expect the Unexpected",
      description: "Dive into a collection of surprising and eclectic tales.",
    },
    "spiritual": {
      mainTitle: "Seek Inner Peace",
      description: "Discover stories and teachings to uplift your spirit.",
    },
  };

  const getRandomCategory = () => {
    const categories = Object.keys(categoryDetails);
    const randomCategory = categories[Math.floor(Math.random() * categories.length)];
    return randomCategory;
  };

  const randomCategories = Array.from({ length: 2 }, () => getRandomCategory());

  
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{mb: 10}}>
      <Typography mt={2} sx={{ fontWeight: 550, fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', textAlign: 'center' } }}>Explore Books</Typography>

      <Filter onSearch={handleSearch} />

           <Box display="flex" justifyContent="left" alignItems="center" mb={3}>
            <Button
              variant="contained"
              onClick={() => setShowCategories((prev) => !prev)}
              sx={{
                mx:2,
                borderRadius: 5,
                textTransform: 'none',
                bgcolor: 'black',
                color: 'white',
                borderColor: 'black',
                '&:hover': {
                  bgcolor: 'black',
                  borderColor: 'black',
                },
                '&:active': {
                  bgcolor: 'black',
                  borderColor: 'black',
                }
              }}
            >
              {showCategories ? "Hide Categories" : "Explore Categories"}
            </Button>
          </Box>
       

      <Collapse in={showCategories} >
        <Grid container spacing={2} justifyContent="center" alignItems="center" mb={7}>
          {bookCategories.map((category, index) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="outlined"
                sx={{
                  color: 'black',
                  width: '80%',
                  borderRadius: 5,
                  borderColor: 'black',
                  textTransform: 'none',
                }}
                onClick={() => navigateToUrl(`/book/categories/${category.toLowerCase()}`)}
              >
                {category.toLowerCase()}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Collapse>

      <Grid container spacing={3} justifyItems='center' alignItems='center'>
        {booksToDisplay.length > 0 ? (
          booksToDisplay.map((book) => (
            <Grid item xs={6} sm={3} md={2} key={book.id} justifyItems='center' alignItems='center'>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  borderRadius: 2,
                  height: { xs: '220px', md: '250px', sm: '225px' },
                  width: { xs: '90%', md: '100%', sm: '80%' },
                  position: "relative",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.01)",
                    zIndex: 100,
                  },
                }}
                onClick={() => navigateToUrl(`/book/${book.id}`)}

              >
                <CardMedia
                  component="img"
                  src={bookImages[book.id] || ""}
                  alt={book.title}
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                   }}
                />

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                    zIndex: 1,
                    pointerEvents: "none",
                  }}
                />

                <CardContent
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 2,
                    color: "white",
                    p: 2,
                  }}
                >
                  <Typography sx={{ color: "white", fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.9rem' }, userSelect: 'none' }}>
                    {book.title || "No Title"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{
              width: '100%',
              textAlign: 'center',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1rem' }
            }}
          >
            No stories found matching your search.
          </Typography>
        )}
      </Grid>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="circular"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
          mb: 5,
          "& .MuiPaginationItem-root": {
            color: "black",
          },
          "& .MuiPaginationItem-outlined": {
            borderColor: "transparent",
          },
          "& .Mui-selected": {
            background: 'black !important',
            color: "white",
            borderColor: "transparent",
          },
        }}
      />

     

      <Grid item xs={12} sm={12} mb={12}>
        {
          randomCategories.map((category, index) => (
            <BookGridList
              key={index}
              category={category}
              mainTitle={categoryDetails[category]?.mainTitle}
              description={categoryDetails[category]?.description}
            />
          ))
        }      
      </Grid>

    </Container>
  );
}

export default ExploreStories;

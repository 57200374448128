import configs from "./endpoints.json"
import axios from "axios"

const articleService = axios.create({
    baseURL: `${configs.articleService}`,
    headers: {
        'Content-Type': 'application/json'
    }
})

const bookService = axios.create({
    baseURL: `${configs.bookService}`,
    headers: {
        'Content-Type' : 'application/json'
    }
})

const userAwsImageService = axios.create({
    baseURL: `${configs.userAwsService}`,
    headers: {
       'Content-Type': 'multipart/form-data',
    }
})

const userService = axios.create({
    baseURL: `${configs.userService}`,
    headers: {
        'Content-Type' : 'application/json'
    }
})

const ApiInstance = {
    articleService,
    bookService,
    userAwsImageService,
    userService
}

export default ApiInstance;
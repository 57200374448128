import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import cover from '../images/coverImage.png';


function Banner() {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                overflow: 'hidden',
                height: { xs: '200px', sm: '300px', md: '300px', lg: '300px' },
                width: '100%',
                borderRadius: 5,
                mt:3
            }}
        >
            <Box
                component="img"
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={cover}
                alt="Banner Image"
            />
        </Box>
    );
}

export default Banner;

import React, { useState } from "react";
import {
    Box,
    Grid,
    Container,
    TextField,
    InputAdornment,
} from "@mui/material";
import './styles/input.css';

import SearchIcon from '@mui/icons-material/Search';

function Filter({ onSearch }) {

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        onSearch(query);
    };

    return (
        <Container sx={{ mb: 5 }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <TextField
                        className="gradient-textfield"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" sx={{ color: "lightgray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Filter;

import ApiInstance from "../configs/ApiInstance";

async function AddLike(bookId, chapterId, id) {
  try {
    const userId = id;
    const response = await ApiInstance.bookService.put(`/${bookId}/chapter/${chapterId}/like`, {
        userId,
        });
     return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default AddLike;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';
import { Box, Container, Divider, Grid, Stack, Typography, Avatar, Button, Collapse, Breadcrumbs, Link, IconButton, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import GetBookById from '../services/GetBookById';
import GetUploadedImages from '../services/GetUploadedImages';
import FetchProfileImage from '../services/FetchProfileImage';
import GetUserData from '../services/GetUserData';

import AddSavedUser from '../services/AddSavedUser';
import AddBookmark from '../services/AddBookmark';

import ChapterGridCard from './ChapterGridCard';
import BookGridList from './BookGridList';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CopyrightIcon from '@mui/icons-material/Copyright';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RemoveBookmark from '../services/RemoveBookmark';
import RemoveSavedUser from '../services/RemoveSavedUser';
import LoadingScreen from './LoadingScreen';

function Book() {

    const { bookId } = useParams();
    const [id, setId] = useState(null);
    const [nickname, setNickname] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [age, setAge] = useState('')

    const [chapters, setChapters] = useState([]);
    const [book, setBook] = useState('');
    const [bookCoverImage, setBookCoverImage] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [isExpanded, setIsExpanded] = useState(true);
    const [likeCount, setLikeCount] = useState('')
    const [rating, setRating] = useState('');

    const [hasBookmark, setHasBookmark] = useState(false);
    const [userBookMarks, setUserBookMarks] = useState([]);
    const [bookmarkId, setBookmarkId] = useState('');
    const [savedUserId, setSavedUserId] = useState('');

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [open, setOpen] = useState(false);

    const [openRatingDialog, setOpenRatingDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const userBirthDate = userDetails.birthday;
        if (userBirthDate) {

            const birthDate = new Date(userBirthDate);
            const today = new Date();
            const age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth();
            const day = today.getDate();

            // Check if the user is 18 or older
            if (age > 18 || (age === 18 && (month > birthDate.getMonth() || (month === birthDate.getMonth() && day >= birthDate.getDate())))) {
                // If the user is 18 or older, don't show the dialog
                setOpenRatingDialog(false);
            } else {
                // If the user is under 18, show the dialog
                setOpenRatingDialog(true);
            }
        }
    }, [userDetails]);

    useEffect(() => {
        window.scrollTo(0,0);
    },[location])

    const handleReload = () => {
        return new Promise((resolve) => {
            setIsLoading(true);
            setTimeout(() => {
                resolve();
            },500)
        })
    }

    useEffect(() => {
        fetchBookData(bookId);
        preventRightClickAndTextSelection();
    }, [bookId]);

    const fetchBookData = async (bookId) => {
        setIsLoading(true)
        try {
            const bookData = await GetBookById(bookId);
            setBook(bookData)
            const likeCountSum = bookData.chapters.reduce((sum, chapter) => sum + (chapter.likeCount || 0), 0);
            setLikeCount(likeCountSum)
            
            if (bookData.rating === 'Mature') {
                setOpenRatingDialog(true);
            }
            
            setChapters(bookData.chapters)
            const bookCoverImage = await GetUploadedImages(bookData.imageKey);
            setBookCoverImage(bookCoverImage);
            
            const authorDetails = await GetUserData(bookData.author);
            const authorProfileImage = await FetchProfileImage(authorDetails.profileImageKey)
            setProfileImage(authorProfileImage)
            await handleReload();
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = getUserAttribute(parsedUser, 'nickname');
            fetchUserDetails(nickname);
            setNickname(nickname);
        }
        setId(userId);
    }, []);

    useEffect(() => {
        if (userBookMarks.length > 0 && bookId) {
            checkIfUserBookmarkedBefore();
        }
    }, [userBookMarks, bookId]);


    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const fetchUserDetails = async (nickname) => {
        const userDetails = await GetUserData(nickname);
        setUserBookMarks(userDetails.bookmarks);
        setUserDetails(userDetails);
        setAge(userDetails.birthday)
    }

    const checkIfUserBookmarkedBefore = () => {
        const matchedBookmarkFromBook = Array.isArray(book.savedUserLists)
            ? book.savedUserLists.find((savedUser) => savedUser.nickname === nickname)
            : null;

        const matchedBookmarkFromUser = Array.isArray(userBookMarks)
            ? userBookMarks.find((bookmark) => bookmark.bookId === bookId)
            : null;

        if (matchedBookmarkFromBook && matchedBookmarkFromUser) {
            setHasBookmark(true);
            setSavedUserId(matchedBookmarkFromUser.id);
            setBookmarkId(matchedBookmarkFromBook.id);
        } else {
            setHasBookmark(false);
            setBookmarkId('');
        }
    };



    const handleAvatarClick = (nickName) => {
        navigateToUrl(`/profile/${nickName}`);
    };

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const preventRightClickAndTextSelection = () => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        document.body.style.userSelect = 'none';
    };

    const handleAddBookmark = async () => {
        if (!hasBookmark && id) {
            try {
                await AddBookmark(bookId, nickname);
                await AddSavedUser(bookId, nickname);
                setHasBookmark(true);
                setSnackbarMessage('Bookmark added!');
                setSnackbarSeverity('success');
                setOpen(true);
            } catch (error) {
                console.error('Error adding bookmark:', error);
                setSnackbarMessage('Error adding bookmark.');
                setSnackbarSeverity('error');
                setOpen(true);
            }
        } else {
            setSnackbarMessage('Please sign in to add this to your bookmarks.')
            setSnackbarSeverity('error')
            setOpen(true);
        }
    }

    const handleRemoveBookmark = async () => {
        if (hasBookmark && id) {
            try {
                await RemoveBookmark(nickname, savedUserId);
                await RemoveSavedUser(bookId, bookmarkId);
                setHasBookmark(false);
                setSnackbarMessage('Bookmark removed!');
                setSnackbarSeverity('success');
                setOpen(true);
            } catch (error) {
                console.error('Error removing bookmark:', error);
                setSnackbarMessage('Error removing bookmark.');
                setSnackbarSeverity('error');
                setOpen(true);

            }
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpenRatingDialog(false);
    };

    if(isLoading) {
        return <LoadingScreen/>
    }

    return (
        <Container maxWidth="lg" sx={{ mb: 10 }}>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={openRatingDialog}
                onClose={handleCloseDialog}
                aria-labelledby="mature-content-dialog-title"
                aria-describedby="mature-content-dialog-description"
            >
                <DialogTitle id="mature-content-dialog-title">
                    Warning: Adult Content
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        This content is rated for adults only (18+).<br />
                        Please be advised that it may include mature themes, violence, or explicit material.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => window.history.back()}
                        color="black"
                    >
                        Go Back
                    </Button>
                    <Button
                        onClick={handleCloseDialog}
                        color="black"
                    >
                        View
                    </Button>
                </DialogActions>
            </Dialog>


            <Breadcrumbs aria-label="breadcrumb" mt={5} mb={0} sx={{ position: 'relative', zIndex: 10 }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                    color="inherit"
                    href="/"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                </Link>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                    color="inherit"
                    href={`/explorestories`}
                >
                    <ArticleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Explore
                </Link>
                <Typography
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                    color="inherit"
                >
                    {book.title}
                </Typography>
            </Breadcrumbs>

            <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            border: 1,
                            display: { xs: 'block', lg: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: { xs: '500px', md: '400px', sm: '400px', lg: '500px' },
                            borderRadius: 5,
                            mt: 1,
                            width: '100%',
                            backgroundImage: `url(${bookCoverImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={8}>
                    <Stack container spacing={1}>
                        <Typography sx={{
                            fontSize: { xs: '2rem', sm: '2.2rem', md: '2.2rem', lg: '2.5rem' },
                            fontWeight: 550
                        }}>
                            {book.title}
                        </Typography>
                        <Divider />

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="left">
                                    <Avatar
                                        src={profileImage}
                                        sx={{
                                            width: { xs: 24, sm: 24 },
                                            height: { xs: 24, sm: 24 },
                                            cursor: 'pointer',
                                        }}
                                        alt={book.author}
                                        onClick={() => handleAvatarClick(book.author)}
                                    />
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        sx={{
                                            fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.7rem', lg: '0.8rem' },
                                        }}
                                    >
                                        {book.author}
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyItems={'center'}>
                                    <FormatListBulletedIcon fontSize="inherit" sx={{ color: 'gray' }} />
                                    <Typography sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.7rem', lg: '0.8rem' }, color: 'gray' }}>
                                        {book.numberOfChapters}
                                    </Typography>
                                </Stack>

                                <Stack direction="row" spacing={1} alignItems="center" justifyItems={'center'}>
                                    <ThumbUpIcon fontSize="inherit" sx={{ color: 'gray' }} />
                                    <Typography sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.7rem', lg: '0.8rem' }, color: 'gray' }}>{likeCount}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} alignItems="center" justifyItems={'center'}>
                                    <IconButton
                                        onClick={hasBookmark ? handleRemoveBookmark : handleAddBookmark}
                                        sx={{
                                            color: hasBookmark ? 'black' : 'gray'
                                        }}
                                    >
                                        <BookmarkIcon fontSize="inherit" />
                                    </IconButton>
                                </Stack>

                            </Box>
                        </Stack>

                        <Divider />
                        <Collapse in={!isExpanded} collapsedSize={375}>
                            <Stack direction="column" spacing={5} alignItems="left" justifyItems={'center'}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1.1rem' }, whiteSpace: 'pre-wrap' }}>
                                    {book.description}
                                </Typography>
                                <Stack direction="row" spacing={1} alignItems="center" justifyItems={'center'}>
                                    <CopyrightIcon fontSize="inherit" sx={{ color: 'gray', }} />
                                    <Typography sx={{ fontSize: { xs: '0.9rem', sm: '0.8rem', md: '0.7rem', lg: '0.8rem' }, color: 'gray' }}>
                                        {book.copyright}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Collapse>
                        {book.description?.length > 450 && (
                            <Button
                                onClick={toggleExpanded}
                                sx={{
                                    mt: 2,
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                    },
                                    '&:active': {
                                        backgroundColor: 'transparent',
                                        color: 'transparent',
                                        boxShadow: 'none',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                    },
                                }}
                            >
                                {isExpanded ? (
                                    <KeyboardArrowDownIcon fontSize="medium" sx={{ color: 'black' }} />
                                ) : (
                                    <KeyboardArrowUpIcon fontSize="medium" sx={{ color: 'black' }} />
                                )}
                            </Button>
                        )}
                    </Stack>
                </Grid>

            </Grid>

            <Divider sx={{ mt: 2 }} />
            <Typography
                sx={{
                    fontSize: { xs: '1.0rem', sm: '1.5rem' },
                    fontWeight: 550,
                    mt: 1,
                    mb: 1
                }}
            >
                Table of Chapters
            </Typography>

            <Divider />
            {chapters.length === 0 ? (
                <Typography sx={{ textAlign: 'center', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1.1rem' }, color: 'gray', mt: 5, mb: 5 }}>
                    No chapters yet published
                </Typography>
            ) : (
                <ChapterGridCard chapters={chapters} />
            )}
            <Divider sx={{ mt: 2, mb: 5 }} />

            {/* <Grid item xs={12} sm={12}>
                {
                    randomCategories.map((category, index) => (
                        <BookGridList
                            key={index}
                            category={category}
                            mainTitle={categoryDetails[category]?.mainTitle}
                            description={categoryDetails[category]?.description}
                        />
                    ))
                }
            </Grid> */}

        </Container>
    )
}

export default Book
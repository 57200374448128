import axios from "axios"
import configs from  "./endpoints.json"

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`
})

const userService = axios.create({
    baseURL: `${configs.userService}`
})

const articleService = axios.create({
    baseURL: `${configs.articleService}`
})

const adminService = axios.create({
    baseURL: `${configs.adminService}`
})

const bookService = axios.create({
    baseURL: `${configs.bookService}`,
    headers: {
        'Content-Type' : 'application/json'
    }
})

const emailService = axios.create({
    baseURL: `${configs.emailService}`,
    headers: {
        'Content-Type' : 'application/json'
    }
})

const ApiInstance = {
    userAwsService,
    userService,
    articleService,
    adminService,
    bookService,
    emailService
}

export default ApiInstance;
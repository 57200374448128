import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Screen from './Screen';
import ExploreArticles from './components/ExploreArticles'
import ExploreStories from './components/ExploreStories';
import Library from './components/Library';
import Contact from './components/Contact';
import PrivacyPolicies from './components/PrivacyPolicies';
import Standards from './components/Standards';
import AboutUs from './components/AboutUs';
import ContentGuidline from './components/ContentGuidline';

function App() {
  return (
    <Router>
        <Routes>
            <Route path='/' element={<Screen/>} />
            <Route path='/library' element={<Library/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/privacy' element={<PrivacyPolicies/>} />
            <Route path='/standards' element={<Standards/>} />
            <Route path='/contentguidline' element={<ContentGuidline/>} />
            <Route path='/about' element={<AboutUs/>} />
            <Route path='/explorearticles' element={<ExploreArticles/>} />
            <Route path='/explorestories' element={<ExploreStories/>} />
        </Routes>
    </Router>
  )
}

export default App
import { Box, Card, CardMedia, Typography, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GetAllArticles from '../services/GetAllArticles';
import GetUploadedImages from '../services/GetUploadedImages';

import "../styles/GridList.css"

function ArticleGrid({ category, mainTitle, description, displayNumber }) {
  const [articles, setArticles] = useState([]);
  const [articleImages, setArticleImages] = useState({});
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetchAllArticles(category);
  }, [category]);

  const fetchAllArticles = async (category) => {
    try {
      const defaultPage = 0;
      const defaultSize = 10;
      const { content } = await GetAllArticles(defaultPage, defaultSize, category);
      const shuffledContent = shuffleArray(content);
      setArticles(shuffledContent);
      fetchImages(content);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const fetchImages = async (content) => {
    if (!Array.isArray(content)) {
      console.error("Invalid articles data:", content);
      return;
    }

    try {
      const images = await Promise.all(
        content.map(async (article) => {
          if (!article.articleImageKey) {
            console.warn(`Article with ID ${article.id} has no imageKey`);
            return { [article.id]: "" };
          }

          try {
            const imageUrl = await GetUploadedImages(article.articleImageKey);
            return { [article.id]: imageUrl };
          } catch (error) {
            console.error(`Failed to fetch image for article ${article.id}:`, error);
            return { [article.id]: "" };
          }
        })
      );

      setArticleImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ ...item, sortKey: Math.random() }))
      .sort((a, b) => a.sortKey - b.sortKey)
      .map(({ sortKey, ...item }) => item);
  };

  const getSlidesToShow = () => {
    if (displayNumber) {
      return displayNumber;
    }
    if (isSmallScreen) {
      return 2;
    } else if (isMediumScreen) {
      return 7;
    } else {
      return 4;
    }
  };

  const settings = {
    infinite: articles.length >= 2,
    speed: 2500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    swipeToSlide: true,
    draggable: true,
    centerMode: false,
    prevArrow: (
      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          left: '2px',
          zIndex: 10,
          borderRadius: '50%',
          padding: 2,
          transform: 'translateY(-50%)',
        }}
      >
      </Button>
    ),
    nextArrow: (
      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          right: '2px',
          zIndex: 10,
          borderRadius: '50%',
          padding: 2,
          transform: 'translateY(-50%)',
        }}
      >
      </Button>
    ),
  };

  const handleMouseDown = (e) => {
    setStartPosition({ x: e.clientX, y: e.clientY });
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    const distance = Math.sqrt(
      Math.pow(e.clientX - startPosition.x, 2) +
      Math.pow(e.clientY - startPosition.y, 2)
    );
    if (distance > 5) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = (category, article) => {
    if (!isDragging) {
      navigateToUrl(`/articles/${category}/${article.id}`);
    }
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartPosition({ x: touch.clientX, y: touch.clientY });
    setIsDragging(false); // Reset dragging state
  };
  
  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const distance = Math.sqrt(
      Math.pow(touch.clientX - startPosition.x, 2) +
      Math.pow(touch.clientY - startPosition.y, 2)
    );
    if (distance > 5) { // Adjust threshold if necessary
      setIsDragging(true);
    }
  };
  
  const handleTouchEnd = (category, article) => {
    if (!isDragging) {
      // Navigate only if not dragging
      navigateToUrl(`/articles/${category}/${article.id}`);
    }
    setIsDragging(false); // Reset dragging state
  };
  

  const fillEmptySlots = (slidesToShow, articles) => {
    const remainingSlots = slidesToShow - articles.length;
    const placeholders = Array.from({ length: remainingSlots }, (_, index) => (
      <Card key={`placeholder-${index}`} sx={{
        height: '100%',
        borderRadius: 2,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography variant="body2" color="gray">No Article</Typography>
      </Card>
    ));
    return [...articles, ...placeholders];
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <Box>
        <Stack spacing={0} mb={1}>
          <Typography variant='h6' fontWeight="550"> {mainTitle} </Typography>
          <Typography variant='caption' color="gray"> {description} </Typography>
        </Stack>
      </Box>

      {articles.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
          }}
        >
          <Typography variant="body1" color="textSecondary" textAlign="center">
            No articles available at the moment. Please check back later!
          </Typography>
        </Box>
      ) : (
        <Slider {...settings}>
          {fillEmptySlots(getSlidesToShow(), articles).map((article, index) => (
            <Card
              key={article.id || index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                borderRadius: 2,
                height: { xs: "180px", md: "150px", lg: '225px' },
                position: "relative",
                transition: "transform 0.2s ease-in-out",
                backgroundColor:"#f5f5f5"
              }}
            >
              {article.id ? (
                <CardMedia
                  component="img"
                  src={articleImages[article.id] || ""}
                  alt={article.title}
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={() => handleMouseUp(category, article)}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={() => handleTouchEnd(category, article)}
                />
              ) : (
                <Box sx={{ height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="body2" color="gray">No Article</Typography>
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />
            </Card>
          ))}
        </Slider>
      )}
    </Box>
  );
}

export default ArticleGrid;

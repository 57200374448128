import React from 'react';
import { Box, Typography, Link, Grid, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
        <Box
            sx={{
                 display: 'flex',
                flexDirection: 'column',
             }}
        >
            <Box
                component="footer"
                sx={{
                    mt: 'auto', 
                    backgroundColor: 'white',
                    color: '#000',
                    py: 3,
                    px: 2,
                    boxShadow: '0px -1px 0.5px #e0e0e0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 5, // Space between items
                        flexWrap: 'wrap', // Wrap items if screen size is small
                    }}
                >
                    {/* Links */}
                    <Link href="/" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        Home
                    </Link>
                    <Link href="/explorestories" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        Books
                    </Link>
                    <Link href="/explorearticles" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        Articles
                    </Link>
                    <Link href="/about" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        About Us
                    </Link>
                    <Link href="/contact" underline="none" sx={{ color: '#000' , fontSize: '0.9rem'}}>
                        Contact
                    </Link>
                    <Link href="/privacy" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        Privacy Policy
                    </Link>
                    <Link href="/contentguidline" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        Content Guidline
                    </Link>
                    <Link href="/standards" underline="none" sx={{ color: '#000', fontSize: '0.9rem' }}>
                        Community Standards
                    </Link>

                    <Box sx={{ mx: 5 }} />

                    {/* Social Media Icons */}
                    <IconButton href="https://www.facebook.com/profile.php?id=61571089676352" target="_blank" sx={{ color: '#000' }}>
                        <FacebookIcon />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/_ceylondiaries_/" target="_blank" sx={{ color: '#000' }}>
                        <InstagramIcon />
                    </IconButton>
                </Box>

                <Box textAlign="center" mt={3} mb={2}>
                    <Typography variant="body2">
                        &copy; {new Date().getFullYear()} Ceylon Diaries. All rights reserved.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;

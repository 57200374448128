import {
  Avatar,
  Box,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  Alert,
  Grid,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import React, { useEffect, useState } from 'react';
import LikeComment from '../services/LikeComment';
import UnLikeComment from '../services/UnLikeComment';
import FetchProfileImage from '../services/FetchProfileImage';
import { navigateToUrl } from 'single-spa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteComment from '../services/DeleteComment';


function ChapterDrawerComment({ data = [], chapterId, chapterIndex, bookId }) {
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState(data);
  const [profileImages, setProfileImages] = useState({});

  const [nickname, setNickname] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUserId = localStorage.getItem('userId');
        const data = localStorage.getItem('user');

        if (storedUserId) {
          setUserId(storedUserId);

          const parsedData = JSON.parse(data);
          const userDetails = parsedData?.UserAttributes || [];
          const nick = userDetails.find(attr => attr.Name === "nickname")?.Value;
          setNickname(nick || "");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const filteredComments = data.filter((comment) => Number(comment.chapterIndex) === chapterIndex);
    setComments(filteredComments);
    fetchProfileImagesForComments(filteredComments);
  }, [data, chapterIndex]);

  const fetchProfileImagesForComments = async (commentsData) => {
    const images = {};

    try {
      await Promise.all(
        commentsData.map(async (comment) => {
          if (comment.profileImage) {
            const imageUrl = await FetchProfileImage(comment.profileImage);
            images[comment.id] = imageUrl;
          }
        })
      );
      setProfileImages(images);
    } catch (error) {
      // console.error('Error fetching profile images:', error);
    }
  };

  const handleLike = async (commentId) => {
    if (!userId) {
      setError('Please log in to like this comment.');
      setOpen(true);
      return;
    }

    const hasLiked = checkIfUserLiked(commentId);

    try {
      if (hasLiked) {
        await UnLikeComment(bookId, chapterId, commentId, userId);
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                ...comment,
                likeCount: comment.likeCount - 1,
                commentLikes: comment.commentLikes.filter((like) => like.userId !== userId),
              }
              : comment
          )
        );
      } else {
        await LikeComment(bookId, chapterId, commentId, userId);
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? {
                ...comment,
                likeCount: comment.likeCount + 1,
                commentLikes: [...comment.commentLikes, { userId }],
              }
              : comment
          )
        );
      }
    } catch (error) {
      // console.error('Error toggling like:', error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const checkIfUserLiked = (commentId) => {
    const comment = comments.find((comment) => comment.id === commentId);
    return comment ? comment.commentLikes.some((like) => like.userId === userId) : false;
  };

  const handleAvatarClick = (nickName) => {
    navigateToUrl(`/profile/${nickName}`);
  };

  const handleClickMenu = (event, chapterId) => {
    setAnchorEl(event.currentTarget);
    setSelectedChapterId(chapterId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedChapterId(null);
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await DeleteComment(bookId, chapterId, commentId, nickname);

      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId)
      );

    } catch (error) {

    }
  };

  return (
    <Container maxWidth="xl" disableGutters >
      <Box ml={2}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>

        {comments.map((comment, index) => {
          const hasLiked = checkIfUserLiked(comment.id);
          const profileImage = profileImages[comment.id] || comment.profileImage;

          return (
            <React.Fragment key={comment.id}>
              <Grid container spacing={4}>
                <Grid item xs={1} sm={1}>
                  <Avatar
                    src={profileImage}
                    sx={{
                      width: { xs: 35, sm: 40 },
                      height: { xs: 35, sm: 40 },
                      mt: 3,
                      cursor: 'pointer',
                    }}
                    alt={comment.nickName}
                    onClick={() => handleAvatarClick(comment.nickName)}
                  />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <Box
                    sx={{
                      p: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      minHeight: '100px',
                      position: 'relative',  
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography variant="body2" fontWeight={550} fontSize={{ xs: '0.9rem', sm: '1rem' }}>
                        {comment.nickName}
                      </Typography>
                      <Typography variant="body2" fontSize={{ xs: '0.8rem', sm: '1rem', whiteSpace: 'pre-wrap' }}>
                        {comment.comment}
                      </Typography>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      <Typography fontSize={'0.7rem'} color="gray">
                        {new Date(comment.updatedAt).toLocaleDateString()}
                      </Typography>
                      <Box sx={{ display: 'flex' }}>
                        <IconButton
                          onClick={() => handleLike(comment.id)}
                          disableRipple={true}
                          sx={{
                            bgcolor: 'transparent',
                            ':hover': {
                              bgcolor: 'transparent',
                              transition: 'none',
                            },
                          }}
                        >
                          <ThumbUpIcon sx={{ fontSize: '20px', color: hasLiked ? 'black' : 'inherit' }} />
                          <Typography sx={{ ml: 1 }} fontSize={{ xs: '0.7rem', sm: '1rem' }}>
                            {comment.likeCount}
                          </Typography>
                        </IconButton>
                      </Box>
                    </Box>
                    {comment.nickName === nickname && (
                      <>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 10,  
                            right: 10,  
                          }}
                          onClick={(event) => handleClickMenu(event, comment.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          sx={{
                            mt: 1,
                          }}
                          anchorEl={anchorEl}
                          open={selectedChapterId === comment.id}
                          onClose={handleCloseMenu}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem onClick={() => handleDeleteComment(comment.id)}>Delete</MenuItem>
                        </Menu>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {index < comments.length - 1 && <Divider sx={{ mb: 0 }} />}
            </React.Fragment>
          );
        })}
      </Box>
    </Container>
  );
}

export default ChapterDrawerComment;

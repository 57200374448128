import ApiInstance from "../configs/ApiInstance";

async function AddComment(bookId, chapterId, nickName, comment, updatedAt, profileImage, chapterIndex) {
  try {
    const likeCount = 0;
    const response = await ApiInstance.bookService.post(`${bookId}/chapter/${chapterId}/comment`, {
        chapterId,
        nickName,
        comment,
        updatedAt,
        likeCount,
        profileImage,
        chapterIndex
        });
     return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default AddComment;

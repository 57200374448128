import { Box, Card, CardMedia, Typography, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GetAllBooks from '../services/GetAllBooks';
import GetUploadedImages from '../services/GetUploadedImages';

import "../styles/GridList.css"

const cache = {
  books: {}, 
  images: {},  
};

function BookGridList({ category, mainTitle, description, displayNumber }) {
  const [books, setBooks] = useState([]);
  const [bookImages, setBookImages] = useState({});
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });  
  const [isDragging, setIsDragging] = useState(false);  

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (cache.books[category]) {
      setBooks(cache.books[category]);
      setBookImages(cache.images);
    } else {
      fetchAllBooks(category);
    }
  }, [category]);

  const fetchAllBooks = async (category) => {
    try {
      const defaultPage = 0;  
      const defaultSize = 9999999; 
      const { content } = await GetAllBooks(defaultPage, defaultSize ,category);
      const filteredBooks = content.filter((book) => book.category === category);
      const shuffledContent = shuffleArray(filteredBooks);
      setBooks(shuffledContent);
      cache.books[category] = shuffledContent;
      fetchImages(content);
    } catch (error) {
      // console.error("Error fetching books:", error);
    }
  };

  const fetchImages = async (content) => {
    if (!Array.isArray(content)) {
      console.error("Invalid books data:", content);
      return;
    }

    try {
      const images = await Promise.all(
        content.map(async (content) => {
          if (!content.imageKey) {
            console.warn(`Book with ID ${content.id} has no imageKey`);
            return { [content.id]: "" };
          }

          try {
            const imageUrl = await GetUploadedImages(content.imageKey);
            return { [content.id]: imageUrl };
          } catch (error) {
            console.error(`Failed to fetch image for book ${content.id}:`, error);
            return { [content.id]: "" };
          }
        })
      );

      const imagesMap = images.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      setBookImages(imagesMap);
      cache.images = { ...cache.images, ...imagesMap };      cache.images = { ...cache.images, ...imagesMap };

    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const shuffleArray = (array) => {
    return array
        .map((item) => ({ ...item, sortKey: Math.random() }))  
        .sort((a, b) => a.sortKey - b.sortKey)  
        .map(({ sortKey, ...item }) => item); 
};

  const getSlidesToShow = () => {
    if (displayNumber) {
      return displayNumber;
    }
    if (isSmallScreen) {
      return 3;
    } else if (isMediumScreen) {
      return 7;
    } else {
      return 7;
    }
  };

  const settings = {
    infinite: books.length >= 7,
    speed: 2500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    swipeToSlide: true,
    draggable: true,
    centerMode: false,
    prevArrow: (
      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          left: '2px',
          zIndex: 10,
          borderRadius: '50%',
          padding: 2,
          transform: 'translateY(-50%)',
        }}
      >
      </Button>
    ),
    nextArrow: (
      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          right: '2px',
          zIndex: 10,
          borderRadius: '50%',
          padding: 2,
          transform: 'translateY(-50%)',
        }}
      >
      </Button>
    ),
  };

  const handleMouseDown = (e) => {
    setStartPosition({ x: e.clientX, y: e.clientY });
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    // Ensure startPosition is defined before using it
    const distance = Math.sqrt(
      Math.pow(e.clientX - startPosition.x, 2) +
      Math.pow(e.clientY - startPosition.y, 2)
    );
    if (distance > 5) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = (bookId) => {
    if (!isDragging) {
      navigateToUrl(`/book/${bookId}`);
    }
    setIsDragging(false);
  };

   const handleTouchStart = (e) => {
      const touch = e.touches[0];
      setStartPosition({ x: touch.clientX, y: touch.clientY });
      setIsDragging(false); // Reset dragging state
    };
    
    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const distance = Math.sqrt(
        Math.pow(touch.clientX - startPosition.x, 2) +
        Math.pow(touch.clientY - startPosition.y, 2)
      );
      if (distance > 5) { // Adjust threshold if necessary
        setIsDragging(true);
      }
    };
    
    const handleTouchEnd = (bookId) => {
      if (!isDragging) {
        // Navigate only if not dragging
        navigateToUrl(`/book/${bookId}`);
      }
      setIsDragging(false); // Reset dragging state
    };
    

  // Fill up empty slots with placeholder cards if needed
  const fillEmptySlots = (slidesToShow, books) => {
    const remainingSlots = slidesToShow - books.length;
    const placeholders = Array.from({ length: remainingSlots }, (_, index) => (
      <Card key={`placeholder-${index}`} sx={{
        height: '100%',
        borderRadius: 2,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography variant="body2" color="gray">No Book</Typography>
      </Card>
    ));
    return [...books, ...placeholders];
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <Box>
        <Stack spacing={0} mb={1}>
          <Typography variant='h6' fontWeight="550"> {mainTitle} </Typography>
          <Typography variant='caption' color="gray"> {description} </Typography>
        </Stack>
      </Box>

      {books.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
          }}
        >
          <Typography variant="body1" color="textSecondary" textAlign="center" p={5}>
            No books available at the moment. Please check back later!
          </Typography>
        </Box>
      ) : (
        <Slider {...settings}>
          {fillEmptySlots(getSlidesToShow(), books).map((book, index) => (
            <Card
              key={book.id || index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                borderRadius: 2,
                height: { xs: "180px", md: "150px", lg: '225px' },
                position: "relative",
                transition: "transform 0.2s ease-in-out",
                backgroundColor:"#f5f5f5"
              }}
            >
              {book.id ? (
                <CardMedia
                  component="img"
                  src={bookImages[book.id] || ""}
                  alt={book.title}
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={() => handleMouseUp(book.id)}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={() => handleTouchEnd(book.id)}
                />
              ) : (
                <Box sx={{ height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="body2" color="gray">No Book</Typography>
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />
            </Card>
          ))}
        </Slider>
      )}
    </Box>
  );
}

export default BookGridList;

import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent, CardActions, Chip } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import { useNavigate } from 'react-router-dom';

import GetArticleImage from '../services/GetArticleImage';


function ArticleGrid({ articles }) {
  const navigate = useNavigate();
  const [articleImages, setArticleImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        articles.map(async (article) => {
          const imageUrl = await GetArticleImage(article.articleImageKey);
          return { [article.id]: imageUrl };
        })
      );
      setArticleImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };

    fetchImages();
  }, [articles]);

  if (!articles || articles.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            mt: 5,
            mb:5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            border: '1px solid #e0e0e0',
            borderRadius:"15px",
          }}
        >
          <Typography sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1.2rem' }, textAlign: 'center' }}>
            It looks like this user hasn’t shared any articles yet.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} mt={1}>
        {articles.map((article) => (
          <Grid item key={article.id} xs={12}
          sm={4}
          md={4}
          lg={3}
          mb={2}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: '150px',
                width: "100%",
                borderRadius: 2,
                position: "relative",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
              onClick={() => navigate(`/articles/${article.category}/${article.id}`)}
            >
              <CardMedia
                component="img"
                height="250"
                image={articleImages[article.id] || ""}
                alt={article.name || "Article Image"}
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 0,
                }}
                />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />

              <CardContent
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 2,
                  color: "white",
                  p: 2,
                }}
              >
                <Typography sx={{ color: "white", fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '0.8rem' }, userSelect: 'none' }}>
                  {article.title}
                </Typography>
              </CardContent>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ArticleGrid;

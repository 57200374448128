import ApiInstance from "../configs/ApiInstance";

async function GetAllBooks(page, size ) {
  try {
    const response = await ApiInstance.bookService.request({
      method: "get", 
      params: {
        size: size, page: page
      }
    });
    const { content, totalPages } = response.data;
    return { content, totalPages };
  } catch (error) {
    return null;
    // console.log(error);
    // throw error;
  }
}
export default GetAllBooks;

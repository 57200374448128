import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Button, Container, Grid, Stack, TextField, Typography, Snackbar, CircularProgress, FormHelperText, Select, MenuItem, Chip, Collapse, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateAwsUserDetails from "../services/UpdateAwsUserDetails";
import GetUserDetails from '../services/GetUserData';
import FetchProfileImage from '../services/FetchProfileImage';
import PutUserDetails from '../services/PutUserDetails';
import LoadingScreen from './LoadingScreen';
import UpdateProfilePicture from '../services/UpdateProfilePicture';
import CloseIcon from '@mui/icons-material/Close';
import { navigateToUrl } from 'single-spa';

import "../styles/styles.css"

const categories = [
    "Fantasy",
    "Science Fiction",
    "Mystery",
    "Thriller",
    "Romance",
    "Historical Fiction",
    "Horror",
    "Adventure",
    "Action",
    "Teen Fiction",
    "Drama",
    "True Crime",
    "History",
    "Paranormal",
    "Werewolf",
    "Vampire",
    "Fan Fiction",
    "Dark Romance",
    "New Adult",
    "LGBTQ+",
    "Mythology",
    "Humor",
    "Poetry",
    "Short Stories",
    "General Fiction",
    "Non-Fiction",
    "Random",
    "Spiritual",
];

function EditProfile() {
    const [bio, setBio] = useState('');
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [nickname, setNickname] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [openError, setOpenError] = useState(false);

    const [fileError, setFileError] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleReload = () => {
        return new Promise((resolve) => {
            setIsLoading(true);  
            setTimeout(() => {
                navigateToUrl(`/`);  
                resolve(); 
            }, 2000);
        });
    };


    const showAlert = (message) => {
        setErrorMessage(message);
        setOpenError(true);
    };

    const handleErrorClose = () => {
        setErrorMessage('');
        setOpenError(false);
    };

    const handleCategoryChange = (event) => {
        const { value } = event.target;
        if (value.length <= 4) {
            setSelectedCategories(value);

            // Automatically close the menu when 4 categories are selected
            if (value.length === 4) {
                document.activeElement.blur();
            }
        } else {
            showAlert({ message: "You can select up to 4 categories only." });
        }
    };

    const handleRemoveCategory = (category) => {
        setSelectedCategories((prev) => prev.filter((item) => item !== category));
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = getUserAttribute(parsedUser, 'nickname');
            fetchUserDetails(nickname);
        }
    }, []);

    const fetchUserDetails = async (nickname) => {
        try {
            const userDetails = await GetUserDetails(nickname);
            setUserData(userDetails);
            setNickname(userDetails.nickname);
            setGivenName(userDetails.given_name);
            setFamilyName(userDetails.family_name);
            setSelectedCategories(userDetails.favourites)
            setBio(userDetails.bio || '');
            if (userDetails.profileImageKey) {
                const imageUrl = await FetchProfileImage(userDetails.profileImageKey);
                setProfileImage(imageUrl);
            }
        } catch (error) {
            showAlert("Failed to fetch user details. Please try again.");
        }
    };

    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const charLimit = 300;

    const handleBioChange = (event) => {
        const newBio = event.target.value;
        if (newBio.length <= charLimit) {
            setBio(newBio);
        } else {
            showAlert("Character limit reached!");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        const accessToken = userData?.AccessToken || localStorage.getItem('accessToken');
        const attributes = [
            { Name: 'given_name', Value: givenName },
            { Name: 'family_name', Value: familyName },
        ];
        if (!familyName) {
            showAlert("Please provide your last name.");
            return;
        }
        if (!nickname) {
            showAlert("Please provide a nickname.");
            return;
        }
        if (!bio) {
            showAlert("Please provide a bio.");
            return;
        }
        if (!selectedCategories.length) {
            showAlert("Please select at least one category.");
            return;
        }
        if (!accessToken) {
            showAlert("Your session has expired. Please log out and try again.");
            return;
        }

        try {
            await UpdateAwsUserDetails(accessToken, attributes);
            await PutUserDetails(nickname, givenName, familyName, bio, selectedCategories);
            handleReload();
        } catch (error) {
            showAlert("An error occurred while saving your profile. Please try again.");
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            setFileError("No file selected.");
            return;
        }

        const validTypes = ["image/png", "image/jpeg", "image/jfif"];
        if (!validTypes.includes(file.type)) {
            setFileError("Only PNG, JPG, and JFIF images are allowed.");
            return;
        }

        const maxSize = 1 * 1024 * 1024;
        if (file.size > maxSize) {
            setFileError("File size must be less than 1MB.");
            return;
        }

        if (file) {
            try {
                await UpdateProfilePicture(userData.profileImageKey, file);
                setProfileImage(URL.createObjectURL(file));
                window.location.reload();
            } catch (error) {
                showAlert("Error updating profile picture. Please try again.");
            }
        }
    };

    // if (userData) {
    //     return (
    //         <Container maxWidth="xs">
    //             <Box
    //                 sx={{
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                     height: '100vh'
    //                 }}
    //             >
    //                 <CircularProgress sx={{ color: 'black', fontSize:'inherit' }} />
    //             </Box>
    //         </Container>
    //     );
    // }

    if (isLoading || !userData) {
        return <LoadingScreen />;
      }


    return (

        <Container
            maxWidth="lg"
            sx={{
                mb:10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container
                maxWidth="md"
                sx={{
                    mt: 5,
                    mb: 5
                }}
            >
                <Stack spacing={3} alignItems="center">
                    <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar
                            src={profileImage}
                            alt={nickname}
                            sx={{
                                width: { xs: 80, sm: 100, md: 120 },
                                height: { xs: 80, sm: 100, md: 120 },
                            }}
                        />
                        <Button
                            disableRipple
                            disableFocusRipple
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: {xs:80, sm: 90, md: 110},
                                backgroundColor: 'transparent',
                                padding: 1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            component="label"
                        >
                            <EditIcon sx={{ color: 'black' }} />
                            <input
                                type="file"
                                 accept="image/png, image/jpeg"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Box>
                        {fileError && <FormHelperText sx={{ color: 'red' }}>{fileError}</FormHelperText>}

                    <Box sx={{ width: '100%' }}> 
                        <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Username</Typography>
                        <TextField
                            variant="outlined"
                            inputProps={{ readOnly: true }}
                            value={nickname}
                            fullWidth
                            className='gradient-textfield'
                            sx={{ '& .MuiInputBase-input': { cursor: 'default' } }}
                        />
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Bio</Typography>
                        <TextField
                            fullWidth
                            className='gradient-textfield'
                            variant="outlined"
                            multiline
                            rows={5}
                            value={bio}
                            onChange={handleBioChange}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {bio.length}/{charLimit} characters
                        </Typography>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ marginBottom: 1 }} fontWeight={600}>First Name</Typography>
                        <TextField
                            variant="outlined"
                            className='gradient-textfield'
                            value={givenName}
                            onChange={(e) => setGivenName(e.target.value)}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Last Name</Typography>
                        <TextField
                            variant="outlined"
                            className='gradient-textfield'
                            value={familyName}
                            onChange={(e) => setFamilyName(e.target.value)}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Favorite Categories</Typography>
                        <Select
                            fullWidth
                            className='gradient-select'
                            name="categories"
                            multiple
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                            onClose={() => {
                                if (selectedCategories.length === 4) {
                                    document.activeElement.blur(); 
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 5 + 8,  
                                        width: 250, 
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                            }}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category} value={category.replace(/[\s%20]+/g, '_').toUpperCase()}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Select up to 4 categories</FormHelperText>

                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            {selectedCategories.map((value) => (
                                <Chip 
                                    key={value}  
                                    label={value.replace(/_/g, ' ').toLowerCase()} 
                                    sx={{
                                        backgroundColor: "black",
                                        color: "white",
                                        "& .MuiChip-deleteIcon": {
                                            color: "white",
                                        },
                                        "&:hover": {
                                            backgroundColor: "black",   
                                            color: "white",              
                                            "& .MuiChip-deleteIcon": {
                                                color: "white",          
                                            },
                                        },
                                    }}
                                    onDelete={() => handleRemoveCategory(value)}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                            type="submit"
                            onClick={handleSave}
                            variant="contained"
                            sx={{
                                fontWeight: 550,
                                letterSpacing: 2,
                                background: 'black',
                                "&:hover": {
                                    background: 'black',
                                },
                            }}
                        >
                            Save Changes
                        </Button>
                    </Box>
                </Stack>
            </Container>

            <Snackbar
                open={openError}
                autoHideDuration={3000}
                onClose={handleErrorClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleErrorClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
            
        </Container>


    );
}

export default EditProfile;

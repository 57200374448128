import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function Content({ article }) {

    const [articles, setArticle] = useState([]);

    const createChapters = () => {
        const rawContent = article.content.split(/<\/?p>/); 
        const validChapters = rawContent.filter(chapter => chapter.trim() !== '');
        console.log(validChapters);
        setArticle(validChapters);
    };
    
    
    useEffect(() => {
        createChapters();
    }, [article.content]);

    return (
        <Container maxWidth='xl' sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid
                    item
                    md={2}
                    mt={56}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%'
                    }}
                >

                </Grid>
                <Grid
                    item
                    md={8}
                >
                    <Box
                        sx={{
                            minHeight: "1000px",
                            mb: 10,
                        }}
                    >
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography
                                    variant='h4'
                                    fontFamily={'serif'}
                                    sx={{ fontWeight: 700 }}
                                >
                                    {article.title}
                                </Typography>
                                <Typography
                                    variant='body1'
                                    fontFamily={'serif'}
                                    color="text.secondary"
                                >
                                    By {article.author}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    fontFamily={'serif'}
                                    color="text.secondary"
                                >
                                    {article.updatedAt}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Typography
                                variant="body1"
                                component="div"
                                paragraph
                                sx={{
                                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1rem' },
                                    lineHeight: 1.8,
                                    textAlign: 'left',
                                    '& p': {
                                        marginBottom: '4px',
                                    },
                                    '& br': {
                                        margin: '0px 0',
                                        display: 'block',
                                        content: '""',
                                    },
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: `<p>${articles}</p>`,
                                }}
                            />
                        </Stack>
                    </Box>
                    <Divider sx={{ mb: 5 }} />
                </Grid>
                <Grid
                    item
                    md={2}
                    mt={55}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%',
                    }}
                >

                </Grid>
            </Grid>
        </Container>
    );
}

export default Content;

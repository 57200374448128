import React, { useEffect, useState } from 'react';
import {
    Container,
    Avatar,
    Typography,
    Grid,
    Box,
    CssBaseline,
    Stack,
    Tabs,
    Tab
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ArticleGrid from './ArticleGrid';
import Pagination from './Pagination';
import GetAllArticlesByAuthor from '../services/GetAllArticlesByAuthor';
import { useParams } from 'react-router-dom';
import GetUserDetails from '../services/GetUserData';
import FetchProfileImage from '../services/FetchProfileImage';
import GetAllBooksByAuthor from '../services/GetAllBooksByAuthor';
import BookGrid from './BookGrid';
import LoadingScreen from './LoadingScreen';

function Profile() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [articles, setArticles] = useState([]);

    const [currentBookPage, setCurrentBookPage] = useState(1);
    const [totalBookPages, setTotalBookPages] = useState(1);
    const [books, setBooks] = useState([]);

    const [profileImage, setProfileImage] = useState('');
    const [userDetails, setUserDetails] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const { id: nickname } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const itemsPerPage = 10;
    const articleItemsPerPage = 8;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleReload = () => {
        return new Promise((resolve) => {
            setIsLoading(true);
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };


    useEffect(() => {
        const fetchArticleData = async (page) => {
            try {
                const { content, totalPages } = await GetAllArticlesByAuthor(nickname, page - 1, articleItemsPerPage);
                const approvedArticles = content.filter(article => article.status === "ACCEPTED");

                const userDetails = await GetUserDetails(nickname);
                setUserDetails(userDetails);

                const profileImageId = await FetchProfileImage(userDetails.profileImageKey);
                setProfileImage(profileImageId);

                const sortedArticles = approvedArticles.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                setArticles(sortedArticles);

                setTotalPages(totalPages);
            } catch (error) {
                // Handle error
            }
        };

        const fetchBookData = async (page) => {
            setIsLoading(true);
            try {
                const { content, totalPages } = await GetAllBooksByAuthor(nickname, page - 1, itemsPerPage);
                setBooks(content);
                setTotalBookPages(totalPages);
                await handleReload();
            } catch (error) {
                // Handle error
            } finally {
                setIsLoading(false);
            }
        }
        fetchArticleData(currentPage);
        fetchBookData(currentBookPage)
    }, [currentPage, currentBookPage, nickname, itemsPerPage, articleItemsPerPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleBookPageChange = (page) => {
        setCurrentBookPage(page);
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (isLoading) {
        return <LoadingScreen />
    }


    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
                <Avatar
                    src={profileImage}
                    alt="Profile"
                    sx={{
                        width: {
                            xs: 75,
                            sm: 80,
                            md: 100,
                            lg: 120,
                        },
                        height: {
                            xs: 75,
                            sm: 80,
                            md: 100,
                            lg: 120,
                        },

                        margin: '0 auto',
                        border: '4px solid #ddd',
                    }}
                />
                <Typography variant="h5" fontWeight="bold" mt={2}>
                    {userDetails.nickname || "User Name"}
                </Typography>
                <Typography variant="body1" color="textSecondary" mt={1} sx={{ whiteSpace: 'pre-line' }}>
                    {userDetails.bio || "No bio available."}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mt={1}>
                    {/* <a href={`mailto:${userDetails.email}`} style={{ textDecoration: 'none' }}>
                    <EmailIcon fontSize="small" />
                        <Typography variant="body2" color="black">
                            {userDetails.email || "example@mail.com"}
                        </Typography>
                    </a> */}
                </Stack>
            </Box>


            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'black',
                        },
                        '& .MuiTab-root': {
                            color: 'black',
                            fontWeight: 'bold',
                            '&:hover': {
                                color: 'black',
                            },
                        },
                        '& .MuiTab-root.Mui-selected': {
                            color: 'black',
                            fontWeight: 'bold',
                        },
                    }}
                >
                    <Tab label="Books" />
                    <Tab label="Articles" />
                </Tabs>
            </Box>

            <Box>
                {tabValue === 0 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <BookGrid books={books} />
                        </Grid>
                        <Grid item xs={12}>
                            <Pagination
                                currentPage={currentBookPage}
                                totalPages={totalBookPages}
                                onPageChange={handleBookPageChange}
                            />
                        </Grid>
                    </Grid>
                )}
                {tabValue === 1 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ArticleGrid articles={articles} />
                        </Grid>
                        <Grid item xs={12}>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Container>
    );
}

export default Profile;

import ApiInstance from "../configs/ApiInstance";

async function GetAllBooks(page, size , category) {
    const response = await ApiInstance.bookService.request({
        method: "get",
        params: {
          size: size, page: page, category:category
        }
      });
      const { content, totalPages } = response.data;
      return { content, totalPages };
}

export default GetAllBooks; 
import ApiInstance from "../configs/ApiInstance";

async function SendMail(name, email, message) {

    const response = await ApiInstance.emailService.post('/contact', {
        name, email, message
    });

    return response.data;
}

export default SendMail;

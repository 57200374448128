import ApiInstance from "../configs/ApiInstance";

async function SendNotifications(nickname, message, url) {
    const response = await ApiInstance.userService.put(`${nickname}/notifications`,{
        message,
        url
    })
    return response.data
}

export default SendNotifications;;
import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

function AdPage() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('data-cfasync', 'false');
    script.src = 'https://pl25609186.profitablecpmrate.com/e72805251edeef681a97da3b4ebf1707/invoke.js';

    const adContainer = document.getElementById('container-e72805251edeef681a97da3b4ebf1707');
    if (adContainer) {
      adContainer.innerHTML = ''; // Clear any existing content
      adContainer.appendChild(script);
    }

    return () => {
      if (adContainer) {
        adContainer.innerHTML = ''; // Clean up script on unmount
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#fff',
        color: '#fff',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1300,
      }}
    >
      <Box
        id="container-e72805251edeef681a97da3b4ebf1707"
        sx={{
          width: { xs: '90%', sm: '70%', md: '50%' },
          textAlign: 'center',
          minHeight: '100px',
          mb: 3, // Margin bottom to separate from CircularProgress
        }}
      />
      {/* Circular Progress */}
      <CircularProgress size={60} sx={{ color: '#eb2f25' }} />
    </Box>
  );
}

export default AdPage;

import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignInService from '../services/SignInService';
import { navigateToUrl } from "single-spa";
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import "../styles/styles.css"
import GetAdminData from '../services/GetAdminData';
import GetAdminImage from '../services/GetAdminImage';
import LoadingScreen from './LoadingScreen';

import banner from '../images/banner.jpg'

const defaultTheme = createTheme();

function SignIn() {
  const [loginCoverImage, setLoginCoverImage] = useState();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleReload = () => {
    return new Promise((resolve) => {
      setIsLoading(true);
      setTimeout(() => {
        navigateToUrl(`/`);
        resolve();
      }, 2000);
    });
  };

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const adminData = await GetAdminData();
        const loginCoverImageKey = adminData.loginCoverImage;
        const loginCoverImageKeyUrl = await GetAdminImage(loginCoverImageKey);
        setLoginCoverImage(loginCoverImageKeyUrl);
      } catch (error) {
        // console.error("Error occurred when getting data", error);
      }
    };
    fetchAdminData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    setError('');

    try {
      const result = await SignInService(email, password);
      handleReload();
    } catch (error) {
      // console.error('Login failed:', error);
      setError('Invalid email or password. Please try again.');
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }


  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      />
      <Grid item xs={12} sm={8} md={5} square>
        <Box
          sx={{
            my: 15,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{
              m: 1,
              width: 64,
              height: 64,
              backgroundColor: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              borderRadius: '50%',
              backgroundSize: 'cover',
            }}
          >
            <LockOutlinedIcon />
          </Avatar>



          <Box component="form" Validate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              className="gradient-textfield"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              className="gradient-textfield"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: 550,
                letterSpacing: 2,
                background: 'black',
                "&:hover": {
                  background: 'black',
                }
              }}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs={6} sx={{ textAlign: 'left' }}>
                <Link href="/signup" variant="body2" sx={{ textDecoration: 'none', color: 'black' }}>
                  {"Don't have an account?"}
                </Link>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Link href="/signin/forgotpassword" variant="body2" sx={{ textDecoration: 'none', color: 'black' }}>
                  {"Forgot password?"}
                </Link>
              </Grid>
            </Grid>


            {error && (
              <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignIn;

import { Alert, Box, Button, Container, CssBaseline, Drawer, Grid, MenuItem, Select, Snackbar, Stack, TextField, Typography, Breadcrumbs, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import UpdateBook from '../services/UpdateBook';
import GetBookById from '../services/GetBookById';
import GetUploadedImages from '../services/GetUploadedImages';
import buttonImage from '../images/buttonImage.png'
import EditIcon from '@mui/icons-material/Edit';
import LoadingScreen from './LoadingScreen';
import BookHomePageButton from './BookHomePageButton';
import { navigateToUrl } from 'single-spa';

import Draft from '../images/Draft.png';
import NewChapter from '../images/NewChapter.png';
import Published from '../images/Published.png';

import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import GridOnIcon from '@mui/icons-material/GridOn';
import LexicalEditorComponent from './LexicalEditor/LexicalEditorComponent';
import DeleteBookById from '../services/DeleteBookById';

function Book() {

  const titleCharLimit = 60;
  const charLimit = 2500;

  const { bookId } = useParams();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [numberOfChapters, setNumberOfChapters] = useState('');
  const [bookStatus, setBookStatus] = useState('');

  const [chapters, setChapters] = useState([]);
  const [bookData, setBookData] = useState({});
  const [draftChapter, setDraftChapter] = useState({});
  const [bookCoverImage, setBookCoverImage] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [openDrawer, setOpenDrawer] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const handleReload = () => {
    setOpenDrawer(false);
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleMoveBack = () => {
    setIsLoading(true);
    setTimeout(() => {
      navigateToUrl('/write')
    }, 2000);
  };


  const fetchBookData = async (bookId) => {
    const bookData = await GetBookById(bookId);
    setChapters(bookData.chapters)
    setNumberOfChapters(bookData.numberOfChapters);
    const bookCoverImage = await GetUploadedImages(bookData.imageKey);
    setBookCoverImage(bookCoverImage);
    setBookData(bookData);
    setTitle(bookData.title || '');
    setDescription(bookData.description || '');
    setBookStatus(bookData.status || '');
  };

  useEffect(() => {
    fetchBookData(bookId);
  }, [bookId]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const draftChapter = chapters.find((chapter) => chapter.status === "DRAFT");
    setDraftChapter(draftChapter)
  }, [chapters]);

  const handleEditClick = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    if (newTitle.length <= titleCharLimit) {
      setTitle(newTitle);
    }
  };

  const handleTabKey = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const newContent = description.substring(0, cursorPosition) + '    ' + description.substring(cursorPosition);
      setDescription(newContent);
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = cursorPosition + 4;
      }, 0);
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    if (newDescription.length <= charLimit) {
      setDescription(newDescription);
    }
  };

  const handleBookStatusChange = (event) => {
    setBookStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title.trim()) {
      setSnackbarMessage('Please enter a title.');
      setOpenSnackbar(true);
      return;
    }
    if (!description.trim()) {
      setSnackbarMessage('Please enter a description.');
      setOpenSnackbar(true);
      return;
    }

    try {
      await UpdateBook(title, description, bookStatus, numberOfChapters, bookId);
      handleReload();
    } catch (error) {
      console.error('Failed to create article', error);
    }

  };

  const handleDeleteBook = async () => {
    try {
        await DeleteBookById(bookId);
        handleMoveBack();
    } catch (error) {
        console.error("Error deleting chapter:", error);
    }
};


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{mb:7}}>


      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
              color="inherit"
              href="/"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
              color="inherit"
              href={`/write`}
            >
              <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Contributor
            </Link>
            <Link
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
              color="inherit"
              href={`/write/mystories`}
            >
              <GridOnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              My Stories
            </Link>
            <Typography
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
              color="inherit"
            >
              {bookData.title}
            </Typography>
          </Breadcrumbs>
        </Stack>
      </Container>

      <Grid container spacing={0} direction={{ xs: 'column', sm: 'row' }}>

        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              mt: 2,
              borderColor: 'black',
              borderRadius: 5,
              backgroundColor: 'black',
              display: { xs: 'block', lg: 'flex' },
              justifyContent: 'center',
              alignItems: 'center',
              height: { xs: '60vh', md: '35vh', sm: '40vh', lg: '80vh' },
              background: 'black',
              width: '100%',
              backgroundImage: `url(${bookCoverImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              padding: { xs: '10px', sm: '25px' }
            }}
          >
            <Stack spacing={1} mb={2}>
              <Typography variant="h4" fontWeight="550" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                <Box display="flex" alignItems="center">
                  {bookData.title}
                  <EditIcon sx={{ ml: 1, fontSize: '1.8rem', cursor: 'pointer' }} onClick={handleEditClick} />
                  <MoreVertIcon sx={{ ml: 2, fontSize: '1.8rem', cursor: 'pointer' }} onClick={handleOpenDialog} />
                </Box>
              </Typography>
              <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, whiteSpace: 'pre-wrap'}}>
                {bookData.description}
              </Typography>
              <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}> <strong>Published language:</strong> {bookData.language}</Typography>
              <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}> <strong>CopyRight Type :</strong> {bookData.copyright}</Typography>
              <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}> <strong>Audience Type :</strong> {bookData.rating}</Typography>
              <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}> <strong>Published Date :</strong> {bookData.createdAt}</Typography>
              <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}> <strong>Number of Chapters :</strong> {bookData.numberOfChapters}</Typography>
            </Stack>

            <Grid container spacing={3} justifyContent="flex-start">
              {draftChapter && (
                <Grid item xs={6} sm={4} lg={4} sx={{ mb: 2 }}>
                  <BookHomePageButton image={Draft} path={`/write/mystories/book/${bookId}/draft/${draftChapter.id}`} />
                </Grid>
              )}
              <Grid item xs={6} sm={4} lg={4} sx={{ mb: 2 }}>
                <BookHomePageButton image={Published} path={`/write/mystories/book/${bookId}/published`} />
              </Grid>
              {!draftChapter && (
                <Grid item xs={6} sm={4} lg={4} sx={{ mb: 2 }}>
                  <BookHomePageButton image={NewChapter} path={`/write/book/${bookId}/chapter`} />
                </Grid>
              )}
            </Grid>

          </Box>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: 600 },
            padding: 2,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'flex-start', mb: 1 }}>
          <Button
            onClick={handleDrawerClose}
            sx={{
              backgroundColor: 'transparent',
              color: 'black',
              fontSize: '1.5rem',
              padding: 0,
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <Box
          sx={{
            width: '100%',
            padding: 2,
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='h5'
                sx={{
                  marginBottom: 1,
                  letterSpacing: '0.1rem',
                  fontSize: { xs: '1.2rem', sm: '1.5rem' },
                }}
                fontWeight={600}
              >
                Book Title
              </Typography>
              <TextField
                className="gradient-textfield"
                required
                fullWidth
                variant='outlined'
                multiline
                rows={2}
                value={title}
                onChange={handleTitleChange}
                autoComplete="title"
                name="title"
                id="title"
                sx={{ mb: 2 }}
                InputProps={{
                  sx: {
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                  },
                }}
              />
              <Typography
                variant='caption'
                display='block'
                gutterBottom
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                {title.length}/{titleCharLimit} characters
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='h5'
                sx={{
                  marginBottom: 1,
                  letterSpacing: '0.1rem',
                  fontSize: { xs: '1.2rem', sm: '1.5rem' },
                }}
                fontWeight={600}
              >
                Book Description
              </Typography>
              <TextField
                className="gradient-textfield"
                required
                fullWidth
                variant='outlined'
                multiline
                rows={15}
                value={description}
                onChange={handleDescriptionChange}
                onKeyDown={handleTabKey}
                autoComplete="description"
                name="description"
                id="description"
                sx={{ mb: 2 }}
                InputProps={{
                  sx: {
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                  },
                }}
              />
              {/* <LexicalEditorComponent setContent={setDescription} /> */}
              <Typography
                variant='caption'
                display='block'
                sx={{
                  mt: 1,
                  fontSize: { xs: '0.8rem', sm: '1rem' }
                }}
              >
                {description.length}/{charLimit} characters
              </Typography>
            </Grid>

            {/* <Grid item xs={12}>
              <Typography
                variant='h5'
                sx={{
                  marginBottom: 1,
                  letterSpacing: '0.1rem',
                  fontSize: { xs: '1.2rem', sm: '1.5rem' },
                }}
                fontWeight={600}
              >
                Book Status
              </Typography>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={bookStatus}
                onChange={handleBookStatusChange}
                fullWidth
                sx={{ mb: 2 }}
              >
                <MenuItem value="ARCHIVED">Archived</MenuItem>
                <MenuItem value="PUBLISHED">Published</MenuItem>
                <MenuItem value="UNPUBLISHED">Unpublished</MenuItem>

              </Select>


            </Grid> */}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  fontWeight: 550,
                  letterSpacing: 2,
                  background: 'black',
                  "&:hover": {
                    background: 'black',
                  },
                  mb: 5,
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                }}
              >
                Update
              </Button>
            </Box>

          </Grid>
        </Box>
      </Drawer>



      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Delete Book</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Book?<br /> This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ color: 'black' }}>
            Cancel
          </Button>
          <Button onClick={handleDeleteBook} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  )
}

export default Book;

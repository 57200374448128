import React, { useState, useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, Box, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResetPasswordService from '../services/ResetPasswordService';
import "../styles/styles.css";
import { navigateToUrl } from "single-spa";
import LoadingScreen from './LoadingScreen';

const defaultTheme = createTheme();

const ResetPassword = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleReload = () => {
            return new Promise((resolve) => {
                setIsLoading(true);  
                setTimeout(() => {
                    navigateToUrl(`/`);  
                    resolve(); 
                }, 2000);
            });
        };

    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        setLoading(true);
        setMessage('');
        try {
            const response = await ResetPasswordService(email, verificationCode, newPassword);
            setMessage(response.message || 'Your password has been reset successfully.');
            setTimeout(() => {
                handleReload();
            }, 3000);
        }
        catch (error) {
            const errorMessage = error.response?.data?.message || 'Error occurred while resetting your password.';
            setMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    
  if (isLoading) {
    return <LoadingScreen />;
  }


    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Box
                sx={{
                    my: 10,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    sx={{
                        m: 1,
                        width: 64,
                        height: 64,
                        backgroundColor: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                    }}
                >
                    <LockOutlinedIcon />
                </Avatar>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="verificationCode"
                        label="Verification Code"
                        name="verificationcode"
                        autoFocus
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="gradient-textfield"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        label="New Password"
                        name="newpassword"
                        type="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="gradient-textfield"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="confirmPassword"
                        label="Confirm Password"
                        name="confirmpassword"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="gradient-textfield"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            fontWeight: 550,
                            letterSpacing: 2,
                            background: 'black',
                            "&:hover": {
                                background: 'black',
                            }
                        }}
                        disabled={loading}
                    >
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </Button>
                    {message && (
                        <Alert severity={message.includes('Error') || message.includes('do not match') ? 'error' : 'success'} sx={{ width: '100%', mt: 2 }}>
                            {message}
                        </Alert>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ResetPassword;

import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Profile from './components/Profile';
import UserProfile from './components/UserProfile';
import Article from './components/Article';
import Stats from './components/Stats';
import EditProfile from './components/EditProfile';


function App() {
  return (
    <Router>
        <Routes>
            <Route path='/profile' element={<EditProfile/>} />
            <Route path='/profile/:id' element={<Profile/>} />
            <Route path='/profile/:id/stats' element={<Stats/>} />
            <Route path='/profile/:id/articles/:articleId' element={<Article/>} />
        </Routes>
    </Router>
  )
}

export default App
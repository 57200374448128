import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

function ContentGuidelines() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 3 }}>
          Content Guidelines
        </Typography>

        <Typography variant="body1" paragraph>
          At Ceylon Diaries, we aim to create a safe and comfortable space for everyone to discover and share stories. To maintain an inclusive and respectful environment, we ask all users to adhere to the following guidelines:
        </Typography>

        <Typography variant="h6" gutterBottom>
          Mature Rating
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          Marking your story "Mature" does not mean it can contain prohibited content. Stories that contain prohibited content will be removed regardless of their rating. Mature stories are intended for an audience that is 18+ and may include:
          <ul>
            <li>Explicit sex scenes</li>
            <li>Self-harm themes or scenes (including suicide and eating disorders)</li>
            <li>Graphic depictions of violence, including but not limited to sexual, verbal, emotional, and physical abuse</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          What We Remove
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          The following are not allowed on Ceylon Diaries, and we reserve the right to remove any content that violates these guidelines:
          <ul>
            <li>Pornographic content</li>
            <li>Age of consent: All characters must be 18+ in all stories</li>
            <li>Glorification of sexual violence, including non-consensual acts or sexual slavery</li>
            <li>Sexual roleplay or solicitation, including private sexual messages</li>
            <li>Illegal sexual acts</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Media on Ceylon Diaries
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          Images must adhere to these guidelines:
          <ul>
            <li>No media displaying sexual intercourse, regardless of whether private parts are visible</li>
            <li>No media displaying self-harm or suicide</li>
            <li>No images of people posted without their consent, except for public figures and celebrities</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Self-Harm on Ceylon Diaries
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          Stories or content that encourage or give instruction on self-harm will be removed. Content about recovery or survival is encouraged if it promotes a positive message.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Hate Groups/Extremist Content
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          We do not allow content or organizations that promote violence or hatred towards groups or individuals. This includes:
          <ul>
            <li>Groups with a violent mission</li>
            <li>Organized hate groups</li>
            <li>Terrorist activity</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Talking About Others
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          Be kind and respectful when talking about others. Do not:
          <ul>
            <li>Bash a user purely for personal reasons</li>
            <li>Reveal any personally identifying information about others without consent</li>
            <li>Post defamatory or hateful remarks</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Spam
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'}>
          Any content that advertises unrelated products or services or irrelevant promotions will be removed.
        </Typography>

        <Typography variant="body1" paragraph textAlign="center" sx={{ mt: 3 }}>
          By following these guidelines, you contribute to a safe and enjoyable space for everyone on Ceylon Diaries.
        </Typography>
      </Box>
    </Container>
  );
}

export default ContentGuidelines;

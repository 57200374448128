import { Box, Container, Divider, Grid, Stack, Typography, Avatar, Fab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ArticleLeftAd from './ArticleLeftAd';
import ArticleRightAd from './ArticleRightAd';
import LikeAndCommentSection from './LikeAndCommentSection';
import { navigateToUrl } from 'single-spa';
import PlaceIcon from '@mui/icons-material/Place';
import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
import ChapterDrawer from './ChapterDrawer';

function Content({ article, profileImage, articleImageKey }) {

    const location = useLocation();
    
    const [articles, setArticle] = useState([]);
    const [hoveredChapter, setHoveredChapter] = useState(null); 
    const [drawerOpen, setDrawerOpen] = useState(false); 
    const [selectedChapter, setSelectedChapter] = useState(null);

     useEffect(() => {
            window.scrollTo(0, 0);
          }, [location]);
    

    const createChapters = () => {
        const rawContent = article.content.split(/<\/?p>/); 
        const validChapters = rawContent.filter(chapter => chapter.trim() !== '');
        setArticle(validChapters);
    };
    
    
    useEffect(() => {
        createChapters();
    }, [article.content]);

    const handleAvatarClick = (nickName) => {
        navigateToUrl(`/profile/${nickName}`);
    };

    const handleClick = () => {
        window.open(article.locationUrl, '_blank');
    };

    const handleChapterClick = (index) => {
        setSelectedChapter(articles[index]);
        setDrawerOpen(true); 
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleMouseEnter = (index) => {
        setHoveredChapter(index); 
    };

    const handleMouseLeave = () => {
        setHoveredChapter(null);
    };

    const getCommentCountForChapter = (chapterIndex) => {
        return article.comments.filter(
            (comment) => comment.chapterIndex === String(chapterIndex)
        ).length;
    };

    return (
        <Container maxWidth='xl' sx={{ mt: 3, mb:10 }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid
                    item
                    xs={12}
                    md={2}
                    mt={50}
                    sx={{
                        display: { xs: 'none', md: 'none', lg: 'block' },
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {/* <ArticleLeftAd /> */}
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        margin: { xs: 'auto', md: '0' },
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "1000px",
                            mb: 10,
                        }}
                    >
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography
                                    variant='h4'
                                    fontFamily={'serif'}
                                    sx={{ 
                                        fontWeight: 700,
                                        fontSize: { xs: '2rem', sm: '2.2rem', md: '2.2rem', lg: '2.5rem' }, 
                                     }}
                                >
                                    {article.title}
                                </Typography>
                                <Stack spacing={2}>
                                    <Stack spacing={1} direction='row' alignItems="center">
                                        <Avatar
                                            src={profileImage}
                                            sx={{
                                                width: { xs: 30, sm: 32 },
                                                height: { xs: 30, sm: 32 },
                                                cursor: 'pointer',
                                            }}
                                            alt={article.author}
                                            onClick={() => handleAvatarClick(article.author)}
                                        />
                                        <Stack direction='column' justifyContent='center'>
                                            <Typography
                                                variant='caption'
                                                fontFamily={'serif'}
                                                color="text.secondary"
                                            >
                                                By {article.author}
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                fontFamily={'serif'}
                                                color="text.secondary"
                                            >
                                                {article.updatedAt}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Divider />
                            <div>
                                {articles.map((chapter, index) => {
                                    const commentCount = getCommentCountForChapter(index);

                                    return (
                                        <Box
                                            key={index}
                                            id={`chapter-${index}`}
                                            sx={{
                                                position: 'relative',
                                            }}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => {
                                                if (commentCount === 0) {
                                                    handleMouseLeave();
                                                }
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="div"
                                                    paragraph
                                                    sx={{
                                                        fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1.1rem' },
                                                        lineHeight: 1.8,
                                                        textAlign: 'justify',
                                                        '& p': {
                                                            marginBottom: '4px',
                                                        },
                                                        '& br': {
                                                            margin: '0px 0',
                                                            display: 'block',
                                                            content: '""',
                                                        },
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: `<p>${chapter}</p>`,
                                                    }}
                                                />

                                                {/* Floating Action Button */}
                                                <Fab
                                                    size="small"
                                                    sx={{
                                                        position: 'absolute',
                                                        backgroundColor: 'transparent',  
                                                        boxShadow: 'none',  
                                                        padding: 0, 
                                                        top: { xs: '-20px', md: '-20px', sm: '-20px' },
                                                        right: { xs: '0px', md: '-10px', sm: '0px' },
                                                        zIndex: 1,
                                                        visibility:
                                                            hoveredChapter === index || commentCount > 0
                                                                ? 'visible'
                                                                : 'hidden',
                                                        opacity:
                                                            hoveredChapter === index || commentCount > 0
                                                                ? 1
                                                                : 0,
                                                        transition: 'opacity 0.3s, visibility 0.3s',
                                                        '&:active, &:focus': {
                                                           boxShadow: 'none', // Remove box shadow on press or focus
                                                        },
                                                    }}
                                                    onClick={() => handleChapterClick(index)}
                                                >
                                                    <CommentIcon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />
                                                    {commentCount > 0 && (
                                                        <Typography
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                color: 'black',
                                                                fontSize: { xs: '0.8rem', sm: '1rem' }                                                            }}
                                                        >
                                                            *
                                                        </Typography>
                                                    )}
                                                </Fab>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </div>


                        </Stack>
                        <Box
                            sx={{
                                width: '250px',
                                height: '150px',
                                display: article.locationUrl ? 'flex' : 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 2,
                                position: 'relative',
                                cursor: 'pointer',
                                overflow: 'hidden',
                            }}
                            onClick={handleClick}
                            aria-label="open external link"
                        >
                            <Box
                                component="img"
                                src={articleImageKey}
                                alt="Background"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 2,
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                    borderRadius: 2,
                                }}
                            />
                            <PlaceIcon
                                sx={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '10px',
                                    color: 'red',
                                    height: '45px',
                                    width: '48px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 5 }} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={2}
                    mt={50}
                    sx={{
                        display: { xs: 'none', md: 'none', lg: 'block' },
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {/* <ArticleRightAd /> */}
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <LikeAndCommentSection
                        articleId={article.id}
                        articleLikeCount={article.likeCount}
                        articleLikes={article.likes}
                        articleComments={article.comments}
                        articleAuthor={article.author}
                    />
                </Grid>
            </Grid>

            <ChapterDrawer
                articleId={article.id}
                articleComments={article.comments}
                title={article.title}
                open={drawerOpen}
                onClose={handleDrawerClose}
                chapterText={selectedChapter || ''}
                chapterIndex={articles.indexOf(selectedChapter)}
            />

        </Container>


    );
}

export default Content;

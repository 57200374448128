import React from 'react';
import { Drawer, Box, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChapterDrawerComment from './ChapterDrawerComment';
import ChapterDrawerCommentBox from './ChapterDrawerCommentBox';

function ChapterDrawer({ open, onClose, chapterText, chapterIndex, onCommentSubmit, chapterComments, chapterId, bookId }) {

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        width: { xs: '50%', sm: '75%', md: '500px' },
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: '75%', md: '500px' },
          backgroundColor: '#fff',
          padding: { xs: '10px', sm: '15px', md: '20px' },
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ position: 'absolute', top: 10, left: 10, display: { xs: 'block', sm: 'none' } }}>
        <IconButton onClick={onClose} sx={{ color: 'black' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <Box
          sx={{
            maxHeight: { xs: '60vh', sm: '70vh' },
            overflowY: 'auto',
            mb: 2,
            mt:5,
            p:2
          }}
          dangerouslySetInnerHTML={{ __html: `<p>${chapterText}</p>` }}
        />
        <Divider sx={{mb:2}}/>
        <ChapterDrawerComment data={chapterComments} chapterId={chapterId} chapterIndex={chapterIndex} bookId={bookId} />
        <ChapterDrawerCommentBox bookId={bookId} chapterId={chapterId} chapterIndex={chapterIndex} />
      </Box>
    </Drawer>
  );
}

export default ChapterDrawer;

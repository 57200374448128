import React from 'react';
import { Box, Container } from "@mui/material";

function Banner({ articleImageKey }) {
    return (
        <Container maxWidth="lg">
        <Box
            sx={{
                display: 'flex',
                borderRadius: 5,
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                height: '100%',
                mb: 2,
                mt:2
            }}
        >
            <Box
                component="img"
                sx={{
                    width: '100%',
                    height: {xs:'200px', sm:'300px', md:'500px', },
                    objectFit: 'cover',
                }}
                src={articleImageKey}
                alt="Banner Image"
            />
        </Box>
    </Container>
    );
}

export default Banner;

import ApiInstance from "../configs/ApiInstance";

async function DeleteArticle(articleId) {
    try {
        const response = await ApiInstance.articleService.delete(`/${articleId}`);
    } catch (error) {
        console.error('Error deleting article:', error);
        throw error;
    }
}
export default DeleteArticle;
import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent, CardActions, Chip } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetArticleImage from '../services/GetArticleImage';
import { useNavigate } from 'react-router-dom';


function BookGrid({ books }) {
  const navigate = useNavigate();
  const [bookImages, setBookImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        books.map(async (article) => {
          const imageUrl = await GetArticleImage(article.imageKey);
          return { [article.id]: imageUrl };
        })
      );
      setBookImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };

    fetchImages();
  }, [books]);

  if (!books || books.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            mt: 5,
            mb:5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            border: '1px solid #e0e0e0',
            borderRadius:"15px",
          }}
        >
          <Typography sx={{fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1.2rem' }, textAlign: 'center' }}>
            This user hasn't published any books yet.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} mt={1}>
        {books.map((book) => (
          <Grid item key={book.id} xs={6} sm={3} md={2}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                borderRadius: 2,
                height: { xs: '220px', md: '250px', sm: '225px' },
                width: "100%",
                position: "relative",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.01)",
                  zIndex: 100,
                },
              }}
              onClick={() => navigate(`/book/${book.id}`)}
            >
              <CardMedia
                component="img"
                src={bookImages[book.id] || ""}
                alt={book.title}
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />

              <CardContent
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 2,
                  color: "white",
                  p: 2,
                }}
              >
                <Typography sx={{ color: "white", fontSize: { xs: '0rem', sm: '1rem', md: '1rem', lg: '0.8rem' }, userSelect: 'none'}}>
                  {book.title}
                </Typography>
              </CardContent>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default BookGrid;

import ApiInstance from "../configs/ApiInstance";

async function GetAllBooksByAuthor(author,currentPage) {
  try {
    const response = await ApiInstance.bookService.get(`/list/${author}`,
      {
        params: { size: 10, page: currentPage - 1, sort: 'createdDate,desc' }, 
      }
    );
    return {
      content: response.data.content,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default GetAllBooksByAuthor;

import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, IconButton, Select, MenuItem, FormControl, InputLabel, Grid } from "@mui/material";
import { css } from "@emotion/css";
import {
  HEADINGS,
  LOW_PRIORIRTY,
  RichTextAction,
  RICH_TEXT_OPTIONS,
} from "../constants";
import { Divider } from "../components/Divider";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";

import { mergeRegister } from "@lexical/utils";
import { HeadingTagType, $createHeadingNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { useKeyBindings } from "../hooks/useKeyBindings";

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [disableMap, setDisableMap] = useState({
    [RichTextAction.Undo]: true,
    [RichTextAction.Redo]: true,
  });
  const [selectionMap, setSelectionMap] = useState({
    [RichTextAction.Bold]: false,
    [RichTextAction.Italics]: false,
    [RichTextAction.Underline]: false,
    [RichTextAction.Strikethrough]: false,
    [RichTextAction.Superscript]: false,
    [RichTextAction.Subscript]: false,
    [RichTextAction.Code]: false,
    [RichTextAction.Highlight]: false,
    [RichTextAction.BulletedList]: false,
    [RichTextAction.NumberedList]: false,
  });

  // State to track selected heading
  const [selectedHeading, setSelectedHeading] = useState("");

  const updateToolbar = () => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const parentNode = selection.anchor.getNode().getParent();
      const isList = parentNode ? parentNode.getType() === "list" : false;

      const newSelectionMap = {
        [RichTextAction.Bold]: selection.hasFormat("bold"),
        [RichTextAction.Italics]: selection.hasFormat("italic"),
        [RichTextAction.Underline]: selection.hasFormat("underline"),
        [RichTextAction.Strikethrough]: selection.hasFormat("strikethrough"),
        [RichTextAction.Superscript]: selection.hasFormat("superscript"),
        [RichTextAction.Subscript]: selection.hasFormat("subscript"),
        [RichTextAction.Code]: selection.hasFormat("code"),
        [RichTextAction.Highlight]: selection.hasFormat("highlight"),
        [RichTextAction.BulletedList]: isList && parentNode.getTag() === "ul",
        [RichTextAction.NumberedList]: isList && parentNode.getTag() === "ol",
      };
      setSelectionMap(newSelectionMap);
    }
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LOW_PRIORIRTY
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setDisableMap((prev) => ({
            ...prev,
            undo: !payload,
          }));
          return false;
        },
        LOW_PRIORIRTY
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setDisableMap((prev) => ({
            ...prev,
            redo: !payload,
          }));
          return false;
        },
        LOW_PRIORIRTY
      )
    );
  }, [editor]);

  const onAction = (id) => {
    switch (id) {
      case RichTextAction.Bold:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        break;
      case RichTextAction.Italics:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        break;
      case RichTextAction.Underline:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        break;
      case RichTextAction.Strikethrough:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        break;
      case RichTextAction.Superscript:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
        break;
      case RichTextAction.Subscript:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
        break;
      case RichTextAction.Highlight:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "highlight");
        break;
      case RichTextAction.Code:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
        break;
      case RichTextAction.LeftAlign:
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        break;
      case RichTextAction.RightAlign:
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        break;
      case RichTextAction.CenterAlign:
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        break;
      case RichTextAction.JustifyAlign:
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        break;
      case RichTextAction.BulletedList:
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        break;
      case RichTextAction.NumberedList:
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        break;
      case RichTextAction.Undo:
        editor.dispatchCommand(UNDO_COMMAND, undefined);
        break;
      case RichTextAction.Redo:
        editor.dispatchCommand(REDO_COMMAND, undefined);
        break;
    } 
  };

  useKeyBindings({ onAction });

  const getSelectedBtnProps = (isSelected) =>
    isSelected
      ? {
          color: "primary",
          variant: "contained",
        }
      : {};

  const updateHeading = (heading) => {
    setSelectedHeading(heading); // Update selected heading value
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode(heading));
      }
    });
  };

  // Handle new lines and reset heading to default
  useEffect(() => {
    const handleNewLine = () => {
      setSelectedHeading(""); // Reset to default (empty string)
    };

    // Register the command for detecting new block elements (new lines)
    const unregisterNewLineListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) && selection.isCollapsed()) {
          // Reset heading when a new block or line is created
          handleNewLine();
        }
      });
    });

    return () => unregisterNewLineListener();
  }, [editor]);

  return (
    <Box display="flex" flexDirection="row" gap={4} flexWrap="wrap">
      <ButtonGroup
        size="small"
        variant="text"
        sx={{
          "& > button": { borderRadius: 0 },
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <FormControl size="small" sx={{ mr: 2, width: { xs: "100%", sm: "200px" } }}>
          <InputLabel>Select Heading</InputLabel>
          <Select
            value={selectedHeading} // Bind value to the selectedHeading state
            label="Select Heading"
            onChange={(e) => updateHeading(e.target.value)} // Update heading when changed
          >
            {HEADINGS.map((heading) => (
              <MenuItem key={heading} value={heading}>
                {heading}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {RICH_TEXT_OPTIONS.map(({ id, label, icon, fontSize }) =>
          id === RichTextAction.Divider ? (
            <Divider key={id} />
          ) : (
            <IconButton
              aria-label={label}
              sx={{ fontSize: fontSize }}
              onClick={() => onAction(id)}
              disabled={disableMap[id]}
              {...getSelectedBtnProps(selectionMap[id])}
              key={id}
            >
              {icon}
            </IconButton>
          )
        )}
      </ButtonGroup>
    </Box>
  );
}

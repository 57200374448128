import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography, Card, CardMedia, CardContent, Pagination } from '@mui/material';
import { navigateToUrl } from 'single-spa';

import Filter from './Filter';
import LoadingScreen from './LoadingScreen';
import GetAllBooks from '../services/GetAllBooks';
import GetArticleImage from '../services/GetArticleImage';
import GetUserDetails from '../services/GetUserData';

function Library() {
  const [searchQuery, setSearchQuery] = useState("");
  const [nickname, setNickname] = useState('');
  const [userData, setUserData] = useState({});
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarkIds, setBookmarkIds] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [bookImages, setBookImages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 12;
  const [isLoading, setIsLoading] = useState(true);

  const handleReload = () => {
    return new Promise((resolve) => {
      setIsLoading(true);
      setTimeout(() => {
        resolve();
      }, 500);
    });
  };

  useEffect(() => {
    const fetchBooks = async () => {
      setIsLoading(true);
      try {
        const { content, totalPages } = await GetAllBooks(0, 999); // Fetch all books without pagination
        setAllBooks(content);
        setTotalPages(Math.ceil(content.length / itemsPerPage)); // Calculate total pages based on all books
        await handleReload();
      } catch (error) {
        // console.error("Error fetching books:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBooks();
  }, []);

  useEffect(() => {
    const filterBooks = () => {
      let booksToFilter = allBooks;
      if (searchQuery.trim() !== "") {
        booksToFilter = booksToFilter.filter(book =>
          book.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      // Filter by bookmarkIds
      booksToFilter = booksToFilter.filter(book => bookmarkIds.includes(book.id));
      
      setFilteredBooks(booksToFilter);
    };
    filterBooks();
  }, [allBooks, searchQuery, bookmarkIds]);

  useEffect(() => {
    const fetchImages = async () => {
      const booksToFetch = searchQuery.trim() === "" ? allBooks : filteredBooks;
      if (booksToFetch.length === 0) return;

      try {
        const images = await Promise.all(
          booksToFetch.map(async (book) => {
            const imageUrl = await GetArticleImage(book.imageKey);
            return { id: book.id, imageUrl };
          })
        );

        const imagesMap = images.reduce((acc, { id, imageUrl }) => {
          acc[id] = imageUrl;
          return acc;
        }, {});

        setBookImages(imagesMap);
      } catch (error) {
        console.error("Error fetching article images:", error);
      }
    };

    fetchImages();
  }, [allBooks, filteredBooks, searchQuery]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = getUserAttribute(parsedUser, 'nickname');
      fetchUserDetails(nickname);
    }
  }, []);

  useEffect(() => {
    if (bookmarks.length > 0) {
      const bookmarkIds = bookmarks.map((bookmark) => bookmark.bookId);
      setBookmarkIds(bookmarkIds);
    }
  }, [bookmarks]);

  const fetchUserDetails = async (nickname) => {
    try {
      const userDetails = await GetUserDetails(nickname);
      localStorage.setItem('role', userDetails.role);
      setUserData(userDetails);
      setBookmarks(userDetails.bookmarks);
      setNickname(userDetails.nickname);
    } catch (error) {
      console.error("Error occurred when getting user data", error);
    }
  };

  const getUserAttribute = (user, name) => {
    const attribute = user.UserAttributes.find(attr => attr.Name === name);
    return attribute ? attribute.Value : 'N/A';
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1); 
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

   useEffect(() => {
    setTotalPages(Math.ceil(filteredBooks.length / itemsPerPage));
  }, [filteredBooks]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const booksToDisplay = filteredBooks.slice(startIndex, endIndex);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{mb:10}}>
      <Typography mt={2} sx={{ fontWeight: 550, fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', textAlign: 'center' } }}>Library</Typography>

      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Filter onSearch={handleSearch} />
      </Box>

      <Grid container spacing={3} mt={3}>
        {booksToDisplay.length > 0 ? (
          booksToDisplay.map((book) => (
            <Grid item xs={6} sm={3} md={2} key={book.id} justifyItems="center" alignItems="center">
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  borderRadius: 2,
                  height: { xs: '220px', md: '250px', sm: '225px' },
                  width: { xs: '90%', md: '100%', sm: '80%' },
                  position: "relative",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.01)",
                    zIndex: 100,
                  },
                }}
                onClick={() => navigateToUrl(`/book/${book.id}`)}
              >
                <CardMedia
                  component="img"
                  src={bookImages[book.id] || ""}
                  alt={book.title}
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                    zIndex: 1,
                    pointerEvents: "none",
                  }}
                />
                <CardContent
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 2,
                    color: "white",
                    p: 2,
                  }}
                >
                  <Typography sx={{ color: "white", fontSize: { xs: '0rem', sm: '0.7rem', md: '0.8rem', lg: '0.9rem' }, userSelect: 'none' }}>
                    {book.title || "No Title"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',  
              width: '100%',
              p: 5,
              textAlign: 'center',  
            }}
          >
            <Typography sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1rem' }, textAlign: 'center' }}>
              There are no bookmarked stories in your library. Bookmark the stories you like for quick and easy access later.
            </Typography>
          </Box>
        )}
      </Grid>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="circular"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
          mb: 5,
          "& .MuiPaginationItem-root": {
            color: "black",
          },
          "& .MuiPaginationItem-outlined": {
            borderColor: "transparent",
          },
          "& .Mui-selected": {
            background: 'black !important',
            color: "white",
            borderColor: "transparent",
          },
        }}
      />
    </Container>
  );
}

export default Library;

import ApiInstance from "../configs/ApiInstance";

const GetUploadedImages = async (imageUploadResponse) => {
  try {
    const response = await ApiInstance.userAwsService.get(`/article-image/${imageUploadResponse}`);
    const imageUrl = response.data.url;
    return imageUrl;
  } catch (error) {
    console.error('Failed to fetch uploaded images:', error);
    throw error;
  }
};

export default GetUploadedImages;

import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, Menu, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetUploadedImages from '../services/GetUploadedImages';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoadingScreen from './LoadingScreen'
import DeleteChapter from '../services/deleteChapter';
import GetBookById from '../services/GetBookById';
import UpdateBook from '../services/UpdateBook';

function ChapterGrid({ chapters }) {
    const { bookId } = useParams();
    const [bookCoverImage, setBookCoverImage] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedChapterId, setSelectedChapterId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [chapterIdToDelete, setChapterIdToDelete] = useState(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [bookStatus, setBookStatus] = useState('');
    const [newNumberOfChapters, setNewNumberOfChapters] = useState('');

    useEffect(() => {
        const fetchImages = async () => {
            const images = await Promise.all(
                chapters.map(async (chapter) => {
                    const imageUrl = await GetUploadedImages(chapter.imageKey);
                    return { [chapter.id]: imageUrl };
                })
            );
            setBookCoverImage(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
        };

        fetchBookData(bookId);
        fetchImages();
    }, [chapters]);

    const fetchBookData = async (bookId) => {
        const bookData = await GetBookById(bookId);
        const numberOfChapters = bookData.numberOfChapters;
        const newNumberOfChapters = numberOfChapters - 1;
        setNewNumberOfChapters(newNumberOfChapters);
        setTitle(bookData.title || '');
        setDescription(bookData.description || '');
        setBookStatus(bookData.status || '');
    };

    const handleReload = () => {
        setIsLoading(true);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    };

    const DeleteChapterById = async (bookId) => {
        try {
            await UpdateBook(title, description, bookStatus, newNumberOfChapters, bookId)
            await DeleteChapter(bookId, chapterIdToDelete);
            handleCloseMenu();
            handleReload();
        } catch (error) {
            console.error("Error deleting chapter:", error);
        }
    };
    const handleClickMenu = (event, chapterId) => {
        setAnchorEl(event.currentTarget);
        setSelectedChapterId(chapterId);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSelectedChapterId(null);
    };

    const handleNavigate = (path) => {
        navigateToUrl(path);
        handleCloseMenu();
    };

    const handleDeleteChapter = async () => {
        try {
            await DeleteChapterById(bookId, chapterIdToDelete);
        } catch (error) {
            console.error("Error deleting chapter:", error);
        }
    };

    const handleOpenDialog = (chapterId) => {
        setChapterIdToDelete(chapterId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setChapterIdToDelete(null);
    };


    const draftChapters = chapters.filter(chapter => chapter.status === "PUBLISHED");

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (!draftChapters || draftChapters.length === 0) {
        return (
            <Container maxWidth="xl">
                <Box
                    sx={{
                        mt: 5,
                        mb:10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75vh',
                        border: '1px solid #e0e0e0',
                        borderRadius:"15px",
                     }}
                >
                    <Typography
                        component="h2"
                        fontFamily='serif'
                        sx={{
                            fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1.2rem' }
                        }}
                    >
                        No Published Chapters Available
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <Box sx={{ height: '70vh', mt:2}}>
            <Grid container spacing={2}>
                {draftChapters.map((chapter) => (
                    <Grid item key={chapter.id} xs={12} sm={6} md={4} lg={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: '150px',
                                    width: "80%",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "8px",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(1.01)",
                                    },
                                    marginBottom: 1,
                                    position: 'relative',
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={bookCoverImage[chapter.id] || ""}
                                    alt={chapter.title}
                                    sx={{
                                        objectFit: "cover",
                                        borderTopLeftRadius: "8px",
                                        borderTopRightRadius: "8px",
                                    }}
                                    onClick={() => navigateToUrl(`/write/mystories/book/${bookId}/published/${chapter.id}`)}
                                />
                                <IconButton
                                    sx={{
                                        size: '10px',
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        },
                                        width: 32,
                                        height: 32,
                                    }}
                                    onClick={(event) => handleClickMenu(event, chapter.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                            </Card>

                            <Typography
                                sx={{
                                    color: 'black',
                                    fontSize: '0.90rem',
                                    marginTop: '8px',
                                    width: "225px",
                                    marginBottom: '16px',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    textAlign: 'center',
                                }}
                            >
                                {chapter.title}
                            </Typography>
                        </Box>

                        <Menu
                            sx={{
                                mt: 1
                            }}
                            anchorEl={anchorEl}
                            open={selectedChapterId === chapter.id}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={() => handleNavigate(`/write/mystories/book/${bookId}/published/${chapter.id}`)}>View</MenuItem>
                            <MenuItem onClick={() => handleOpenDialog(chapter.id)}>Delete</MenuItem>
                        </Menu>
                    </Grid>
                ))}
            </Grid>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Delete Chapter</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this chapter?<br/> This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} sx={{color: 'black'}}>
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteChapter} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            </Box>
        </Container>
    );
}

export default ChapterGrid;

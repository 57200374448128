import { Box, Container, IconButton, Typography, Snackbar, Alert, Slide, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
import AddLike from '../services/AddLike';
import UnLike from '../services/UnLike';
import Comment from './Comment';
import CommentBox from './CommentBox';

import IncrementTotalLikeCount from '../services/IncrementTotalLikeCount';
import DecrementTotalLikeCount from '../services/DecrementTotalLikeCount';

function LikeAndCommentSection({ bookId, chapterId, chapterLikeCount, chapterLikes, chapterComments,bookAuthor }) {
  const [likeCount, setLikeCount] = useState(chapterLikeCount);
  const [hasLiked, setHasLiked] = useState(false);
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCommentBox, setOpenCommentBox] = useState(false);
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = getUserAttribute(parsedUser, 'nickname');
      setNickname(nickname);
    }
    setId(userId);
  }, []);

  const getUserAttribute = (user, name) => {
    const attribute = user.UserAttributes.find(attr => attr.Name === name);
    return attribute ? attribute.Value : 'N/A';
  };


  useEffect(() => {
    if (id) {
      checkIfUserLiked();
    }
  }, [chapterLikes, id]);

  const checkIfUserLiked = () => {
    const userHasLiked = chapterLikes.some((like) => like.userId === id);
    setHasLiked(userHasLiked);
  };

  const incrementUserTotalLikeCount = async () => {
    try {
      await IncrementTotalLikeCount(bookAuthor);
    } catch (error) {
      console.error('Error liking', error);
    }
  }

  const decrementUserTotalLikeCount = async () => {
    try {
      await DecrementTotalLikeCount(bookAuthor);
    } catch (error) {
      console.error('Error disliking:', error);
    }
  }

  const handleLike = async () => {
    if (!hasLiked && id) {
      try {
        await AddLike(bookId, chapterId, id);
        setLikeCount(likeCount + 1);
        incrementUserTotalLikeCount();
        setHasLiked(true);
      } catch (error) {
        console.error('Error liking:', error);
      }
    } else {
      setError('Please login');
      setOpen(true);
    }
  };

  const handleUnLike = async () => {
    if (hasLiked && id) {
      try {
        await UnLike(bookId, chapterId, id);
        setLikeCount(likeCount - 1);
        decrementUserTotalLikeCount();
        setHasLiked(false);
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const toggleCommentBox = () => {
    if (id) {
      setOpenCommentBox((prev) => !prev);
    } else {
      setError('Please login to comment');
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="lg">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <IconButton
            onClick={hasLiked ? handleUnLike : handleLike}
            disableRipple={true}
            sx={{
              bgcolor: 'transparent',
              ':hover': {
                bgcolor: 'transparent',
                transition: 'none',
              },
            }}
          >
            <ThumbUpIcon sx={{ fontSize: '20px', color: hasLiked ? 'black' : 'inherit' }} />
            <Typography sx={{ ml: 1, color: 'black', fontSize: { xs: '0.8rem', sm: '1.1rem', md: '1.1rem', lg: '1rem' }, }}>{likeCount}</Typography>
          </IconButton>

          <IconButton
            onClick={toggleCommentBox} 
            disableRipple={true}
          >
            <CommentIcon sx={{ fontSize: '20px', color: hasLiked ? 'black' : 'inherit' }}  />
            <Typography sx={{ ml: 1, color: 'black', fontSize: { xs: '0.8rem', sm: '1.1rem', md: '1.1rem', lg: '1rem' }, }}>Comments</Typography>
          </IconButton>
        </Box>
      </Box>

      <Box sx={{   position: 'relative', minHeight: '180px' }}>
        {/* <Slide direction="down" in={openCommentBox} mountOnEnter unmountOnExit> */}
          <Box sx={{ mt: 1, mb: 2, position: 'absolute', bottom: '0', width: '100%' }}>
            <CommentBox chapterId={chapterId} bookId={bookId}/>
          </Box>
        {/* </Slide> */}
      </Box>

      {/* <Box
        my={-22}
        sx={{
          transform: openCommentBox ? 'translateY(150px)' : 'translateY()',
          transition: 'transform 0.3s ease-in-out', 
        }}
      > */}
        <Comment data={chapterComments} chapterId={chapterId} bookId={bookId}/>
      {/* </Box> */}
    </Container>
  );
}

export default LikeAndCommentSection;

import ApiInstance from "../configs/ApiInstance";

async function DeleteComment(bookId, chapterId, commentId, nickName) {
  try {
    const response = await ApiInstance.bookService.delete(`/${bookId}/chapter/${chapterId}/comment/${commentId}/${nickName}`);
     return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default DeleteComment;

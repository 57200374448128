import ApiInstance from "../configs/ApiInstance";

async function UnLikeComment(bookId, chapterId, commentId, userId) {
  try {
    const response = await ApiInstance.bookService.delete(`/${bookId}/chapter/${chapterId}/comment/${commentId}/like/${userId}`);
    return response;
  } catch (error) {
    console.error('Error unliking comment:', error);
    throw error;
  }
}

export default UnLikeComment;

import ApiInstance from "../configs/ApiInstance";

async function GetAllArticlesByAuthor(author, page, size) {
  try {
    const response = await ApiInstance.articleService.get(`/list/${author}`,
      {
        params: { size: size, page: page, sort: 'createdDate,desc' },
      }
    );
    return {
      content: response.data.content,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    // console.log(error);
    // throw error;
  }
}

export default GetAllArticlesByAuthor;

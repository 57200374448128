import React, { useEffect, useState, useRef } from 'react';
import { Alert, Box, Button, Container, Grid, IconButton, Snackbar, Link, TextField, FormHelperText, Breadcrumbs, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem, Divider, Stack } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import CreateArticle from '../services/CreateArticle';
import LoadingScreen from './LoadingScreen';
import { navigateToUrl } from "single-spa";
import UploadImages from '../services/UploadImages';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/styles.css'
import defaultCoverImage from '../images/ChapterCover.png'

import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import GetUploadedImages from '../services/GetUploadedImages';
import LexicalEditorComponent from './LexicalEditor/LexicalEditorComponent';
import imageCompression from 'browser-image-compression';

function WriteArticle() {
    const charLimit = 10000;
    const titleCharLimit = 75;
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [author, setAuthor] = useState('');
    const fileInputRef = useRef(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState('');
    const [locationUrl, setLocationUrl] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [fileError, setFileError] = useState('');
    const [imagePreview, setImagePreview] = useState(null);


    const handleReload = () => {
        return new Promise((resolve) => {
            setIsLoading(true);
            setTimeout(() => {
                navigateToUrl(`/write`);
                resolve();
            }, 2000);
        });
    };

    const handleFileChange = async (event) => {

        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            setFileError("No file selected.");
            setFile(null);
            setImagePreview(null);
            return;
        }

        const validTypes = ["image/png", "image/jpeg", "image/jfif"];
        if (!validTypes.includes(selectedFile.type)) {
            setFileError("Only PNG, JPG, and JFIF images are allowed.");
            setFile(null);
            return;
        }

        const maxSize = 1 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
            setFileError("File size must be less than 1MB.");
            setFile(null);
            return;
        }

        setFileError("");
        setFile(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));

        // try {
        //     const compressedFile = await imageCompression(selectedFile, options);
        //     const formData = new FormData();
        //     formData.append('articleImage', compressedFile);

        //     const imageUploadResponse = await UploadImages(formData);
        //     setImageKey(imageUploadResponse);
        //     const uploadedImageUrl = await GetUploadedImages(imageUploadResponse);
        //     setCoverImage(uploadedImageUrl);

        //     setFile(compressedFile);
        //     setFileName(compressedFile.name);
        //     setFileError(false)
        // } catch (error) {
        //     console.error("Image compression failed", error);
        // }
    };


    const handleButtonClick = () => {
        if (!isLoading) {
            fileInputRef.current.click();
        }
    };

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        if (newTitle.length <= titleCharLimit) {
            setTitle(newTitle);
        }
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleContentChange = (value) => {
        const newContent = event.target.value;
        if (newContent.length <= charLimit) {
            setContent(newContent);
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setSubcategory('');
    };

    const handleSubcategoryChange = (event) => {
        setSubcategory(event.target.value);
    };



    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
            setAuthor(nickname);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file || !title.trim() || !content.trim()) {
            setOpenSnackbar(true);
            return;
        }

        const formData = new FormData(event.currentTarget);
        formData.append('articleImage', file);
        setIsLoading(true);

        try {
            const dateOnly = new Date().toLocaleDateString('en-CA');
            const imageUploadResponse = await UploadImages(formData);
            await CreateArticle(title, category, content, author, dateOnly, imageUploadResponse, locationUrl, subcategory);
            handleReload();
        } catch (error) {
            console.error('Failed to create article', error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleTabKey = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            const newContent = content.substring(0, cursorPosition) + '    ' + content.substring(cursorPosition);
            setContent(newContent);
            setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = cursorPosition + 4;
            }, 0);
        }
    };

    const tagOptions = [
        "Entertainment",
        "Sports",
        "Fashion",
        "Travel",
        "Food",
        "Technology",
        "Health",
        "Lifestyle",
        "Business",
        "Education",
        "Environment",
        "Culture",
        "History",
        "Pets",
        "Books",
        "Gaming"
    ];


    const subcategoryOptions = {
        ENTERTAINMENT: [
            "Movies",
            "TV Shows",
            "Music",
            "Celebrity News",
            "Theater",
            "Concerts",
            "Reality TV",
            "Awards",
            "Streaming Services",
            "Gossip",
            "Red Carpet Events",
            "Film Festivals",
            "Documentaries",
            "Comedy",
            "Animation",
            "Podcasts",
            "Music Videos",
            "Fashion in Entertainment",
            "Video Games in Pop Culture",
            "Celebrity Interviews"
        ],
        SPORTS: [
            "Football",
            "Basketball",
            "Cricket",
            "Tennis",
            "Golf",
            "Baseball",
            "Rugby",
            "Boxing",
            "MMA",
            "Swimming",
            "Athletics",
            "Cycling",
            "Winter Sports",
            "Formula 1",
            "eSports",
            "Olympics",
            "Extreme Sports",
            "Auto Racing",
            "Archery",
            "Skiing"
        ],
        FASHION: [
            "Street Style",
            "Haute Couture",
            "Fast Fashion",
            "Sustainable Fashion",
            "Accessories",
            "Men’s Fashion",
            "Women’s Fashion",
            "Footwear",
            "Jewelry",
            "Fashion Photography",
            "Fashion Design",
            "Trends",
            "Fashion Week",
            "Vintage Fashion",
            "Fashion History",
            "Ethical Fashion",
            "Plus-Size Fashion",
            "Fashion Models",
            "Fashion Bloggers",
            "Runway Shows"
        ],
        TRAVEL: [
            "Adventure Travel",
            "Luxury Travel",
            "Solo Travel",
            "Family Vacations",
            "Budget Travel",
            "Cultural Experiences",
            "Eco-Tourism",
            "Road Trips",
            "Beach Destinations",
            "Mountain Adventures",
            "Food & Travel",
            "Cruises",
            "Travel Guides",
            "Camping",
            "Hiking",
            "City Breaks",
            "National Parks",
            "Travel Photography",
            "Wellness Retreats",
            "Volunteer Travel"
        ],
        FOOD: [
            "Recipes",
            "Healthy Eating",
            "Street Food",
            "Vegetarian",
            "Vegan",
            "Desserts",
            "Beverages",
            "Food Photography",
            "Cooking Tips",
            "International Cuisine",
            "Food Reviews",
            "Food Trends",
            "Fast Food",
            "Organic Food",
            "Meal Prep",
            "Catering",
            "Gourmet Cooking",
            "BBQ",
            "Food Pairing",
            "Food Festivals"
        ],
        TECHNOLOGY: [
            "Artificial Intelligence",
            "Blockchain",
            "Virtual Reality",
            "Augmented Reality",
            "Internet of Things",
            "Cybersecurity",
            "Gadgets",
            "Smartphones",
            "Wearables",
            "5G",
            "Drones",
            "Robotics",
            "Cloud Computing",
            "Machine Learning",
            "Gaming Technology",
            "Tech Startups",
            "Space Exploration",
            "Quantum Computing",
            "Self-Driving Cars",
            "Tech Reviews"
        ],
        HEALTH: [
            "Mental Health",
            "Fitness",
            "Nutrition",
            "Healthy Living",
            "Yoga",
            "Medications",
            "Chronic Illness",
            "Disease Prevention",
            "Medical Research",
            "Sleep Disorders",
            "Weight Loss",
            "Alternative Medicine",
            "Healthcare Technology",
            "Women's Health",
            "Men's Health",
            "Public Health",
            "Healthcare Policy",
            "Addiction Recovery",
            "Exercise Routines",
            "Holistic Health"
        ],
        LIFESTYLE: [
            "Minimalism",
            "Work-Life Balance",
            "Mindfulness",
            "Personal Growth",
            "Hobbies",
            "Home Decor",
            "Parenting",
            "Productivity",
            "Relationships",
            "Pets",
            "Self-Care",
            "Travel Lifestyle",
            "Sustainable Living",
            "Green Living",
            "Fashion Lifestyle",
            "Home Improvement",
            "Fitness Lifestyle",
            "Cooking at Home",
            "Digital Detox",
            "Social Media Influence"
        ],
        BUSINESS: [
            "Entrepreneurship",
            "Startups",
            "Marketing",
            "Finance",
            "Investing",
            "Leadership",
            "Sales",
            "E-commerce",
            "Networking",
            "Human Resources",
            "Small Business",
            "Business Strategy",
            "Mergers & Acquisitions",
            "Corporate Social Responsibility",
            "Branding",
            "Franchising",
            "Online Businesses",
            "Remote Work",
            "Venture Capital",
            "Business Analytics"
        ],
        EDUCATION: [
            "Online Learning",
            "STEM Education",
            "Language Learning",
            "Higher Education",
            "School Systems",
            "Teaching Methods",
            "Student Life",
            "Scholarships",
            "Study Abroad",
            "Learning Technologies",
            "Curriculum Development",
            "Special Education",
            "Adult Education",
            "Tutoring",
            "Educational Psychology",
            "Classroom Management",
            "Teacher Training",
            "Education Policy",
            "Homeschooling",
            "E-learning"
        ],
        ENVIRONMENT: [
            "Climate Change",
            "Renewable Energy",
            "Waste Management",
            "Conservation",
            "Sustainability",
            "Recycling",
            "Pollution",
            "Wildlife Protection",
            "Environmental Activism",
            "Ecosystems",
            "Green Technologies",
            "Water Conservation",
            "Global Warming",
            "Environmental Policy",
            "Eco-Friendly Products",
            "Nature Reserves",
            "Deforestation",
            "Carbon Footprint",
            "Sustainable Agriculture",
            "Environmental Education"
        ],
        CULTURE: [
            "Global Traditions",
            "Art & Heritage",
            "Cultural Festivals",
            "Language & Identity",
            "Cultural Diplomacy",
            "World Religions",
            "Cultural Practices",
            "Architecture",
            "Music & Dance",
            "Cultural History",
            "Indigenous Cultures",
            "Cultural Anthropology",
            "Cuisine & Culture",
            "Folklore",
            "Art Exhibitions",
            "Cultural Exchange",
            "Fashion & Culture",
            "Literature & Culture",
            "Cultural Preservation",
            "Cultural Diversity"
        ],
        HISTORY: [
            "Ancient Civilizations",
            "World Wars",
            "Historical Figures",
            "Cultural Revolutions",
            "Archaeology",
            "Medieval History",
            "Modern History",
            "Colonialism",
            "Historical Events",
            "History of Technology",
            "Historical Artifacts",
            "Social Movements",
            "Historical Documents",
            "Military History",
            "Economic History",
            "Political History",
            "Geopolitics",
            "Historical Fiction",
            "History of Science",
            "Historical Geography"
        ],
        PETS: [
            "Dogs",
            "Cats",
            "Birds",
            "Exotic Pets",
            "Small Mammals",
            "Aquatic Pets",
            "Pet Care",
            "Pet Training",
            "Pet Health",
            "Pet Adoption",
            "Pet Nutrition",
            "Pet Behavior",
            "Pet Insurance",
            "Pet Products",
            "Animal Shelters",
            "Pet Grooming",
            "Pet Photography",
            "Pets & Mental Health",
            "Veterinary Care",
            "Pet Travel"
        ],
        BOOKS: [
            "Fiction",
            "Non-fiction",
            "Biography",
            "Self-Help",
            "Fantasy",
            "Historical Fiction",
            "Science Fiction",
            "Romance",
            "Mystery & Thriller",
            "Young Adult",
            "Children’s Books",
            "Poetry",
            "Graphic Novels",
            "Literature",
            "Science & Technology",
            "Health & Wellness",
            "Philosophy",
            "Cookbooks",
            "Travel Books",
            "Art & Design"
        ],
        GAMING: [
            "Action Games",
            "RPG",
            "Strategy Games",
            "First-Person Shooters",
            "Sports Games",
            "Simulation Games",
            "Adventure Games",
            "Multiplayer Online Games",
            "Indie Games",
            "Mobile Games",
            "Esports",
            "Game Development",
            "Game Streaming",
            "Virtual Reality Games",
            "Augmented Reality Games",
            "Board Games",
            "Card Games",
            "Game Reviews",
            "Retro Gaming",
            "Gaming Consoles"
        ]
    }

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (

        <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>

            <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write`}
                        >
                            <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Contributor
                        </Link>
                        <Typography
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                        >
                            Write Article
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Container>


            <Typography variant="h2" sx={{
                mb: 2,
                mt: 2,
                fontFamily: "serif",
                fontSize: { xs: 'h4.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' }
            }}>
                Write an Article
            </Typography>


            <Box width="100%" maxWidth="1000px" p={1} sx={{ mb: 10, position: 'relative' }}>
                <Box component="form" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Category</Typography>
                            <FormControl fullWidth required>
                                <Select
                                    labelId="category-label"
                                    id="category"
                                    name="category"
                                    autoComplete="category"
                                    onChange={handleCategoryChange}
                                    className="gradient-select"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 5 + 8,
                                                width: 250,
                                            },
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    {tagOptions.map((tag, index) => (
                                        <MenuItem key={index} value={tag.toUpperCase()}>
                                            {tag.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {category && subcategoryOptions[category] && (
                            <Grid item xs={12}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Subcategory</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        labelId="subcategory-label"
                                        id="subcategory"
                                        name="subcategory"
                                        autoComplete="subcategory"
                                        value={subcategory}
                                        onChange={handleSubcategoryChange}
                                        className="gradient-select"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 5 + 8,
                                                    width: 250,
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        {subcategoryOptions[category].map((sub, index) => (
                                            <MenuItem key={index} value={sub.toUpperCase()}>
                                                {sub.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {category === 'TRAVEL' && (
                            <Grid item xs={12}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Location URL (optional)</Typography>
                                <TextField
                                    className="gradient-textfield"
                                    fullWidth
                                    variant='outlined'
                                    value={locationUrl}
                                    onChange={(e) => setLocationUrl(e.target.value)}
                                    placeholder="Enter location URL"
                                    autoComplete="location-url"
                                    sx={{ mb: 2 }}
                                    disabled={isLoading}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Title</Typography>
                            <TextField
                                className="gradient-textfield"
                                required
                                fullWidth
                                variant='outlined'
                                multiline
                                rows={2}
                                value={title}
                                onChange={handleTitleChange}
                                autoComplete="title"
                                name="title"
                                id="title"
                                sx={{ mb: 2 }}
                                InputProps={{
                                    sx: {
                                        fontSize: '1.1rem',
                                    },
                                }}
                                disabled={isLoading}
                            />
                            <Typography variant='caption' display='block' gutterBottom>
                                {title.length}/{titleCharLimit} characters
                            </Typography>
                        </Grid>



                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Content</Typography>
                                <LexicalEditorComponent setContent={setContent} />
                                {/* <TextField
                                    className="gradient-textfield"
                                    required
                                    fullWidth
                                    variant='outlined'
                                    multiline
                                    rows={35}
                                    value={content}
                                    onChange={handleContentChange}
                                    onKeyDown={handleTabKey}
                                    autoComplete="content"
                                    name="content"
                                    id="content"
                                    autoFocus
                                    sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
                                    disabled={isLoading}
                                />
                                <Typography variant='caption' display='block' sx={{ mt: 2 }}>
                                    {stripHtmlTags(content).length}/{charLimit} characters
                                </Typography> */}
                                {/* <Typography variant='caption' display='block' sx={{ mt: 2 }}>
                                    {stripHtmlTags(content).length}/{charLimit} characters
                                </Typography>  */}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{
                                border: '1px solid #e0e0e0',
                                borderRadius: "15px",
                                backgroundColor: '#e0e0e0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '300px',
                                width: '100%',
                                backgroundImage: `url(${imagePreview || defaultCoverImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                mb: 3,
                                '@media (max-width: 600px)': { height: '200px' }
                            }} />
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 2, flexShrink: 0, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>
                                        Upload Chapter Image
                                    </Typography>
                                    <IconButton
                                        onClick={handleButtonClick}
                                        sx={{
                                            background: 'black',
                                            "&:hover": {
                                                background: 'black',
                                            },
                                            color: 'white',
                                            borderRadius: '25px',
                                            width: '40px',
                                            height: '40px',
                                            justifyContent: 'center',
                                            mx: 1,
                                            mt: 1
                                        }}
                                        disabled={isLoading}
                                    >
                                        <ImageIcon />
                                        <input
                                            type="file"
                                            id="profileImage"
                                            accept="image/png, image/jpeg"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                    </IconButton>
                                </Box>
                                    {fileError && <FormHelperText sx={{ color: 'red' }}>{fileError}</FormHelperText>}
                                <Typography variant='caption' display='block' gutterBottom sx={{ mt: 1, mb: 10 }}>
                                    Instructions: Click the upload button to select an image file. Ensure the file is in JPG, PNG format and does not exceed 1MB.<br />
                                    Image minimum resolution should be 2048 x 1152 pixels.
                                </Typography>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                fontWeight: 550,
                                letterSpacing: 2,
                                background: 'black',
                                "&:hover": {
                                    background: 'black',
                                },
                                mb: 2
                            }}
                            disabled={isLoading}
                        >
                            Post
                        </Button>
                        {isLoading && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color="inherit" />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="error">
                        Please fill in the title, content and upload image before submitting.
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default WriteArticle;

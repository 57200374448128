import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';

import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ListNode, ListItemNode } from "@lexical/list";

import ToolbarPlugin from './plugins/ToolbarPlugin';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HeadingNode } from '@lexical/rich-text';
import { Box } from '@mui/material';
import { css } from '@emotion/css';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { $generateHtmlFromNodes, $parseEditorState } from '@lexical/html';

// Lexical editor config
const editorConfig = {
  namespace: 'Write',
  theme: {
    text: {
      bold: css({ fontWeight: "bold" }),
      italic: css({ fontStyle: "italic" }),
      underline: css({ textDecoration: "underline" }),
      strikethrough: css({ textDecoration: "line-through" }),
      "bold italic": css({ fontWeight: "bold", fontStyle: "italic" }),
      "bold underline": css({
        fontWeight: "bold",
        textDecoration: "underline",
      }),
      "bold italic underline": css({
        fontWeight: "bold",
        fontStyle: "italic",
        textDecoration: "underline",
      }),
      underlineStrikethrough: css({
        textDecoration: "underline line-through",
      }),
      code: css({
        color: "black",
        padding: "2px",
        background: "#eee",
        border: "1px solid #ccc",
        fontFamily: "monospace",
      }),
    }},
  nodes: [HeadingNode, CodeHighlightNode, CodeNode, ListNode, ListItemNode],
  onError(error) {
    throw error;
  },
};

const StyledContentEditable = styled(ContentEditable)`
  height: 700px;
  font-size: 18px;
  padding: 12px;
  outline: none;
  overflow: auto;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  position: relative;
  background-color: #fff;
  z-index: 1;
`;

const ToolbarContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 8px;
`;

export default function LexicalEditorComponent({ setContent  }) {

  const handleEditorChange = (editorState, editor) => {
    editor.update(() => {
      // const contentJson = JSON.stringify(editorState.toJSON());
      const htmlString = $generateHtmlFromNodes(editor);
      setContent(htmlString); 
    });
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <ToolbarContainer>
          <ToolbarPlugin />
        </ToolbarContainer>
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <RichTextPlugin
            contentEditable={<StyledContentEditable />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={(editorState, editor) => handleEditorChange(editorState, editor)} />
          <HistoryPlugin />
          <ListPlugin />
        </Box>
      </Box>
    </LexicalComposer>
  );
}

import React, { useEffect, useState } from 'react';
import { Grid, Container, CssBaseline, Breadcrumbs, Link, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';

import Pagination from './components/Pagination'
import ArticleGrid from './components/ArticleGrid'
import GetAllArticles from './services/GetAllArticles'

import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import LoadingScreen from './components/LoadingScreen';

function Screen() {
  
  const { category: category } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryName, setCategoryName] = useState('')

  const [isLoading, setIsLoading] = useState(true);

  const [articles, setArticles] = useState([]);

  const itemsPerPage = 8;

  useEffect(() => {
    const categoryName = category.toUpperCase()
    setCategoryName(categoryName)
  }, []);

  useEffect(() => {
    window.scrollTo(0,0)
  },[location])

  const handleReload = () => {
    return new Promise((resolve) => {
      setIsLoading(true);
      setTimeout(() => {
        resolve();
      },1000)
    })
  }

  useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true)
      try {
        const { content, totalPages } = await GetAllArticles(currentPage - 1, itemsPerPage, categoryName);
        setArticles(content);
        setTotalPages(totalPages);
        handleReload();
      } catch (error) {
        // console.error("Error fetching articles:", error);
      } finally {
        setIsLoading(false)
      }
    };

    if (categoryName) {
      fetchArticles();

    }
  }, [categoryName, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if(isLoading) {
    return <LoadingScreen/>
  }

  return (
    <Container maxWidth="lg" sx={{mb: 10}}>

      <Breadcrumbs aria-label="breadcrumb" mt={5}>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
          color="inherit"
          href={`/explorearticles`}
        >
          <ArticleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Explore
        </Link>
        <Typography
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
          color="inherit"
        >
          {categoryName.toLowerCase()}
        </Typography>
      </Breadcrumbs>
      <Typography mt={2} sx={{ fontWeight: 550, fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', textAlign: 'center' } }}>Explore {categoryName.toLowerCase()} articles</Typography>

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <ArticleGrid articles={articles} categoryName={categoryName} />
        </Grid>
        <Grid item xs={12} >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Screen
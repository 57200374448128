import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography, Button, Card, CardMedia, CardContent, Pagination, Collapse } from '@mui/material';
import { navigateToUrl } from 'single-spa';

import Filter from './Filter';
import GetAllArticles from '../services/GetAllArticles';
import GetArticleImage from '../services/GetArticleImage';
import ArticleGrid from './ArticleGrid';
import LoadingScreen from './LoadingScreen';

function ExploreArticles() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allArticles, setAllArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [articleImages, setArticleImages] = useState({});
  const [showCategories, setShowCategories] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const itemsPerPage = 8;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

    const handleReload = () => {
      return new Promise((resolve) => {
        setIsLoading(true);
        setTimeout(() => {
          resolve();
        }, 500);
      });
    };
  

  useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true);
      try {
        const { content, totalPages } = await GetAllArticles(currentPage - 1, itemsPerPage);
        const sortedArticles = content.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setAllArticles(sortedArticles);
        setTotalPages(totalPages);
        await handleReload();
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchArticles();
  }, [currentPage]);

  // Filter articles based on search query
  useEffect(() => {
    const filterArticles = () => {
      const articlesToFilter = searchQuery.trim() === "" ? allArticles : allArticles.filter(article =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredArticles(articlesToFilter);
    };
    filterArticles();
  }, [allArticles, searchQuery]);

  // Fetch images for articles
  useEffect(() => {
    const fetchImages = async () => {
      const articlesToFetch = searchQuery.trim() === "" ? allArticles : filteredArticles;
      if (articlesToFetch.length === 0) return;

      try {
        const images = await Promise.all(
          articlesToFetch.map(async (article) => {
            const imageUrl = await GetArticleImage(article.articleImageKey);
            return { id: article.id, imageUrl };
          })
        );

        const imagesMap = images.reduce((acc, { id, imageUrl }) => {
          acc[id] = imageUrl;
          return acc;
        }, {});

        setArticleImages(imagesMap);
      } catch (error) {
        console.error("Error fetching article images:", error);
      }
    };

    fetchImages();
  }, [allArticles, filteredArticles, searchQuery]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const articleCategories = [
    "BOOKS",
    "BUSINESS",
    "CULTURE",
    "EDUCATION",
    "ENTERTAINMENT",
    "ENVIRONMENT",
    "FASHION",
    "FOOD",
    "GAMING",
    "HEALTH",
    "HISTORY",
    "LIFESTYLE",
    "PETS",
    "SPORT",
    "TECHNOLOGY",
    "TRAVEL",
  ];

  const articlesToDisplay = searchQuery.trim() === "" ? allArticles : filteredArticles;

  const articleCategoryDetails = {
    'ENTERTAINMENT': {
      mainTitle: "Stay Updated with the Latest in Entertainment",
      description: "Catch up on the hottest trends in movies, music, and celebrity news.",
    },
    'SPORT': {
      mainTitle: "All About the Game",
      description: "Get the latest sports news, scores, and highlights from around the world.",
    },
    'FASHION': {
      mainTitle: "Discover the Latest Fashion Trends",
      description: "Explore the world of fashion, from runway shows to street style.",
    },
    'TRAVEL': {
      mainTitle: "Wanderlust Awaits",
      description: "Get inspired for your next adventure with travel tips and destination guides.",
    },
    'FOOD': {
      mainTitle: "Savor Delicious Delights",
      description: "Delve into culinary wonders with recipes, restaurant reviews, and food culture.",
    },
    'TECHNOLOGY': {
      mainTitle: "The Future is Now",
      description: "Stay ahead with the latest tech news, gadgets, and innovations.",
    },
    'HEALTH': {
      mainTitle: "Be Your Healthiest Self",
      description: "Find tips on maintaining a healthy lifestyle, fitness, and well-being.",
    },
    'LIFESTYLE': {
      mainTitle: "Living Your Best Life",
      description: "Explore topics on personal growth, hobbies, and improving your everyday life.",
    },
    'BUSINESS': {
      mainTitle: "Empower Your Career",
      description: "Stay informed on the latest business news, career tips, and market trends.",
    },
    'EDUCATION': {
      mainTitle: "Learning for a Better Future",
      description: "Access the latest in education news, resources, and study tips.",
    },
    'ENVIRONMENT': {
      mainTitle: "Protecting Our Planet",
      description: "Stay informed about environmental issues and sustainability practices.",
    },
    'CULTURE': {
      mainTitle: "Explore the World of Culture",
      description: "Dive into cultural stories, art, music, and traditions from around the world.",
    },
    'HISTORY': {
      mainTitle: "Learn from the Past",
      description: "Read articles that dive deep into historical events and figures.",
    },
    'PETS': {
      mainTitle: "All About Pets",
      description: "Get tips on pet care, training, and the latest trends in the pet world.",
    },
    'BOOKS': {
      mainTitle: "For Book Lovers",
      description: "Explore book reviews, recommendations, and literary news.",
    },
    'GAMING': {
      mainTitle: "Level Up Your Gaming Experience",
      description: "Stay updated with the latest in gaming news, reviews, and releases.",
    },
  };

  const getRandomArticleCategory = () => {
    const categories = Object.keys(articleCategoryDetails);
    const randomCategory = categories[Math.floor(Math.random() * categories.length)];
    return randomCategory;
  };

  const randomArticleCategories = Array.from({ length: 2 }, () => getRandomArticleCategory());

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{ mb: 10 }}>
      <Typography mt={2} sx={{ fontWeight: 550, fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', textAlign: 'center' } }}>Explore Articles</Typography>

      <Filter onSearch={handleSearch} />

           <Box display="flex" justifyContent="left" alignItems="center" mb={3}>
            <Button
              variant="contained"
              onClick={() => setShowCategories((prev) => !prev)}
              sx={{
                mx: 2,
                borderRadius: 5,
                textTransform: 'none',
                bgcolor: 'black',
                color: 'white',
                borderColor: 'black',
                '&:hover': {
                  bgcolor: 'black',
                  borderColor: 'black',
                },
                '&:active': {
                  bgcolor: 'black',
                  borderColor: 'black',
                }
              }}
            >
              {showCategories ? "Hide Categories" : "Explore Categories"}
            </Button>
          </Box>
 

      <Collapse in={showCategories} >
        <Grid container spacing={2} justifyContent="center" alignItems="center" mb={7}>
          {articleCategories.map((category, index) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="outlined"
                sx={{
                  color: 'black',
                  width: '80%',
                  borderRadius: 5,
                  borderColor: 'black',
                  textTransform: 'none',
                }}
                onClick={() => navigateToUrl(`/articles/${category.toLowerCase()}`)}
              >
                {category.toLowerCase()}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Collapse>

      <Grid container spacing={3} >
        {articlesToDisplay.length > 0 ? (
          articlesToDisplay.map((article) => (
            <Grid item xs={6} sm={6} md={3} key={article.id}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  borderRadius: 2,
                  height: '150px',
                  width: "100%",
                  position: "relative",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.01)",
                    zIndex: 100,
                  },
                }}
                onClick={() => navigateToUrl(`/articles/${article.category}/${article.id}`)}
              >
                <CardMedia
                  component="img"
                  src={articleImages[article.id] || ""}
                  alt={article.title}
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                 
                />

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                    zIndex: 1,
                    pointerEvents: "none",
                  }}
                />

                <CardContent
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 2,
                    color: "white",
                    p: 2,
                  }}
                >
                  <Typography sx={{ color: "white", fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.9rem' }, userSelect: 'none'  }}>
                    {article.title || "No Title"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{
              width: '100%',
              textAlign: 'center',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1rem' }
            }}
          >
            No articles found matching your search.
          </Typography>
        )}
      </Grid>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="circular"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
          mb: 5,
          "& .MuiPaginationItem-root": {
            color: "black",
          },
          "& .MuiPaginationItem-outlined": {
            borderColor: "transparent",
          },
          "& .Mui-selected": {
            background: 'black !important',
            color: "white",
            borderColor: "transparent",
          },
        }}
      />

      <Grid item xs={12} sm={12} mb={12}>
        {randomArticleCategories.map((category, index) => (
          <ArticleGrid
            key={index}
            category={category}
            mainTitle={articleCategoryDetails[category]?.mainTitle}
            description={articleCategoryDetails[category]?.description}
          />
        ))}
      </Grid>



    </Container>
  );
}

export default ExploreArticles;

import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Box, CssBaseline } from '@mui/material';
import MainBanner from './components/MainBanner'

import GetUserDetails from './services/GetUserData';
import GetAdminData from './services/GetAdminData';
import GetAdminImage from './services/GetAdminImage';
import BookGridList from './components/BookGridList';
import SmallBanner from './components/SmallBanner';
import ArticleGrid from './components/ArticleGrid';

function Screen() {
  const [nickname, setNickname] = useState();
  const [userData, setUserData] = useState(null);
  const [favourites, setFavourites] = useState([]);

  const categoriesRef = useRef(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = getUserAttribute(parsedUser, 'nickname');
      fetchUserDetails(nickname);
    }
  }, []);

  const fetchUserDetails = async (nickname) => {
    try {
      const userDetails = await GetUserDetails(nickname);
      localStorage.setItem('role', userDetails.role);
      setUserData(userDetails);

      const transformedFavourites = userDetails.favourites.map(favourite =>
        favourite.replace(/_/g, ' ').toLowerCase()
      );

      setFavourites(transformedFavourites);
      setNickname(userDetails.nickname);
    
    }
    catch (error) {
      return null;
      // console.error("Error occurred when getting user data", error);
    } 
  };

  const getUserAttribute = (user, name) => {
    const attribute = user.UserAttributes.find(attr => attr.Name === name);
    return attribute ? attribute.Value : 'N/A';
  };

  const articleCategoryDetails = {
    'ENTERTAINMENT': {
      mainTitle: "Stay Updated with the Latest in Entertainment",
      description: "Catch up on the hottest trends in movies, music, and celebrity news.",
    },
    'SPORT': {
      mainTitle: "All About the Game",
      description: "Get the latest sports news, scores, and highlights from around the world.",
    },
    'FASHION': {
      mainTitle: "Discover the Latest Fashion Trends",
      description: "Explore the world of fashion, from runway shows to street style.",
    },
    'TRAVEL': {
      mainTitle: "Wanderlust Awaits",
      description: "Get inspired for your next adventure with travel tips and destination guides.",
    },
    'FOOD': {
      mainTitle: "Savor Delicious Delights",
      description: "Delve into culinary wonders with recipes, restaurant reviews, and food culture.",
    },
    'TECHNOLOGY': {
      mainTitle: "The Future is Now",
      description: "Stay ahead with the latest tech news, gadgets, and innovations.",
    },
    'HEALTH': {
      mainTitle: "Be Your Healthiest Self",
      description: "Find tips on maintaining a healthy lifestyle, fitness, and well-being.",
    },
    'LIFESTYLE': {
      mainTitle: "Living Your Best Life",
      description: "Explore topics on personal growth, hobbies, and improving your everyday life.",
    },
    'BUSINESS': {
      mainTitle: "Empower Your Career",
      description: "Stay informed on the latest business news, career tips, and market trends.",
    },
    'EDUCATION': {
      mainTitle: "Learning for a Better Future",
      description: "Access the latest in education news, resources, and study tips.",
    },
    'ENVIRONMENT': {
      mainTitle: "Protecting Our Planet",
      description: "Stay informed about environmental issues and sustainability practices.",
    },
    'CULTURE': {
      mainTitle: "Explore the World of Culture",
      description: "Dive into cultural stories, art, music, and traditions from around the world.",
    },
    'HISTORY': {
      mainTitle: "Learn from the Past",
      description: "Read articles that dive deep into historical events and figures.",
    },
    'PETS': {
      mainTitle: "All About Pets",
      description: "Get tips on pet care, training, and the latest trends in the pet world.",
    },
    'BOOKS': {
      mainTitle: "For Book Lovers",
      description: "Explore book reviews, recommendations, and literary news.",
    },
    'GAMING': {
      mainTitle: "Level Up Your Gaming Experience",
      description: "Stay updated with the latest in gaming news, reviews, and releases.",
    },
  };

  const getRandomArticleCategory = () => {
    const categories = Object.keys(articleCategoryDetails);
    const randomCategory = categories[Math.floor(Math.random() * categories.length)];
    return randomCategory;
  };

  const randomArticleCategories = Array.from({ length: 2 }, () => getRandomArticleCategory());


  const categoryDetails = {
    "fantasy": {
      mainTitle: "Embark on Magical Journeys",
      description: "Discover spellbinding tales that transport you to otherworldly realms.",
    },
    "science fiction": {
      mainTitle: "Explore the Universe of Possibilities",
      description: "Dive into futuristic worlds and cutting-edge adventures.",
    },
    "mystery": {
      mainTitle: "Solve Intriguing Mysteries",
      description: "Unravel gripping tales of suspense and hidden secrets.",
    },
    "thriller": {
      mainTitle: "Feel the Adrenaline Rush",
      description: "Experience heart-pounding stories that keep you on edge.",
    },
    "romance": {
      mainTitle: "Fall in Love with Love",
      description: "Explore heartwarming tales of passion and romance.",
    },
    "historical fiction": {
      mainTitle: "Travel Through Time",
      description: "Relive the past with captivating historical narratives.",
    },
    "horror": {
      mainTitle: "Face Your Deepest Fears",
      description: "Dare to read spine-chilling tales of terror and dread.",
    },
    "adventure": {
      mainTitle: "Embark on Epic Adventures",
      description: "Set out on journeys filled with excitement and daring quests.",
    },
    "action": {
      mainTitle: "Get Your Heart Racing",
      description: "Dive into explosive and action-packed stories.",
    },
    "teen fiction": {
      mainTitle: "Relive Teenage Dreams",
      description: "Engage with stories that capture the essence of youth.",
    },
    "drama": {
      mainTitle: "Experience the Depths of Emotion",
      description: "Delve into stories rich with drama and intense moments.",
    },
    "true crime": {
      mainTitle: "Explore Real-Life Mysteries",
      description: "Uncover true stories of crime and intrigue.",
    },
    "history": {
      mainTitle: "Learn from the Past",
      description: "Engage with narratives that bring history to life.",
    },
    "paranormal": {
      mainTitle: "Step into the Supernatural",
      description: "Discover thrilling tales of ghosts and otherworldly beings.",
    },
    "werewolf": {
      mainTitle: "Howl with the Pack",
      description: "Immerse yourself in stories of werewolves and full-moon mysteries.",
    },
    "vampire": {
      mainTitle: "Sink Your Teeth Into Adventure",
      description: "Explore dark and alluring tales of vampires.",
    },
    "fan fiction": {
      mainTitle: "Reimagine Your Favorites",
      description: "Dive into creative spins on beloved characters and stories.",
    },
    "dark romance": {
      mainTitle: "Indulge in Forbidden Desires",
      description: "Experience the allure of dangerous and intense romances.",
    },
    "new adult": {
      mainTitle: "Navigate Young Adulthood",
      description: "Explore relatable stories of growth and self-discovery.",
    },
    "lgbtq+": {
      mainTitle: "Celebrate Love and Diversity",
      description: "Engage with stories that embrace all identities and relationships.",
    },
    "mythology": {
      mainTitle: "Uncover Ancient Legends",
      description: "Discover tales of gods, heroes, and epic myths.",
    },
    'humor': {
      mainTitle: "Laugh Out Loud",
      description: "Enjoy stories that bring joy and laughter.",
    },
    "poetry": {
      mainTitle: "Feel the Rhythm of Words",
      description: "Experience the beauty of language through poetry.",
    },
    "short stories": {
      mainTitle: "Bite-Sized Tales",
      description: "Enjoy quick reads packed with depth and meaning.",
    },
    "general fiction": {
      mainTitle: "Discover Timeless Stories",
      description: "Explore narratives that resonate with all readers.",
    },
    "non-fiction": {
      mainTitle: "Learn Something New",
      description: "Engage with real-life stories and informative reads.",
    },
    "random": {
      mainTitle: "Expect the Unexpected",
      description: "Dive into a collection of surprising and eclectic tales.",
    },
    "spiritual": {
      mainTitle: "Seek Inner Peace",
      description: "Discover stories and teachings to uplift your spirit.",
    },
  };

  const getRandomCategory = () => {
    const categories = Object.keys(categoryDetails);
    const randomCategory = categories[Math.floor(Math.random() * categories.length)];
    return randomCategory;
  };

  const randomCategories = Array.from({ length: 4 }, () => getRandomCategory());
  const randomCategory = getRandomCategory();


  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <MainBanner />

      {userData ? (
        favourites.map((favourite, index) => (
          <BookGridList
            key={index}
            category={favourite.replace(/[\s%20]+/g, '_').toUpperCase()}
            mainTitle={categoryDetails[favourite]?.mainTitle}
            description={categoryDetails[favourite]?.description}
          />
        ))
      ) : (
        randomCategories.map((category, index) => (
          <BookGridList
            key={index}
            category={category}
            mainTitle={categoryDetails[category]?.mainTitle}
            description={categoryDetails[category]?.description}
          />
        ))
      )}


      <Box
        ref={categoriesRef}
        sx={{
          background: 'white',
          width: '100%'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <SmallBanner />
          </Grid>
          <Grid item xs={12} sm={5}>
            <BookGridList
              category={randomCategory}
              mainTitle={categoryDetails[randomCategory]?.mainTitle}
              description={categoryDetails[randomCategory]?.description}
              displayNumber={3}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            {randomArticleCategories.map((category, index) => (
              <ArticleGrid
                key={index}
                category={category}
                mainTitle={articleCategoryDetails[category]?.mainTitle}
                description={articleCategoryDetails[category]?.description}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box mb={7} />
    </Container>
  );
}

export default Screen;

import ApiInstance from "../configs/ApiInstance";

const GetBookById = async (id) => {
  try {
    const response = await ApiInstance.bookService.get(`/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch Book data:', error);
    throw error;
  }
};

export default GetBookById;

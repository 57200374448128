import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Content from './components/Content';
import Book from './components/Book';
import Screen from './Screen';
import AdPage from './components/AdPage';


function App() {
  return (
    <Router>
        <Routes>
            <Route path='/book/categories/:category' element={<Screen/>} />
            <Route path='/book/:bookId' element={<Book/>} />
            <Route path='/book/:bookId/chapter/:chapterId' element={<Content/>} />
        </Routes>
    </Router>
  )
}

export default App
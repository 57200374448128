import React, { useEffect, useState, useRef } from 'react';
import { Alert, Box, Button, Container, Grid, Stack, FormHelperText, Breadcrumbs, IconButton, Snackbar, TextField, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem, Divider, OutlinedInput, Chip, Link } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import CreateBook from '../services/CreateBook';
import { useNavigate } from 'react-router-dom';
import UploadImages from '../services/UploadImages';
import ReactQuill from 'react-quill';
import defaultCoverImage from '../images/BookCover.png'
import GetUploadedImages from '../services/GetUploadedImages';
import 'react-quill/dist/quill.snow.css';
import '../styles/styles.css'

import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import LexicalEditorComponent from './LexicalEditor/LexicalEditorComponent';
import LoadingScreen from './LoadingScreen';
import imageCompression from 'browser-image-compression';

function StartBook() {

    const navigate = useNavigate();

    const charLimit = 2500;
    const titleCharLimit = 60;

    const fileInputRef = useRef(null);

    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');

    const [category, setCategory] = useState('');
    const [language, setLanguage] = useState('');

    const [selectedCopyright, setSelectedCopyright] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');

    const [selectedRating, setSelectedRating] = useState('');
    const [ratingDescription, setRatingDescription] = useState('');

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [fileError, setFileError] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [file, setFile] = useState(null);


    const handleReload = (bookId) => {
        return new Promise((resolve) => {
            setIsLoading(true);
            setTimeout(() => {
                handleNext(bookId);
                resolve();
            }, 2000);
        });
    };


    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            setFileError("No file selected.");
            setFile(null);
            setImagePreview(null);
            return;
        }

        const validTypes = ["image/png", "image/jpeg", "image/jfif"];
        if (!validTypes.includes(selectedFile.type)) {
            setFile(null)
            setImagePreview(null)
            setFileError("Only PNG, JPG, and JFIF images are allowed.");
            return;
        }

        const maxSize = 1 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
            setFile(null)
            setImagePreview(null)
            setFileError("File size must be less than 1MB.");
            return;
        }

        setFileError("");
        setFile(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile)); 
    }


    const handleButtonClick = () => {
        if (!isLoading) {
            fileInputRef.current.click();
        }
    };

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        if (newTitle.length <= titleCharLimit) {
            setTitle(newTitle);
        }
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleDescriptionChange = (event) => {
        const strippedContent = event.target.value;
        if (strippedContent.length <= charLimit) {
            setDescription(strippedContent);
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleCopyrightChange = (event) => {
        const selectedOption = copyrightOptions.find(option => option.value === event.target.value);
        setSelectedCopyright(selectedOption.value);
        setSelectedDescription(selectedOption.description);
    };

    const handleRatingChange = (event) => {
        const selectedOption = ratingOptions.find(option => option.value === event.target.value);
        setSelectedRating(selectedOption.value);
        setRatingDescription(selectedOption.description);
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
            setAuthor(nickname);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append('articleImage', file);

        if (!title.trim()) {
            setSnackbarMessage('Please enter a title.');
            setOpenSnackbar(true);
            return;
        }
        if (!description.trim()) {
            setSnackbarMessage('Please enter a description.');
            setOpenSnackbar(true);
            return;
        }
        if (!category) {
            setSnackbarMessage('Please select a category.');
            setOpenSnackbar(true);
            return;
        }
        if (!tags.length) {
            setSnackbarMessage('Please add at least one tag.');
            setOpenSnackbar(true);
            return;
        }
        if (!language) {
            setSnackbarMessage('Please select a language.');
            setOpenSnackbar(true);
            return;
        }
        if (!selectedCopyright) {
            setSnackbarMessage('Please select a copyright option.');
            setOpenSnackbar(true);
            return;
        }
        if (!selectedRating) {
            setSnackbarMessage('Please select a rating.');
            setOpenSnackbar(true);
            return;
        }
        if (!file) {
            setSnackbarMessage('Please upload a book cover image.');
            setOpenSnackbar(true);
            return;
        }
        try {
            const dateOnly = new Date().toLocaleDateString('en-CA');
            const imageUploadResponse = await UploadImages(formData);
            const book = await CreateBook(title, description, category, tags, language, selectedCopyright, selectedRating, author, imageUploadResponse, dateOnly);
            const bookId = book.id;
            handleReload(bookId);
        } catch (error) {
            console.error('Failed to create article', error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const tag = inputValue.trim();
            if (tag && !tags.includes(tag)) {
                if (tags.length < 5) {
                    setTags((prevTags) => [...prevTags, tag]);
                    setInputValue('');
                }
            }
        }
    };

    const handleTagDelete = (tagToDelete) => () => {
        setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    };

    const handleTabKey = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            const newContent = description.substring(0, cursorPosition) + '    ' + description.substring(cursorPosition);
            setDescription(newContent);
            setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = cursorPosition + 4;
            }, 0);
        }
    };

    const handleNext = (bookId) => {
        navigate(`/write/mystories/book/${bookId}`);
    };

    const categoryOptions = [
        "Fantasy",
        "Science Fiction",
        "Mystery",
        "Thriller",
        "Romance",
        "Historical Fiction",
        "Horror",
        "Adventure",
        "Action",
        "Teen Fiction",
        "Drama",
        "True Crime",
        "History",
        "Paranormal",
        "Werewolf",
        "Vampire",
        "Fan Fiction",
        "Dark Romance",
        "New Adult",
        "LGBTQ+",
        "Mythology",
        "Humor",
        "Poetry",
        "Short Stories",
        "General Fiction",
        "Non-Fiction",
        "Random",
        "Spiritual",
    ];

    const languageOptions = [
        "සිංහල",
        "English",
        "தமிழ்",
    ];

    const copyrightOptions = [
        { value: "All Rights Reserved", description: "You do not allow your work to be used or adapted in any way without your permission." },
        { value: "Public Domain", description: "This allows anyone to use your story for any purpose — they could freely print, sell, or turn your story into a movie." },
        { value: "Creative Commons (CC) Attribution", description: "You hold some rights to your story but allow for things like translation or adaptations with credit back to you." },
        { value: "(CC) Attrib. NonCommercial", description: "This license lets others remix, adapt, and build upon your work non-commercially with credit back to you." },
        { value: "(CC) Attrib. NonComm. NoDerivs", description: "This license allows others to share your unaltered work with others as long as you get credit." },
        { value: "(CC) Attrib. NonComm. ShareAlike", description: "This lets others adapt, and build upon your work non-commercially, as long as you are credited and the same terms are applied." },
        { value: "(CC) Attribution-ShareAlike", description: "This lets others adapt and build on your work, even for commercial purposes, as long as you are credited and the same terms are applied." },
        { value: "(CC) Attribution-NoDerivs", description: "This lets others reuse your work for any purpose but it cannot be altered and you must be credited." },
    ];

    const ratingOptions = [
        { value: "General Audience", description: "Suitable for all ages. Content is free of any explicit language or mature themes." },
        { value: "Kids-Friendly", description: "Designed specifically for children, with safe and fun content." },
        { value: "Teens", description: "Appropriate for teenagers. May include mild language or mature themes." },
        { value: "Mature", description: "Contains content suitable for adults, including explicit language or mature themes." },
    ];

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (

        <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>

            <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write`}
                        >
                            <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Contributor
                        </Link>
                        <Typography
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                        >
                            Create a New Story
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Container>

            <Typography variant="h2" sx={{
                mb: 2,
                mt: 2,
                fontFamily: "serif",
                fontSize: { xs: 'h4.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' },
                textAlign: { xs: 'center', sm: 'left' },
            }}>
                Create a New story
            </Typography>
            <Box width="100%" maxWidth="1000px" p={1} sx={{ mb: 10, position: 'relative' }}>
                <Box component="form" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} mb={4}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Title</Typography>
                                <TextField
                                    className="gradient-textfield"
                                    required
                                    fullWidth
                                    variant='outlined'
                                    multiline
                                    rows={2}
                                    value={title}
                                    onChange={handleTitleChange}
                                    autoComplete="title"
                                    name="title"
                                    id="title"
                                    autoFocus
                                    InputProps={{
                                        sx: {
                                            fontSize: '1.1rem',
                                        },
                                    }}
                                    disabled={isLoading}
                                />
                                <Typography variant='caption' display='block' gutterBottom mt={1}>
                                    {title.length}/{titleCharLimit} characters
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <Box>
                                    <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Description</Typography>
                                    <TextField
                                        className="gradient-textfield"
                                        required
                                        fullWidth
                                        variant='outlined'
                                        multiline
                                        rows={15}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        onKeyDown={handleTabKey}
                                        autoComplete="description"
                                        name="description"
                                        id="description"
                                        autoFocus
                                        sx={{ mb: 2 }}
                                        InputProps={{
                                            sx: {
                                                fontSize: '1.1rem',
                                            },
                                        }}
                                        disabled={isLoading}
                                    />
                                    {/* <LexicalEditorComponent setContent={setDescription} /> */}
                                    <Typography variant='caption' display='block' sx={{ mt: 1 }}>
                                        {stripHtmlTags(description).length}/{charLimit} characters
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Category</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        labelId="category-label"
                                        id="category"
                                        name="category"
                                        autoComplete="category"
                                        onChange={handleCategoryChange}
                                        className="gradient-select"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 5 + 8,
                                                    width: 250,
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        {categoryOptions.map((tag, index) => (
                                            <MenuItem key={index} value={tag.replace(/\s+/g, '_').toUpperCase()}>
                                                {tag}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Typography variant='caption' display='block' gutterBottom mt={1}>
                                    Choosing the right category helps readers discover your story and ensures it reaches the right audience. It also helps us understand your story better!
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Tags</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        className="gradient-textfield"
                                        value={inputValue}
                                        required
                                        variant='outlined'
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        fullWidth
                                    />

                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {tags.map((tag) => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                onDelete={handleTagDelete(tag)}
                                                sx={{
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    "& .MuiChip-deleteIcon": {
                                                        color: "white",
                                                    },
                                                    "&:hover": {
                                                        backgroundColor: "black",
                                                        color: "white",
                                                        "& .MuiChip-deleteIcon": {
                                                            color: "white",
                                                        },
                                                    },
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                                <Typography variant='caption' display='block' gutterBottom mt={1}>
                                    (Maximum 5 tags) <br />
                                    Adding tags helps readers find your story more easily and connect with topics they love. Tags boost your story's visibility and discoverability!
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Language</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        labelId="language-label"
                                        id="language"
                                        name="language"
                                        autoComplete="language"
                                        onChange={handleLanguageChange}
                                        className="gradient-select"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 5 + 8,
                                                    width: 250,
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        {languageOptions.map((tag, index) => (
                                            <MenuItem key={index} value={tag.toUpperCase()}>
                                                {tag}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>Copyright</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        labelId="copyright-label"
                                        id="copyright"
                                        name="copyright"
                                        autoComplete="copyright"
                                        onChange={handleCopyrightChange}
                                        className="gradient-select"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 5 + 8,
                                                    width: 250,
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        {copyrightOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Typography variant='caption' display='block' gutterBottom mt={1}>
                                    {selectedDescription}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography variant='h5' sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>
                                    Rating
                                </Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        labelId="rating-label"
                                        id="rating"
                                        name="rating"
                                        autoComplete="rating"
                                        onChange={handleRatingChange}
                                        className="gradient-select"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 5 + 8,
                                                    width: 250,
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        {ratingOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {ratingDescription && (
                                    <Typography variant='caption' display='block' gutterBottom mt={1}>
                                        {ratingDescription}
                                    </Typography>
                                )}
                                <Typography variant='body2' mt={2}>
                                    For more info, please read{' '}
                                    <span
                                        onClick={() => navigate('/contentguidline')}
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            color: 'black',
                                        }}
                                    >
                                        Ceylon Diaries’ Content Guidelines
                                    </span>.
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    border: '1px solid #e0e0e0',
                                    borderRadius: "15px",
                                    backgroundColor: '#e0e0e0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: { xs: '500px', lg: '700px' },
                                    width: '100%',
                                    backgroundImage: `url(${imagePreview || defaultCoverImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    mb: 3,
                                    mt: { lg: 4 },
                                }}
                            />
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>

                                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 2, flexShrink: 0, fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.2rem' } }} fontWeight={600}>
                                        Upload Cover Image
                                    </Typography>
                                    <IconButton
                                        onClick={handleButtonClick}
                                        sx={{
                                            background: 'black',
                                            "&:hover": {
                                                background: 'black',
                                            },
                                            color: 'white',
                                            borderRadius: '25px',
                                            width: '40px',
                                            height: '40px',
                                            justifyContent: 'center',
                                            mx: 1,
                                            mt: 1
                                        }}
                                        disabled={isLoading}
                                    >
                                        <ImageIcon />
                                        <input
                                            type="file"
                                            id="profileImage"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                    </IconButton>
                                </Box>
                                {fileError && <FormHelperText sx={{ color: 'red' }}>{fileError}</FormHelperText>}

                                <Typography variant='caption' display='block' gutterBottom sx={{ mt: 1, mb: 15 }}>
                                    Instructions: Click the upload button to select an image file. Ensure the file is in JPG, PNG format and does not exceed 1MB.<br />
                                    Image resolution should be 512 x 800 pixels.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box sx={{ position: 'absolute', bottom: 1, right: 40 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    fontWeight: 550,
                                    letterSpacing: 2,
                                    background: 'black',
                                    "&:hover": {
                                        background: 'black',
                                    },
                                    mb: 5
                                }}
                                disabled={isLoading}
                            >
                                Publish
                            </Button>
                            {isLoading && (
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress color="inherit" />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Box>


                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  
                    >
                    <Alert severity="error">
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default StartBook;

import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Screen from './Screen';
import ThankYou from './components/ThankYou';
import WriteArticle from './components/WriteArticle';
import StartBook from './components/StartBook';
import WriteBookChapter from './components/WriteBookChapter';
import Preview from './components/Preview';
import MyStories from './components/MyStories';
import Book from './components/Book';
import DraftBookChapter from './components/DraftBookChapter';
import Published from './components/Published';
import ViewChapter from './components/ViewChapter';

function App() {
  return (
    <Router>
        <Routes>
            <Route path='/write/' element={<Screen/>} />
            <Route path='/write/article' element={<WriteArticle/>} />
            <Route path='/write/book' element={<StartBook/>} />

            <Route path='/write/mystories' element={<MyStories/>} />
            <Route path='/write/mystories/book/:bookId' element={<Book/>} />
            <Route path='/write/mystories/book/:bookId/published' element={<Published/>} />
            <Route path='/write/mystories/book/:bookId/published/:chapterId' element={<ViewChapter/>} />
            <Route path='/write/mystories/book/:bookId/draft/:chapterId' element={<DraftBookChapter/>} />


            <Route path='/write/book/:bookId/chapter' element={<WriteBookChapter/>} />
            
            <Route path='/write/book/:bookId/chapter/preview' element={<Preview/>} />
            <Route path="/write/article/confirm/:randomId" element={<ThankYou />} />
        </Routes>
    </Router>
  )
}

export default App
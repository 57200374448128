import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

function Standards() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 3 }}>
          Community Standards
        </Typography>

        <Typography variant="body1" paragraph>
          Welcome to Ceylon Diaries! Our goal is to build an inclusive, respectful, and supportive community for all users. By using our platform, you agree to follow these Community Standards to maintain a positive and safe environment for everyone.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Respectful Communication
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          We believe in fostering a positive, inclusive, and respectful environment. When interacting with other users, please:
          <br />
          - Treat everyone with kindness and respect, regardless of their background, opinions, or identity.
          <br />
          - Refrain from any form of harassment, hate speech, or discriminatory behavior.
          <br />
          - Avoid using offensive language, slurs, or personal attacks.
          <br />
          - Be open to constructive criticism and feedback, and engage in discussions with empathy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Content Guidelines
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          We encourage creativity and positive contributions to the platform. However, certain types of content are not allowed:
          <br />
          - Share original, authentic, and constructive content. Content should add value to the community.
          <br />
          - Do not post any illegal, explicit, or harmful material, including but not limited to pornography, violence, or offensive content.
          <br />
          - Content that spreads misinformation, conspiracy theories, or false claims is prohibited.
          <br />
          - Avoid posting material that may incite violence or hatred or that promotes harmful ideologies.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Spam and Self-Promotion
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          We strive to maintain a relevant and engaging experience for all users. To that end:
          <br />
          - Avoid spamming or posting repetitive, irrelevant, or off-topic content.
          <br />
          - Do not post excessive promotional content that is not related to the platform or our community.
          <br />
          - Self-promotion should be kept to a minimum and only in appropriate contexts (such as sharing your work in designated spaces or threads).
          <br />
          - Be mindful of the community’s purpose and contribute to meaningful discussions rather than purely for personal gain.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Privacy and Personal Information
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          Protecting your privacy and the privacy of others is essential to us. Please:
          <br />
          - Do not share personal information of others without their consent.
          <br />
          - Do not post your own private information (e.g., phone number, address, etc.) unless you are comfortable with it being public.
          <br />
          - Be cautious when interacting with strangers and avoid revealing sensitive details.
          <br />
          - If you encounter any privacy violations, please report them immediately.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Intellectual Property
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          Respecting intellectual property is crucial to maintaining a fair and legal platform. Please:
          <br />
          - Only share content that you own or have explicit permission to use.
          <br />
          - Do not post plagiarized content. Ensure that any work you share is your own or properly credited.
          <br />
          - Do not infringe on copyrights, trademarks, or any other intellectual property rights of others.
          <br />
          - If you believe that your intellectual property rights have been violated, please contact us to resolve the issue.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Reporting Violations
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          If you come across any content or behavior that violates these standards, we encourage you to report it. By helping us maintain a positive environment, you contribute to the well-being of the community.
          <br />
          - You can report violations to us at <Link href='/contact' underline='none' color='black' fontWeight='550'>support@ceylondiaries.com</Link>.
          <br />
          - Our team will investigate reports promptly and take appropriate action, which may include content removal or account suspension.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Consequences of Violations
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          We take violations of our Community Standards seriously. Depending on the severity of the violation, consequences may include:
          <br />
          - Removal of the offending content.
          <br />
          - Temporary or permanent suspension of the user's account.
          <br />
          - Legal action for severe violations (e.g., harassment, intellectual property theft).
          <br />
          - We reserve the right to take any actions necessary to protect the integrity and safety of our platform.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Building a Positive Community
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}>
          Our goal is to create a safe, friendly, and enjoyable space for all members. By following these Community Standards, you help us achieve that goal. Together, we can:
          <br />
          - Foster a respectful and inclusive environment for all users.
          <br />
          - Encourage creativity, collaboration, and meaningful connections.
          <br />
          - Make Ceylon Diaries a place where people feel comfortable sharing their stories and experiences.
        </Typography>

        <Typography variant="body1" paragraph textAlign="center" sx={{ mt: 3 }}>
          Thank you for being a valued member of Ceylon Diaries. By using our platform, you agree to abide by these Community Standards.
        </Typography>
      </Box>
    </Container>
  );
}

export default Standards;

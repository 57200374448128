import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import Filter from './Filter';
import GetUploadedImages from '../services/GetUploadedImages';

function BookGrid({ books }) {
    const [bookImage, setBookImage] = useState({});
    const [filteredBooks, setFilteredBooks] = useState(books);
    const [searchQuery, setSearchQuery] = useState("");

 
    useEffect(() => {
        const fetchImages = async () => {
            if (!Array.isArray(books)) {
                console.error('Invalid articles data:', books);
                return;
            }

            try {
                const images = await Promise.all(
                    books.map(async (book) => {
                        const imageUrl = await GetUploadedImages(book.imageKey);
                        return { [book.id]: imageUrl };
                    })
                );
                setBookImage(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
            } catch (error) {
                // console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [books]);

    useEffect(() => {
        const filtered = books.filter((book) => {
            const bookName = book.title || "";
            const matchesSearch = searchQuery === "" || bookName.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
        setFilteredBooks(filtered);
    }, [searchQuery, books]);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };



    return (
        <Container maxWidth="lg">
            <Grid
                container
                spacing={2}
                mt={2}
                sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Grid item xs={12}>
                    <Filter onSearch={handleSearch} />
                </Grid>
                {filteredBooks.length > 0 ? (
                    filteredBooks.map((book) => (
                        <Grid
                            item
                            key={book.id}
                            xs={6}
                            sm={3}
                            md={3}
                            lg={2}
                            mb={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    height: { xs: '220px', md: '250px', sm: '225px' },
                                    width: { xs: '90%', md: '100%', sm: '80%' },
                                    borderRadius: 2,
                                    position: "relative",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(1.02)",
                                    },
                                }}
                                onClick={() => navigateToUrl(`/book/${book.id}`)}
                            >
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={bookImage[book.id] || ""}
                                    alt={book.name || "Article Image"}
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        zIndex: 0,
                                    }}
                                    
                                />

                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                                        zIndex: 1,
                                        pointerEvents: "none",
                                    }}
                                />

                                <CardContent
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "100%",
                                        zIndex: 2,
                                        color: "white",
                                        p: 2,
                                    }}
                                >
                                    <Typography sx={{ color: "white", fontSize: { xs: '0rem', sm: '0rem', md: '0rem', lg: '0.8rem' } }}>
                                        {book.title || "No Title"}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '500px',
                                width: '100%',
                                border: '1px solid',
                                borderColor: '#e0e0e0',
                                borderImageSlice: 1,
                                borderRadius: 10,
                                p: 5
                            }}
                        >
                            <Typography sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1rem' }, textAlign: 'center' }}>
                                We couldn’t find any books based on your search.<br />Feel free to explore other categories or check back soon for new content.                             </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default BookGrid;

import ApiInstance from "../configs/ApiInstance";

async function CreateChapter(title, content, author, dateOnly, imageKey, status ,bookId ,chapterNumber ) {
    const updatedAt = dateOnly;
    const response = await ApiInstance.bookService.post(`${bookId}/chapter`,{
        title, 
        content, 
        author, 
        updatedAt, 
        imageKey, 
        status, 
        chapterNumber,
    });
    return response.data;
}

export default CreateChapter;
import React from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { navigateToUrl } from 'single-spa';

function ConfirmUser() {
  const handleGoHome = () => {
    navigateToUrl('/');
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundSize: 'cover',
          textAlign: 'center',
          mt:25,
          mb:25
        }}
      >
        <Typography variant="h4" fontFamily={'serif'} sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1.5rem' } }}>
          Thank you for registering with Ceylon Diaries!<br/>Please check your email to confirm your account.
        </Typography>
        <Button
          variant="contained"
          onClick={handleGoHome}
          sx={{
            mt: 5,
            background: 'black',
            "&:hover": {
              background: 'black',
            }
          }}
        >
          Home
        </Button>
      </Box>
    </Container>
  );
}

export default ConfirmUser;

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Container,
  Snackbar,
  Alert
} from '@mui/material';
import SendMail from '../services/SendMail';
import LoadingScreen from './LoadingScreen';
import { navigateToUrl } from "single-spa";

import '../styles/styles.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleReload = (bookId) => {
          return new Promise((resolve) => {
              setIsLoading(true);  
              setTimeout(() => {
                  navigateToUrl(`/`);
                  resolve(); 
              }, 2000);
          });
      };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    if (!name || !email || !message) {
      setSnackbarMessage('All fields are required.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    try {
      setIsLoading(true);
      await SendMail(name, email, message);
      setSnackbarMessage('Message sent successfully!');
      setSnackbarSeverity('success');
      setFormData({ name: '', email: '', message: '' }); 
      handleReload();
    } catch (error) {
      setSnackbarMessage('Failed to send the message. Please try again.');
      setSnackbarSeverity('error');
      console.error('Error sending message:', error);
    }

    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          padding: 3,
          borderRadius: 2,
          mb: 5,
        }}
      >
        <Typography variant="h4" gutterBottom textAlign="center" mb={3}>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="gradient-textfield"
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="gradient-textfield"
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="gradient-textfield"
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={6}
                variant="outlined"
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mb: 2,
                  fontWeight: 550,
                  letterSpacing: 2,
                  background: 'black',
                  '&:hover': {
                    background: 'black',
                  },
                }}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Contact;

import ApiInstance from "../configs/ApiInstance";

const UploadImages = async (formData) => {
    try {
        const response = await ApiInstance.userAwsImageService.post('/upload-articles/images', formData);
        const imageId = response.data.articleImageKey;
        return imageId;
    } catch (error) {
        console.error('Image upload failed:', error);
        throw error;
    }
};

export default UploadImages;

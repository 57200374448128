import React, { useEffect, useState } from 'react';
import { Grid, Container, Breadcrumbs, Link, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';

import Pagination from './components/Pagination'
import GetAllBooks from './services/GetAllBooks'
import BookGrid from './components/BookGrid';

import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import LoadingScreen from './components/LoadingScreen';

function Screen() {

  const { category: category } = useParams();

  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryName, setCategoryName] = useState('')

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
          window.scrollTo(0, 0);
      }, [location]);
  
      const handleReload = () => {
          return new Promise((resolve) => {
              setIsLoading(true);
              setTimeout(() => {
                  resolve();
              }, 1000);
          });
      };

  const itemsPerPage = 12;

  useEffect(() => {
    const formattedCategory = category.replace(/[\s%20]+/g, '_').toUpperCase();
    setCategoryName(formattedCategory);
  }, [category]);



  useEffect(() => {
    const fetchBooks = async () => {
      setIsLoading(true)
      try {
        const { content, totalPages } = await GetAllBooks(currentPage - 1, itemsPerPage, categoryName);
        const filteredBooks = content.filter(book => book.category === categoryName);
        const filteredTotalPages = Math.ceil(filteredBooks.length / itemsPerPage);
        setBooks(filteredBooks);
        setTotalPages(filteredTotalPages);
        await handleReload()
      } catch (error) {
        // console.error("Error fetching articles:", error);
      } finally {
        setIsLoading(false)
      }
    };

    if (categoryName) {
      fetchBooks();
    }
  }, [categoryName, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if(isLoading){
    return <LoadingScreen/>
  }

  return (
    <Container maxWidth="lg" sx={{ mb: 10}}>

      <Breadcrumbs aria-label="breadcrumb" mt={5}>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
          color="inherit"
          href={`/explorestories`}
        >
          <ArticleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Explore
        </Link>
        <Typography
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
          color="inherit"
        >
          {category.toLowerCase()}
        </Typography>
      </Breadcrumbs>
      
      <Typography mt={2} sx={{ fontWeight: 550, fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', textAlign: 'center' } }}>Discover {category.toLowerCase()} books</Typography>


      <Grid container spacing={5}>
        <Grid item xs={12}>
          <BookGrid books={books} />
        </Grid>
        <Grid item xs={12} >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Screen
import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetUploadedImages from '../services/GetUploadedImages';


function BookGrid({ books }) {
  const [bookCoverImage, setBookCoverImage] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        books.map(async (book) => {
          const imageUrl = await GetUploadedImages(book.imageKey);
          return { [book.id]: imageUrl };
        })
      );
      setBookCoverImage(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };

    fetchImages();
  }, [books]);

  if (!books || books.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            mt: 5,
            mb:5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            border: '1px solid #e0e0e0',
            borderRadius:"15px",
          }}
        >
          <Typography 
            component="h2" 
            fontFamily='serif'
            sx={{
              fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1.2rem' }
            }}
            >
            No Books Available
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={5} mt={1} mb={5}>
        {books.map((book) => (
          <Grid item key={book.id} xs={6} sm={4} md={3} lg={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: { xs: '220px', md: '250px', sm: '225px' },
                  width: { xs: '90%', md: '100%', sm: '80%' },
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.01)",
                  },
                  marginBottom: 1,
                }}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={bookCoverImage[book.id] || ""}
                  alt={book.title}
                  sx={{
                    objectFit: "cover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                  onClick={() => navigateToUrl(`/write/mystories/book/${book.id}`)}
                />
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default BookGrid;

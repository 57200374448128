import { Alert, Box, Button, Container, Snackbar, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles/input.css';
import AddComment from '../services/AddComment';
import GetUserDetails from '../services/GetUserData';
import LoadingScreen from './LoadingScreen'

function ChapterDrawerCommentBox({ articleId, chapterIndex}) {
  const [user, setUser] = useState([]);
  const [comment, setComment] = useState("");
  const [nickname, setNickname] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  
    const handleReload = () => {
      return new Promise((resolve) => {
        setIsLoading(true);
        setTimeout(() => {
          window.location.reload();
          resolve();
        }, 1000);
      });
    };

  useEffect(() => {
    const data = localStorage.getItem('user');
    const userId = localStorage.getItem('userId');
    setId(userId);

    if (data) {
      const parsedData = JSON.parse(data);
      const userDetails = parsedData?.UserAttributes || [];
      setUser(userDetails);
      const nick = userDetails.find(attr => attr.Name === "nickname")?.Value;
      fetchUserDetails(nick)
      setNickname(nick || "");
    }
  }, []);

  const fetchUserDetails = async (nickname) => {
    try {
      const user = await GetUserDetails(nickname);
      const profileImage = user.profileImageKey;
      setProfileImage(profileImage)
    } catch (error) {
      console.error("Error occurred when getting user data", error);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    if(id){
        try {
            const updatedAt = new Date().toISOString();
            await AddComment(articleId, nickname, comment, updatedAt, profileImage, chapterIndex);
            handleReload();
          } catch (error) {
            // console.error("Error submitting comment", error);
          }
    } else {
        setError('Please log in to comment on this article.');
        setOpen(true);
    }
    
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }


  return (
    <Container maxWidth="md">
        <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Box>
        <TextField
          className="gradient-textfield"
          label="comment"
          required
          fullWidth
          variant='outlined'
          multiline
          rows={3}
          autoComplete="comment"
          name="comment"
          id="comment"
          value={comment}
          onChange={handleCommentChange}
          sx={{ mb: 2 }}
          InputProps={{
            sx: {
              fontSize: '0.9rem',
            },
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          sx={{
            background: 'black',
            "&:hover": {
              background: 'black',
            },
            fontWeight: 550,
            letterSpacing: 2,
            mb: 2,
          }}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
}

export default ChapterDrawerCommentBox;

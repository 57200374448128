import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent, CardActions, Chip, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetArticleImage from '../services/GetArticleImage';
import LoadingScreen from './LoadingScreen';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteArticle from '../services/DeleteArticle';


function ArticleGrid({ articles }) {

  const [articleImages, setArticleImages] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [articleIdToDelete, setArticleIdToDelete] = useState(null);

  const handleReload = () => {
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        articles.map(async (article) => {
          const imageUrl = await GetArticleImage(article.articleImageKey);
          return { [article.id]: imageUrl };
        })
      );
      setArticleImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };

    fetchImages();
  }, [articles]);

  const DeleteArticleById = async () => {
    try {
      await DeleteArticle(articleIdToDelete);
      handleReload();
    } catch (error) {
      console.error("Error deleting chapter:", error);
    }
  };
  const handleClickMenu = (event, chapterId) => {
    setAnchorEl(event.currentTarget);
    setSelectedChapterId(chapterId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedChapterId(null);
  };

  const handleDeleteChapter = async () => {
    try {
      await DeleteArticleById(articleIdToDelete);
    } catch (error) {
      console.error("Error deleting chapter:", error);
    }
  };

  const handleNavigate = (path) => {
    navigateToUrl(path);
    handleCloseMenu();
  };

  const handleOpenDialog = (articleId) => {
    setArticleIdToDelete(articleId);
    setOpenDialog(true);
};

const handleCloseDialog = () => {
    setOpenDialog(false);
    setArticleIdToDelete(null);
};

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!articles || articles.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            mt: 5,
            mb:5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            border: '1px solid #e0e0e0',
            borderRadius:"15px",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            fontFamily='serif'
            sx={{
              fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1.2rem' }
            }}
          >
            No Articles Available
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} mt={1} mb={10}>
        {articles.map((article) => (
          <Grid item key={article.id} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: '150px',
                width: "100%",
                borderRadius: 2,
                position: "relative",
                transition: "transform 0.2s ease-in-out",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                },
                zIndex:1
              }}
              
            >
              <CardMedia
                component="img"
                height="250"
                image={articleImages[article.id] || ""}
                alt={article.title}
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 0,
                }}
                onClick={() => navigateToUrl(`/myarticles/${article.id}`)}
              />
              <IconButton
                sx={{
                  size: '10px',
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  },
                  width: 32,
                  height: 32,
                }}
                onClick={(event) => handleClickMenu(event, article.id)}
              >
                <MoreVertIcon />
              </IconButton>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />

              <CardContent
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 2,
                  color: "white",
                  p: 2,
                }}
              >
                <Typography sx={{ color: "white", fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '0.8rem' }, userSelect:'none' }}>
                  {article.title || "No Title"}
                </Typography>

                {/* <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  justifyContent: 'flex-start',
                }}
              >



                <Typography fontSize={"1.05rem"} sx={{ color: "white" }}  >
                  {article.title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "auto",
                    padding: 1,
                  }}
                >
                  <Chip
                    sx={{
                      bgcolor:
                        article.status === "ACCEPTED"
                          ? "#00E600"
                          : article.status === "REJECTED"
                            ? "#E62600"
                            : "black",
                      color: 'white'
                    }}
                    label={article.status} />
                </Box> */}
              </CardContent>

            </Card>

            <Menu
              sx={{
                mt: 1
              }}
              anchorEl={anchorEl}
              open={selectedChapterId === article.id}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleNavigate(`/myarticles/${article.id}`)}>View</MenuItem>
              <MenuItem onClick={() => handleOpenDialog(article.id)}>Delete</MenuItem>
            </Menu>
          </Grid>
        ))}
      </Grid>

      <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Delete Chapter</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this article?<br/> This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} sx={{color: 'black'}}>
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteChapter} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

    </Container>
  );
}

export default ArticleGrid;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  LinearProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
  Pagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import GetUserDetails from '../services/GetUserData';
import GetAllArticlesByAuthor from '../services/GetAllArticlesByAuthor';
import GetAllBooksByAuthor from '../services/GetAllBooksByAuthor';

import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PaidIcon from '@mui/icons-material/Paid';
import { Close as CloseIcon } from '@mui/icons-material';
import SendMail from '../services/SendMail';

function Stats() {
  const { id } = useParams();

  const [userDetails, setUserData] = useState({});

  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [totalLikeCount, setTotalLikeCount] = useState('');

  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [books, setBooks] = useState([]);
  const [currentBookPage, setCurrentBookPage] = useState(1);
  const [totalBookPages, setTotalBookPages] = useState(1);

  const [progress, setProgress] = useState(0);
  const [earnedAmount, setEarnedAmount] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isClaimed, setIsClaimed] = useState(false);

  useEffect(() => {
    if (id) {
      fetchUserDetails(id);
      fetchUserArticles(id, currentPage);
      fetchUserBookChapters(id, currentBookPage);
    }
  }, [id, currentPage, currentBookPage]);

  const fetchUserDetails = async (id) => {
    try {
      const userDetails = await GetUserDetails(id);
      setUserId(userDetails.id);
      setName(userDetails.nickname);
      setEmail(userDetails.email);
      setTotalLikeCount(userDetails.totalLikeCount);
      setUserData(userDetails);

      const totalLikes = userDetails.totalLikeCount || 0;

      const earnings = totalLikes * 0.005; // $0.01 per like, To earn $100, the user needs 20, 000 likes.
      setEarnedAmount(earnings);

      // Calculate progress towards $100
      const progressValue = Math.min((earnings / 100) * 100, 100);

      setProgress(progressValue);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchUserArticles = async (id, page) => {
    try {
      const { content, totalPages } = await GetAllArticlesByAuthor(id, page - 1);
      const sortedArticles = content.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

      setArticles(sortedArticles);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  const fetchUserBookChapters = async (id, page) => {
    try {
      const { content, totalPages } = await GetAllBooksByAuthor(id, page - 1);
      const sortedBooks = content.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

      // Calculate total like counts for each book's chapters
      const booksWithLikeCounts = sortedBooks.map((book) => {
        const totalLikeCount = book.chapters.reduce((sum, chapter) => sum + (chapter.likeCount || 0), 0);
        return { ...book, totalLikeCount };
      });

      setBooks(booksWithLikeCounts);
      setTotalBookPages(totalPages);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const sendMail = async () => {
    try {
      await SendMail(name, email, userId, totalLikeCount, earnedAmount, progress);
      setDialogMessage('Claim successful! Admin has been notified, and our customer care team will contact you via email within the next 3 days.');
      setIsClaimed(true);
      setOpenDialog(true);
    } catch (error) {
      console.error('Error sending email:', error);
      setDialogMessage('Claim unsuccessful. Please try again. If the issue persists, contact customer service for assistance.');
      setOpenDialog(true); 
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleBookPageChange = (event, value) => {
    console.log(value);
    setCurrentBookPage(value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleClaimEarnings = () => {
    if (progress === 100 && !isClaimed) {
      // Trigger the claim only if the progress is 100 and hasn't been claimed yet
      sendMail();
    } else {
      setDialogMessage('You have already claimed your earnings. Customer care will contact you soon.');
      setOpenDialog(true);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: 8,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '50%',
            justifyContent: 'space-between',
          }}
        >
          {/* <PaidIcon sx={{ fontSize: 36, color: 'green' }} /> */}
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              flex: 1,
              height: '10px',
              borderRadius: 5,
              marginLeft: 2,
              marginRight: 2,
              backgroundColor: 'lightGray',
              '& .MuiLinearProgress-bar': {
                backgroundColor: 'black',
              },
            }}
          />
          <Typography fontWeight={550} mr={1}>{earnedAmount.toFixed(2)}</Typography>
          <PaidIcon sx={{ fontSize: 36, color: 'gold', mr: 1 }} />
           <Button
            variant="contained"
            sx={{
              background: 'black',
              "&:hover": {
                background: 'black',
              },
              fontWeight: 550,
              letterSpacing: 2,
              borderRadius: 5,
            }}
            onClick={() => handleClaimEarnings()}
            disabled={isClaimed || progress < 100} 
          >
             {isClaimed ? 'Claimed' : 'Claim'} 
          </Button>
         </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          mt: 5,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, textAlign: 'left' }}>
          Articles Insights
        </Typography>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ width: '100%' }} aria-label="articles table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                <TableCell sx={{ fontWeight: 'bold', width: '80%' }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Like Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((article, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '50%' }}>{article.title}</TableCell>
                  <TableCell sx={{ width: '50%' }}>{article.likeCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: 3,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              mb: 4,
              '& .MuiPaginationItem-root': {
                color: 'black',
              },
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: 'black',
                color: 'white',
              },
              '& .MuiPaginationItem-root:focus': {
                backgroundColor: 'black',
                color: 'white',
              },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          mt: 5,
          mb:5
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, textAlign: 'left' }}>
          Books Insights
        </Typography>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ width: '100%' }} aria-label="books table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                <TableCell sx={{ fontWeight: 'bold', width: '80%' }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Total Like Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {books.map((book, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '50%' }}>{book.title}</TableCell>
                  <TableCell sx={{ width: '50%' }}>{book.totalLikeCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: 3,
          }}
        >
          <Pagination
            count={totalBookPages}
            page={currentBookPage}
            onChange={handleBookPageChange}
            sx={{
              mb: 4,
              '& .MuiPaginationItem-root': {
                color: 'black',
              },
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: 'black',
                color: 'white',
              },
              '& .MuiPaginationItem-root:focus': {
                backgroundColor: 'black',
                color: 'white',
              },
            }}
          />
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          '& .MuiDialog-paper': {
            width: 500,  
            height: 200, 
          },
        }}
      >
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleDialogClose}
            aria-label="close"
            sx={{ position: 'revert', right: 5, top: 8, color: 'black' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'normal',  
              wordWrap: 'break-word',  
              overflowWrap: 'break-word', 
            }}
          >
            {dialogMessage}
          </Typography>        
        </DialogContent>
      </Dialog>

    </Container>
  );
}

export default Stats;

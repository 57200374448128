import { Box, Button } from '@mui/material';
import React from 'react';
import { navigateToUrl } from 'single-spa';

function PageButton({ image, path }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        width: {
          xs: '100%',    
          sm: '100%',    
          md: '100%',     
          lg: '250px',      
        },
        height: {
          xs: '100px',    
          sm: '150px',    
          md: '200px',   
          lg: '250px',    
        },
        backgroundImage: `url(${image})`,
        backgroundSize: {
          xs: 'contain',   
          sm: 'contain',   
          md: 'contain',    
          lg: 'contain',     
        },backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: 5,
        overflow: 'hidden',
        transition: 'background-image 0.3s ease',
      }}
    >
      <Button
        variant="contained"
        sx={{
          width: '100%',
          height: '100%',
          fontSize: {
            xs: '1rem',   
            sm: '1.5rem',  
            md: '2rem',    
            lg: '2.5rem',  
          },
          backgroundColor: 'rgba(80, 242, 196, 0.0)',  
          color: 'white',
          borderRadius: 5,
          '&:hover': {
            backgroundColor: 'rgba(64, 224, 181, 0.0)',  
          },
        }}
        onClick={() => navigateToUrl(`${path}`)}
      >
      </Button>
    </Box>
  );
}

export default PageButton;

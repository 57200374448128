import React from 'react';
import Contributor from './components/Contributor';
import { Container } from '@mui/material';

function Screen() {

    return (
        <Container maxWidth="lg">
            <Contributor />
        </Container>
    );
}

export default Screen;

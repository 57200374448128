import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';
import GetAdminImage from '../services/GetAdminImage';
import GetAdminData from '../services/GetAdminData';
import '../styles/MainBanner.css';

function SmallBanner() {
    const [bannerImages, setBannerImages] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false
    };

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const adminData = await GetAdminData();
                const mainBanner1 = await GetAdminImage(adminData.banner1);
                const mainBanner2 = await GetAdminImage(adminData.banner2);
                const mainBanner3 = await GetAdminImage(adminData.banner3);
                setBannerImages([mainBanner1, mainBanner2, mainBanner3]);
            } catch (error) {
                return null;
                // console.error("Error occurred when getting data", error);
            }
        };
        fetchAdminData();
    }, []);



    return (
        <Slider {...settings}>
            {bannerImages.map((image, index) => (
               <Box
               key={index}
               sx={{
                   backgroundImage: `url(${image})`,
                   backgroundSize: 'cover',
                   backgroundPosition: 'center',
                   borderRadius: 5,
                   mt: 2,
                   height: {xs: '200px', sm: '280px', md: '260px', lg: '260px' },
                   width: '100%',
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   position: 'relative',
                   overflow: 'hidden',
               }}
           />
            ))}
        </Slider>
    );
}

export default SmallBanner;

import ApiInstance from "../configs/ApiInstance";

async function SendMail(name, email, userId, totalLikeCount, earnedAmount, progress) {

    const title = 'Action Needed: User Eligible for Earnings Claim';
    const subject = `Earnings Claim: ${name} is Eligible for ${earnedAmount.toFixed(2)} USD`;

    const message = `
        Hello Admin,<br>
        A user has successfully completed their earnings and is eligible to claim their reward.<br><br>
        <strong>Total Likes:</strong> ${totalLikeCount}<br><br>
        <strong>Amount Eligible for Claim:</strong> $${earnedAmount.toFixed(2)}<br><br>
        <strong>Progress:</strong> ${progress}%<br><br>
        Please review and process the claim accordingly.<br><br>
        Thank you!
    `;

    const response = await ApiInstance.emailService.post('/claim', {
        subject, title, message, name, email, userId
    });

    return response.data;
}

export default SendMail;

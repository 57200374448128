import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

function LoadingScreen() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#fff',
        color: '#fff',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1300,
      }}
    >
      <CircularProgress size={60} sx={{ color: '#eb2f25', mb: 2 }} />
    </Box>
  );
}

export default LoadingScreen;

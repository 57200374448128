import ApiInstance from "../configs/ApiInstance";

async function UpdateChapter(title, content, dateOnly, imageKey, bookId ,chapterId ,status, chapterNumber) {
    const updatedAt = dateOnly;
    const response = await ApiInstance.bookService.put(`${bookId}/chapter/${chapterId}`,{
        title, 
        content, 
        updatedAt, 
        imageKey, 
        status, 
        chapterNumber
    });
    return response.data;
}

export default UpdateChapter;
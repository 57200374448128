import ApiInstance from "../configs/ApiInstance";

async function GetAllArticles(page, size ,category) {
  try {
    const response = await ApiInstance.articleService.request({
      method: "get",
      params: {
        size: size, page: page, category:category
      }
    });
    const { content, totalPages } = response.data;
    return { content, totalPages };
  } catch (error) {
    return null;
    // console.log(error);
    // throw error;
  }
}
export default GetAllArticles;

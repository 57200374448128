import ApiInstance from "../configs/ApiInstance";

async function UnLike(bookId, chapterId, id) {
  try {
    const response = await ApiInstance.bookService.delete(`/${bookId}/chapter/${chapterId}/like/${id}`, {
        });
     return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default UnLike;

import React, { useEffect, useState } from 'react'
import { Container, CssBaseline, Divider, Grid, Typography, Stack, Breadcrumbs, Link } from '@mui/material'
import GetAllBooksByAuthor from '../services/GetAllBooksByAuthor';
import BookGrid from './BookGrid';
import Pagination from './Pagination';

import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';

function MyStories() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [books, setBooks] = useState([]);
    const [nickname, setNickname] = useState('');

    useEffect(() => {
        const fetchArticles = async (page) => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                const nickname = getUserAttribute(parsedUser, 'nickname');
                setNickname(nickname);
                try {
                    const BookList = await GetAllBooksByAuthor(nickname, currentPage);
                    const sortedBookList = BookList.content.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                    setBooks(sortedBookList);
                    setTotalPages(BookList.totalPages);
                } catch (error) {
                    console.error("Error fetching articles:", error);
                }
            }
        };
        fetchArticles(currentPage);
    }, [currentPage]);


    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Container maxWidth="lg" sx={{mb:10}}>
            <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                            href={`/write`}
                        >
                            <CreateIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Contributor
                        </Link>
                        <Typography
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' } }}
                            color="inherit"
                        >
                            My Stories
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Container>


            <Divider textAlign="right">
                <Typography variant="h2" sx={{
                    textAlign: 'left',
                    fontSize: { xs: 'h5.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' },
                    fontFamily: 'serif'
                }}>
                    My Stories
                </Typography>


            </Divider>
            <BookGrid books={books} />
                 <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
         </Container>
    )
}

export default MyStories
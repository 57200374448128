import ApiInstance from "../configs/ApiInstance";

async function CreateBook(title, description, category, tags, language, selectedCopyright, selectedRating, author, imageKey, dateOnly) {
    try {
        const numberOfChapters = 0;
        const createdAt = dateOnly;
        const copyright = selectedCopyright;
        const rating = selectedRating;
        const status = "PUBLISHED"
        const response = await ApiInstance.bookService.post("/", {
            title, 
            description, 
            category, 
            tags, 
            language, 
            copyright, 
            rating, 
            author, 
            imageKey,
            createdAt,
            numberOfChapters,
            status
        });
        return response.data;
    } catch (error) {
        console.log("Error occurred when creating new article", error?.message);
        throw error;
    }
}

export default CreateBook;
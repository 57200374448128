import React, { useState, useEffect } from 'react';
import { navigateToUrl } from 'single-spa';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Button, Box, CssBaseline, Avatar, Divider, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import GetUserDetails from '../services/GetUserData';
import FetchProfileImage from '../services/FetchProfileImage';
import Logo from '../image/round-logo.png';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseIcon from '@mui/icons-material/Close';
import DeleteNotification from '../services/deleteNotification';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

function Navbar() {
    const navigate = useNavigate();
    const defaultSettings = ['Home', 'Books', 'Articles', 'Register', 'Login'];
    const defaultSettingsForMobile = ['Register', 'Login'];
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [settings, setSettings] = useState(defaultSettings);
    const [nickname, setNickname] = useState('');
    const [profileImage, setProfileImage] = useState('');

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);

    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const checkLoginStatus = () => {
            const accessToken = localStorage.getItem('accessToken');
            const userRole = localStorage.getItem('role');

            if (accessToken) {
                if (!isLoggedIn) {
                    setIsLoggedIn(true);
                    updateSettings(userRole);
                    fetchUserProfile();
                }
            } else {
                if (isLoggedIn) {
                    setIsLoggedIn(false);
                    updateSettings();
                }
            }
        };

        checkLoginStatus();
        const intervalId = setInterval(checkLoginStatus, 1000);
        return () => clearInterval(intervalId);
    }, [isLoggedIn]);

    const fetchUserProfile = async () => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = getUserAttribute(parsedUser, 'nickname');
            const profileImageKey = sessionStorage.getItem('profileImageKey');
            try {
                const userDetails = await GetUserDetails(nickname);
                if (profileImageKey) {
                    setProfileImage(profileImageKey)
                } else {
                    const profileImageKey = await FetchProfileImage(userDetails.profileImageKey)
                    localStorage.setItem('profileImageKey', profileImageKey)
                    setProfileImage(profileImageKey)
                }
                setNickname(userDetails.nickname);
                setNotifications(userDetails.notifications);
                const role = userDetails.role;
                const cafe = userDetails.cafe;
                localStorage.setItem('cafeId', cafe);
                localStorage.setItem('role', role);
                updateSettings(role);
            } catch (error) {
                console.error('Error occurred when getting user data', error);
            }
        }
    };

    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const updateSettings = (role) => {
        if (role === 'USER') {
            setSettings(['Home', 'Articles', 'Books', 'Library']);
        } else if (role === 'CONTRIBUTOR') {
            setSettings(['Home', 'Articles', 'Books', 'Library']);
        } else {
            setSettings(defaultSettings);
        }
    };

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleProfileClick = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };

    const handlePageSelect = (page) => {
        setMenuAnchorEl(null);
        setProfileAnchorEl(null);
        const pageUrl = getPageUrl(page);
        if (pageUrl) navigate(pageUrl);
    };

    const getPageUrl = (page) => {
        switch (page) {
            case 'Home':
                return '/';
            case 'Library':
                return '/library';
            case 'Profile':
                return `/profile/${nickname}`;
            // case 'Insights & Rewards':
            //     return `/profile/${nickname}/stats`;
            case 'Write':
                return '/write'
            case 'Edit Profile':
                return '/profile';
            case 'Write Article':
                return '/writearticle';
            case 'Write Stories':
                return '/writestories';
            case 'Login':
                return '/signin';
            case 'Register':
                return '/signup';
            case 'Articles':
                return '/explorearticles';
            case 'Books':
                return '/explorestories';
            case 'Logout':
                handleLogout();
                return null;
            default:
                return '/';
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('cafeId');
        localStorage.removeItem('role');
        localStorage.removeItem('profileImageKey');
        setIsLoggedIn(false);
        setSettings([]);
        navigate('/');
        window.location.reload();
    };

    const camelCase = (str) => {
        return str
            .toLowerCase()
            .replace(/(?:^|\s|[-_])\w/g, (match) => match.toUpperCase())
            .replace(/[-_]/g, '');
    };

    const handleNotificationClose = () => {
        setNotificationAnchorEl(null);
    };

    const handleNavigate = (url) => {
        navigateToUrl(url);
        handleNotificationClose();
    }

    const handleDeleteNotification = async (id) => {
        await DeleteNotification(nickname, id);
        handleNotificationClose();
        window.location.reload();
    }

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: 'white',
                boxShadow: 'none',
                borderBottom: '1px solid #e0e0e0'
            }}
        >
            <CssBaseline />
            <Toolbar>
                <img src={Logo} alt="Logo" style={{
                    cursor: 'pointer',
                    height: '50px',
                    width: 'auto',
                    WebkitTapHighlightColor: 'transparent',
                }} onClick={() => navigate('/')} />
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1, cursor: 'pointer', color: 'black', textTransform: 'none' }}
                    onClick={() => navigate('/')}
                >
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                    {settings.map((setting, index) => (
                        <Button
                            key={index}
                            sx={{ color: 'black', textTransform: 'none' }}
                            onClick={() => handlePageSelect(setting)}
                        >
                            {camelCase(setting)}
                        </Button>
                    ))}
                    {isLoggedIn && (
                        <>
                            <IconButton
                                sx={{ color: 'black' }}
                                onClick={(event) => setNotificationAnchorEl(event.currentTarget)}
                            >
                                {notifications.length > 0 ? (
                                    <NotificationsActiveIcon />
                                ) : (
                                    <NotificationsNoneIcon />
                                )}
                            </IconButton>
                            <Menu
                                anchorEl={notificationAnchorEl}
                                open={Boolean(notificationAnchorEl)}
                                onClose={() => setNotificationAnchorEl(null)}
                                PaperProps={{
                                    style: {
                                        width: '300px',
                                    },
                                }}
                            >
                                {notifications.length > 0 ? (
                                    <Box
                                        sx={{
                                            maxHeight: 'calc(3 * 120px)',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {notifications.map((notification, index) => (
                                            <React.Fragment key={index}>
                                                <MenuItem
                                                    disableRipple
                                                    sx={{
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                        },
                                                    }}
                                                >
                                                    <Box sx={{ position: 'relative', width: '100%', padding: 1 }}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleDeleteNotification(notification.id)}
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 4,
                                                                right: 4,
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="small" />
                                                        </IconButton>

                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                whiteSpace: 'normal',
                                                                wordWrap: 'break-word',
                                                                overflowWrap: 'break-word',
                                                                marginRight: 3,
                                                                mt: 1,
                                                            }}
                                                        >
                                                            {notification.message}
                                                        </Typography>

                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{
                                                                textTransform: 'none',
                                                                mt: 1,
                                                                color: 'black',
                                                                borderColor: '#e0e0e0',
                                                                '&:hover': {
                                                                    borderColor: '#e0e0e0',
                                                                    backgroundColor: 'transparent',
                                                                },
                                                            }}
                                                            onClick={() => handleNavigate(notification.url)}
                                                        >
                                                            {notification.buttonText || 'View'}
                                                        </Button>
                                                    </Box>
                                                </MenuItem>
                                                {index < notifications.length - 1 && <Divider />}
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                ) : (
                                    <MenuItem>
                                        <Typography variant="body2">No notifications available</Typography>
                                    </MenuItem>
                                )}
                            </Menu>
                            <IconButton
                                sx={{ color: 'black', mr: 2 }}
                                onClick={() => navigateToUrl('/write')}
                            >
                                <AddBoxOutlinedIcon />
                            </IconButton>
                        </>
                    )}

                </Box>

                {/* smaller screens */}
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    {isLoggedIn && (
                        <>
                            <Stack direction="row" spacing={1} alignItems="center" mr={1}>
                                <IconButton
                                    sx={{ color: 'black' }}
                                    onClick={(event) => setNotificationAnchorEl(event.currentTarget)}
                                >
                                    {notifications.length > 0 ? (
                                        <NotificationsActiveIcon />
                                    ) : (
                                        <NotificationsNoneIcon />
                                    )}
                                </IconButton>
                                <Menu
                                    anchorEl={notificationAnchorEl}
                                    open={Boolean(notificationAnchorEl)}
                                    onClose={() => setNotificationAnchorEl(null)}
                                    PaperProps={{
                                        style: {
                                            width: '300px',
                                        },
                                    }}
                                >
                                    {notifications.length > 0 ? (
                                        <Box
                                            sx={{
                                                maxHeight: 'calc(3 * 120px)',
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {notifications.map((notification, index) => (
                                                <React.Fragment key={index}>
                                                    <MenuItem
                                                        disableRipple
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}
                                                    >
                                                        <Box sx={{ position: 'relative', width: '100%', padding: 1 }}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleDeleteNotification(notification.id)}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 4,
                                                                    right: 4,
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </IconButton>

                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    whiteSpace: 'normal',
                                                                    wordWrap: 'break-word',
                                                                    overflowWrap: 'break-word',
                                                                    marginRight: 3,
                                                                    mt: 1,
                                                                }}
                                                            >
                                                                {notification.message}
                                                            </Typography>

                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    mt: 1,
                                                                    color: 'black',
                                                                    borderColor: '#e0e0e0',
                                                                    '&:hover': {
                                                                        borderColor: '#e0e0e0',
                                                                        backgroundColor: 'transparent',
                                                                    },
                                                                }}
                                                                onClick={() => handleNavigate(notification.url)}
                                                            >
                                                                {notification.buttonText || 'View'}
                                                            </Button>
                                                        </Box>
                                                    </MenuItem>
                                                    {index < notifications.length - 1 && <Divider />}
                                                </React.Fragment>
                                            ))}
                                        </Box>
                                    ) : (
                                        <MenuItem>
                                            <Typography variant="body2">No notifications available</Typography>
                                        </MenuItem>
                                    )}
                                </Menu>

                                <IconButton
                                    sx={{ color: 'black' }}
                                    onClick={() => navigateToUrl('/write')}
                                >
                                    <AddBoxOutlinedIcon />
                                </IconButton>
                            </Stack>
                        </>
                    )}
                </Box>

                {isLoggedIn && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <IconButton onClick={handleProfileClick}>
                            <Avatar src={profileImage}>{!profileImage && nickname.charAt(0).toUpperCase()}</Avatar>
                        </IconButton>
                        <Menu
                            anchorEl={profileAnchorEl}
                            open={Boolean(profileAnchorEl)}
                            onClose={handleProfileClose}
                        >
                            {['Profile', 'Edit Profile', 'Write', 'Logout'].map((option) => (
                                <MenuItem key={option} onClick={() => handlePageSelect(option)}>
                                    {camelCase(option)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                )}

                {!isLoggedIn && (
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        {defaultSettingsForMobile.map((setting, index) => (
                            <Button
                                key={index}
                                sx={{ color: 'black', textTransform: 'none' }}
                                onClick={() => handlePageSelect(setting)}
                            >
                                {camelCase(setting)}
                            </Button>
                        ))}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;

import ApiInstance from "../configs/ApiInstance";

async function CreateArticle(title,category, content, author, updatedAt, imageKey, locationUrl,subcategory) {
    try {
        const articleImageKey = imageKey;
        const status = "ACCEPTED"
        const response = await ApiInstance.articleService.post("", {
            title,
            category,
            content,
            author,
            articleImageKey,
            status,
            updatedAt,
            locationUrl,
            subcategory
        });
        return response.data;
    } catch (error) {
        console.log("Error occurred when creating new article", error?.message);
        throw error;
    }
}

export default CreateArticle;
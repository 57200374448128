import ApiInstance from "../configs/ApiInstance";

async function LikeComment(bookId, chapterId, commentId, id) {
  try {
    const response = await ApiInstance.bookService.put(`/${bookId}/chapter/${chapterId}/comment/${commentId}/like`, {
      userId: id,
    });
    return response;
  } catch (error) {
    console.error('Error liking comment:', error);
    throw error;
  }
}

export default LikeComment;

import { Box, Container, CssBaseline, Divider, Grid, Stack, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Preview() {
    const location = useLocation();
    const navigate = useNavigate();
    const { bookId } = useParams();

    const { title, content, coverImage, author} = location.state || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleButtonClick = () => {
        navigate(`/write/book/${bookId}/chapter`);
    };

    return (
        <Container disableGutters maxWidth='xl'>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    height: '100%',
                    mb: 5
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        height: { xs: '300px', md: '400px' }, 
                        objectFit: 'cover',
                    }}
                    src={coverImage}
                    alt="Banner Image"
                />
            </Box>
            <Grid container spacing={2}>
               
                <Grid
                    item
                    md={2}
                    mt={6}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%',
                    }}
                >
                </Grid>

              
                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        mx: { xs: 2, sm: 4 }, 
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "1000px",
                            mb: 10,
                        }}
                    >
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography
                                    variant='h3'
                                    fontFamily={'serif'}
                                    sx={{ fontWeight: 700}}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    fontFamily={'serif'}
                                    color="text.secondary"
                                    variant='caption'
                                >
                                    By {author}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Typography
                                variant="h6"
                                component="div"
                                fontFamily={'serif'}
                                whiteSpace='pre-wrap'
                                sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem', lg: '1rem' } }} 
                            >
                                {content}
                            </Typography>
                        </Stack>
                    </Box>
                    <Divider sx={{ mb: 5 }} />
                </Grid>

                <Grid
                    item
                    md={2}
                    mt={6}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%',
                    }}
                >
                </Grid>
            </Grid>

            <Box
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 30,
                    zIndex: 1000,
                    display: { xs: 'block' }, 
                    mx: 2,
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => handleButtonClick()}
                    sx={{
                        fontWeight: 550,
                        width: '150px',
                        letterSpacing: 2,
                        background: 'black',
                        "&:hover": {
                            background: 'black',
                        },
                        mb: 2
                    }}
                >
                    Back To Edit
                </Button>
            </Box>
        </Container>
    );
}

export default Preview;

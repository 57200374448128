import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

function PrivacyPolicies() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 3 }}>
          Privacy Policies
        </Typography>

        <Typography variant="body1" paragraph>
          Welcome to Ceylon Diaries! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our platform.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          We collect the following types of information to provide and improve our services:
          <br />
          - <strong>Personal Information:</strong> When you create an account, we collect information such as your name, email address, and other details required to personalize your user experience and facilitate communication.
          <br />
          - <strong>Automatically Collected Information:</strong> We may collect certain information automatically when you access our platform. This includes details about your device (such as device type, browser type, operating system), usage patterns (such as pages viewed, session duration, and interactions), and IP address. This data helps us analyze user behavior and improve our platform.
          <br />
          - <strong>Cookies and Similar Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience, track usage, and gather statistical data. Cookies allow us to remember your preferences, improve functionality, and provide a personalized experience.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          We use the information we collect for the following purposes:
          <br />
          - <strong>Account Management:</strong> To create, manage, and maintain your account. We use your personal information to authenticate you, provide access to platform features, and manage your user profile.
          <br />
          - <strong>Communication:</strong> We may use your email address to send you important notifications regarding your account, updates about our platform, and announcements about new features, policies, and services. You can opt-out of non-essential notifications.
          <br />
          - <strong>Service Improvement:</strong> To enhance the user experience, we analyze usage data to improve the functionality, performance, and security of the platform. This may include A/B testing, feature improvements, and performance optimization.
          <br />
          - <strong>Compliance:</strong> To comply with applicable laws, regulations, and legal requests, including responding to legal processes (such as subpoenas, court orders) or to protect the rights, property, and safety of Ceylon Diaries, our users, and others.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Data Sharing
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          We take your privacy seriously and do not sell, rent, or share your personal information with third parties for marketing purposes. We may share your information in the following circumstances:
          <br />
          - <strong>Legal Requirements:</strong> We may disclose your personal information if required to do so by law or in response to valid legal requests by public authorities (e.g., a court or government agency).
          <br />
          - <strong>Service Providers:</strong> We may share your information with trusted third-party vendors who help us operate our platform and services, such as hosting providers, analytics services, and customer support. These service providers are contractually obligated to handle your information securely and only for the purposes for which it was shared.
          <br />
          - <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your personal information may be transferred to the new owner. We will notify you of such changes and provide you with options regarding your data.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          We implement industry-standard security measures to safeguard your personal information from unauthorized access, use, alteration, or destruction. These measures include encryption, firewalls, and secure data storage protocols.
          <br />
          However, it’s important to note that no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your data, we cannot guarantee its absolute security. We encourage you to be cautious when using any online service and take reasonable steps to protect your personal information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          You have the following rights regarding your personal information:
          <br />
          - <strong>Access:</strong> You can request access to the personal information we have about you. We will provide you with a copy of the data we hold.
          <br />
          - <strong>Correction:</strong> You may request that we correct any inaccuracies in your personal information.
          <br />
          - <strong>Deletion:</strong> You may request that we delete your personal information, subject to certain legal restrictions.
          <br />
          Please contact us at <Link href='/contact' underline='none' color='black' fontWeight='550'>contact@ceylondiaries.com</Link> for assistance.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Cookies
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          Cookies are small files stored on your device that help us track your preferences, enhance user experience, and analyze website traffic. We use cookies to:
          <br />
          - Remember your login credentials for a smoother experience.
          <br />
          - Analyze website traffic and user behavior to improve our services.
          <br />
          - Customize content based on your preferences and usage.
          <br />
          You can control cookies through your browser settings by accepting or rejecting them. However, some features of the platform may not function properly without cookies.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Policy Updates
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we update the policy, we will post the revised version on this page with the updated date. Please review this page periodically to stay informed about how we are protecting your personal information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body2" paragraph color={'text.secondary'} p={1}> 
          If you have any questions or concerns regarding this Privacy Policy or our data practices, please feel free to reach out to us at <Link href='/contact' underline='none' color='black' fontWeight='550'>contact@ceylondiaries.com</Link>.
        </Typography>

        <Typography variant="caption" display="block" textAlign="center" sx={{ mt: 3 }}>
          Last Updated: December 2024
        </Typography>
      </Box>
    </Container>
  );
}

export default PrivacyPolicies;
